import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
					<path id="Malaysia" d="M79.96180168709174 94.48511857392927L76.33296196084603 95.89368136240647L76.56374343466378 100C75.51328982969855 99.76126054432609 74.33550851504057 99.17236988699636 72.82349196243838 98.3526977558489C56.07989813783234 88.63600190991505 5.148814260703334 64.04583797548861 5.148814260703334 37.82428776062337C5.148814260703334 26.929810600031182 3.2786885245899 13.93442622950744 0 1.9974534458058226L9.589368136240353 0L20.141652077033264 2.69775584911634L21.89240808530864 17.56326595575315L36.75791819194655 12.414451695049449L43.42670698710824 4.6872513130663656C51.981537482094964 10.655737704917541 58.53891453127476 15.9239216934582 61.348082126372994 22.720038198312647C67.42798026420496 37.82428776062337 55.26022600668486 45.67085787044341 67.42798026420496 63.23412382619729C75.05172688206231 74.12064300493368 81.60910393124286 88.51663218207919 81.72051567722401 95.77431163456914L79.96180168709174 94.48511857392927Z" className={this.props.getClass('Malaysia')} fill={`url(#regionImg${this.props.id('Malaysia')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
					<path id="South Korea" d="M63.16053631605274 15.315121531511606C71.88642718864214 31.89216318921587 90.11973901197321 61.13859611385914 55.3093855309382 73.79364737936447C20.50620205061921 86.44869864486914 13.40790134078895 100 15.68796156879574 84.17580841758C18.097081809707806 68.35161683516134 37.08324370832447 28.730192873019202 22.011902201189745 24.05535240553467C14.03886140388528 21.517172151717002 5.563920556391739 19.8752419875242 0 16.577041657703607L22.779092277908674 16.067971606797204L29.110202911020004 5.822040582203605L45.81630458162987 5.054850505485005L54.420305442030006 0C57.202265720225874 5.054850505485005 60.500466050045475 10.124041012404001 63.16053631605274 15.315121531511606Z" className={this.props.getClass('South Korea')} fill={`url(#regionImg${this.props.id('South Korea')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

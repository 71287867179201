import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M90.25584472871603 78.65902073224557L97.81649757388634 91.42920158800203C97.5782973092195 91.58800176444659 97.34450816056481 91.7423908248788 97.11513012792267 91.90119100132335C92.05116894574341 89.79267754741981 84.72871636524034 86.59902955447726 83.56418173797987 88.54874283193664C82.62902514336152 90.26025584472899 78.6546096162326 96.56815174239122 76.55491839435336 100L60.895456550507014 98.66784296427026C60.895456550507014 98.66784296427026 28.425231583590264 73.28628142920168 19.62505513895015 69.77944419938257C14.3273048081162 67.59594177326869 9.58094397882646 66.74459638288516 4.441993824437471 66.74459638288516L0 49.52801058667828C0.07498897220972642 49.52801058667828 0.07498897220972642 49.45302161446855 0.15438906043200473 49.45302161446855C17.75474194971345 38.78253198059112 24.99779444199342 35.82708425231581 24.99779444199342 24.68460520511686C24.99779444199342 13.475959417732476 25.544772827525076 13.003970004411155 29.435377150419153 10.04852227613604C31.773268636964826 8.25760917512121 37.61358623731788 3.5068372298191206 41.90119100132294 0C43.533303925893215 0 44.93603881782054 0.23820026466683497 45.94618438464943 0.7763564181737942C49.214821349801305 2.5716806352007695 55.60211733568561 3.6612262902515313 60.04411116012349 4.208204675782985L63.00397000441095 14.411116012351233L64.94927216585779 17.834142037935525L74.137626819585 19.77944419938256L72.27172474636045 25.156594618438586L68.37670930745463 29.986766651962956L67.82973092192297 39.71768857520948L63.93912659902931 41.199823555359686L70.2426113806792 45.55800617556253L73.2068813409788 54.83017203352437C73.2068813409788 54.83017203352437 84.8875165416849 57.238641376268035 86.83281870313174 63.625937362152946C88.78253198059113 69.93824437582713 90.25584472871603 78.65902073224557 90.25584472871603 78.65902073224557Z" id="Iraq" className={this.props.getClass('Iraq')} fill={`url(#regionImg${this.props.id('Iraq')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M91.10429447852732 27.070552147239418C92.65994741454752 31.13496932515388 100 41.78352322524087 88.01489921121758 40.79754601227032C75.83260297984205 40.030674846626056 62.09465381244431 47.392638036810204 50.10955302366289 53.00175284837852C37.927256792287366 58.61086765994735 29.031551270814685 68.2734443470643 24.967134092901233 50.4710780017531C21.099912357581893 32.8878177037687 0 22.436459246275305 1.7528483786153215 19.916739702016223C3.3085013146365325 17.397020157756636 33.0850131463623 9.465381244522332 24.967134092901233 6.178790534618983C23.214285714285914 5.411919368974717 20.508326029797544 5.028483786152331 17.41893076248881 4.842243645924561L26.895267309377985 0L51.07361963190177 10.823838737948929C52.03768624013964 17.024539877301095 55.521472392637605 22.633654688869417 61.32778264680054 23.203330411919573C70.99035933391698 23.970201577563838 89.55959684487195 23.203330411919573 91.10429447852732 27.070552147239418Z" id="Crimea" className={this.props.getClass('Crimea')} fill={`url(#regionImg${this.props.id('Crimea')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

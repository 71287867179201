import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path fillRule="evenodd" clipRule="evenodd" d="M99.36375871772944 39.10436804111111C100 36.302459317264656 96.97785390921374 33.91655450874838 94.6041845099719 32.39936375871803C65.23920225131569 16.1996818793593 33.047840450263585 5.187813532363291 0 0L1.7251927076962854 55.73228924507503C32.607365716383406 54.6555732289243 63.28153676740516 50.33647375504749 93.52746849382116 43.20323014804867C95.90113789306244 42.56698886577755 98.71528202618445 41.47803744035238 99.36375871772944 39.10436804111111Z" id="Dominican Republic" className={this.props.getClass('Dominican Republic')} fill={`url(#regionImg${this.props.id('Dominican Republic')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M10.555849225389402 39.26727117472464L13.857601756597605 39.05176269670255L16.15500345626797 41.27597283779938L17.952262839019337 39.4787134550482H22.90082543813274L29.5815882568214 34.09506770219172L33.16797462692632 31.874923758793237L29.5815882568214 26.91822876428259L25.991135689017007 23.762859350221838L17.448054324401248 26.056194852193862H12.995567844508878L8.038872849998045 22.465742284390032L16.58602041231252 16.150937258569442L14.146301793193045 14.069044036921163L16.58602041231252 12.275850851868325L18.379213597365545 7.176838937909317L38.551620379782996 12.133533932419777L37.68958646769427 2.2242101410970134L46.15947627373649 0L52.04733054121087 6.245679664945394L91.53417639165687 7.176838937909317L100 13.92672711747224L90.16793396494968 20.172406782417635L85.64632212418212 23.762859350221838H76.74134916439664L67.69812548286113 32.301874517138884L66.40507461472822 38.12060342373863L56.93083397714854 37.68552026999555L56.499817021103986 34.530150855934615L51.11617126824732 35.89232708494308L49.31891188549633 39.91379660879128L41.70698979384374 40.84495588175501L40.34887976253398 48.02586101736268L38.266986540885895 48.383686414833875L28.21534583011421 50.25413735615841L21.538649209125012 49.81905420241532L18.814296751108063 46.66368478835458L15.723986500223795 48.45687797340704L8.974098320660685 46.66368478835458L0 47.59484406131831L0.43508315374289336 42.64221526450638L8.038872849998045 39.4787134550482L10.555849225389402 39.26727117472464Z" id="Kyrgyzstan" className={this.props.getClass('Kyrgyzstan')} fill={`url(#regionImg${this.props.id('Kyrgyzstan')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';
import autobind from 'auto-bind';

export default class Progress extends React.Component
{
  constructor (props)
  {
    super (props);
    autobind(this);
    this.state = {
      msgIndex: null,
      isDone: false,
      startTime: null,
    };
  }

  inc ()
  {
    this.setState({
      msgIndex: this.state.msgIndex >= (this.state.msgs.length - 1) ? 0 : this.state.msgIndex + 1,
    });
    setTimeout(this.inc, 5000);
  }

  componentDidMount ()
  {
    let msgs = [
      'Sculpting Mountains...',
      'Filling the Oceans...',
    ];
    msgs.sort(() => (Math.random() > .5) ? 1 : -1);
    msgs.splice (msgs.length, 0,
      'Pondering the Meaning of Life...',
      'Running to the Store Real Quick...',
      `Need Anything While I'm There?`
    );
    this.setState({
      msgIndex: 0,
      msgs,
      startTime: new Date() / 1000,
    });
    setTimeout(this.inc, 5000);
  }

  render ()
  {
    if (!this.props.total) return (null);
    let percentage = Math.floor(100 * ((this.props.total - this.props.remaining) / this.props.total));
    if (percentage >= 100 && !this.state.isDone)
    {
      let nowTime = new Date() / 1000;
      if (nowTime - this.state.startTime < 2)
      {
        this.props.done();
      }
      else
      {
        this.setState({
          isDone: true,
        });
        setTimeout(this.props.done, 2000);
      }
    }
    let progressBarStyle = {
      width: percentage + '%',
      border: '1px solid black',
    };
    return (
          <div className="left-2.5 md:left-auto md:right-2.5 bottom-2.5 w-5/6 md:w-1/2 h-1/5 z-20 rounded-md p-7 drop-shadow-2xl shadow-2xl bg-white fixed">

            <div className="text-center m-5" style={{fontSize:'24pt'}}>
              <h3><b>{this.state.isDone ? 'Good to go!' : 'Just a Moment'}</b></h3>
              <div style={progressBarStyle} />
            </div>
            <div className="text-center m-3">
              { !this.state.isDone && this.state.msgIndex !== null && this.state.msgs[this.state.msgIndex] }
            </div>

          </div>
    );
  }
}


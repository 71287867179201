import React from 'react';
import Form from '../elements/Form.js';
import makeSnack from '../elements/Snack.js';
import ConfirmDialog from '../elements/ConfirmDialog.js';
import autobind from 'auto-bind';

export default makeSnack (class PasswordReset extends React.Component
{
  constructor (props)
  {
    super(props);
    autobind(this);
    this.state = {};
  }

  onSuccess ()
  {
    if (this.props.onSuccess)
    {
      this.props.onSuccess();
    }
  }

  render ()
  {
    let elements = [
      {
        label: 'Password',
        name: 'password',
        type: 'password',
      },
      {
        label: 'Confirm password',
        name: 'password2',
        type: 'password',
      },
    ];
    let fetch = {
      method: 'put',
      url: '/user/passwordReset',
      errorMsg: 'Your user could not be created due to a server error.  Please try again.',
      onSuccess: this.onSuccess,
      body: {
        id_user: this.props.userId,
      },
    };
    return (this.props.disabled ? null :
      <Form
        onClose={this.props.onClose}
        title="Reset Your Password"
        description="Please enter and confirm your new password below."
        elements={elements}
        submitLabel="Continue"
        fetch={fetch}
        confirmDialog={
          <ConfirmDialog
            label="Your password has been reset"
            onConfirm={this.onSuccess}
            onCancel={this.onSuccess}
            description={
              <React.Fragment>
                You can now login to your account again.
              </React.Fragment>
            }
            showNoBtn={false}
            yesBtnLabel="Ok"
          />
        }
      />
    );
  }
});


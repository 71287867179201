import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M96.67009955372434 17.273143380249483L100 21.00926879505679L98.99301979631484 28.584506236411027L91.71529923332156 30.094976541938227L91.41778235496034 41.211809131479505L85.75924018766443 43.73498111912109L84.14006179196652 45.85764961666078L71.82171873211983 46.86462982034566C71.82171873211983 46.86462982034566 70.91200366174587 50.90399359194394 66.7696532784069 50.90399359194394C62.73028950680837 50.90399359194394 53.33562192470541 49.29053667467659 53.33562192470541 49.29053667467659L37.37841858336181 46.36113971850309V39.59263073578213L25.15734065682577 39.99885570431378L21.821718732120356 43.83224625243148L14.04050806728449 44.141206087653L12.118091314795617 42.52202769195563H5.7558073006060475L3.232635312964467 39.186405767250214H0.7094633253228859L0 31.210664835793292H4.94907884197303L7.878475798146 35.35301521913278L12.118091314795617 35.753518709234406V30.300949765419066L18.892321775946336 31.210664835793292L21.821718732120356 34.54056528206842L30.609909600640847 29.894724796887413L35.66197505435377 26.564824350612287L47.07632452225679 32.82412175306091L45.76038448335038 22.319487355532647L50.005721478429756 14.343746424075988V11.008124499370311L60.20139604073702 9.0857077468817L64.34374642407546 12.62730289506795L68.89232177594685 11.814852958004119V7.5752374413547665L71.82171873211983 0L77.77777777777749 2.1226684975394248L82.8298432314904 5.052065453712922L87.88190868520385 9.291680970362803L95.85764961666051 8.885456001830887L96.37258267536261 8.582217645039389L96.67009955372434 17.273143380249483Z" id="Austria" className={this.props.getClass('Austria')} fill={`url(#regionImg${this.props.id('Austria')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

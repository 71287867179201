import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M14.279361229027936 66.80816656559541C11.926420052557479 62.16696988073576 8.211036992116732 54.74428946836447 7.640994542146425 53.531433191833145C6.783909440064437 51.74853446533225 1.8597129573480178 48.61127956337164 1.8597129573480178 48.61127956337164L0.07681423084712337 30.337578330301106L0 30.05053567818868L6.1410956135030395 30.47907822922986L13.276733373761905 20.98645643824562L13.919547200323303 17.129573478876118H9.993935718617253L9.779664443096664 12.77541944612889L11.206791995148587 12.92096219931256L14.489589650293238 9.993935718617253L12.419648271679918 4.641196684859654L10.568021022842103 0.07681423084712337L10.782292298362695 0H42.47422680412351L76.80614513846795 18.775015160703546V23.20194057004224L92.86436223974113 34.61896098645613C92.00727713765914 53.676975945016814 96.21588841722233 71.87790580149588 100 87.86335152617721L84.36628259551271 95.00303214069137L79.87062866383674 94.57448958965018L75.44774610875258 98.56882959369278C75.44774610875258 98.56882959369278 65.1708105922782 97.7117444916108 62.029512836062295 95.9288457651099C58.960986456438235 94.14594703860901 50.03436426116827 98.56882959369278 47.32565191024847 99.49868607236661L49.25409338993303 95.00303214069137C49.25409338993303 95.00303214069137 48.25146553466775 88.79320800485141 48.25146553466775 86.14918132201323C48.25146553466775 83.44046897109344 43.75581160299176 79.01354356175437 43.75581160299176 79.01354356175437L36.19163129169209 80.0161714170204L34.83323226197672 77.65918738629429L16.135031332120292 70.52354962603579C16.135031332120292 70.52354962603579 15.346674752375225 68.9508793208002 14.279361229027936 66.80816656559541Z" id="Tanzania" className={this.props.getClass('Tanzania')} fill={`url(#regionImg${this.props.id('Tanzania')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';
import Form from '../elements/Form.js';
import makeSnack from '../elements/Snack.js';
import autobind from 'auto-bind';

export default makeSnack (class Edit extends React.Component
{
  constructor (props)
  {
    super (props);
    autobind(this);
  }

  onSuccess (res)
  {
    this.props.openSnackbar(<span>The changes to the user have been saved.</span>);
    if (this.props.onSuccess)
    {
      this.props.onSuccess(res);
    }
  }

  render ()
  {
    let elements = [
      {
        label: 'Username',
        name: 'username',
        type: 'text',
        value: this.props.user.username,
      },
      {
        label: 'Password (leave blank if no change)',
        name: 'password',
        type: 'password',
        value: '',
      },
      {
        label: 'Confirm password',
        name: 'password2',
        type: 'password',
        value: '',
      },
      {
        label: 'Email',
        name: 'email',
        type: 'text',
        value: this.props.user.email,
      },
    ];
    if (!this.props.nonAdmin)
    {
      elements.push({
        label: 'User guid (for OpenSea)',
        name: 'guid',
        type: 'text',
        value: this.props.user.guid,
      });
      elements.push({
        label: 'User type',
        name: 'type',
        type: 'select',
        options: [
          { name:'admin', value:'admin' },
          { name:'player', value:'player' },
        ],
        value: this.props.user.type,
      });
      elements.push({
        label: 'Active',
        name: 'status',
        type: 'checkbox',
        value: this.props.user.status,
      });
    }
    let fetch = {
      method: 'put',
      url: `/user/${this.props.user.id}`,
      errorMsg: 'Your user changes could not be saved due to a server error.  Please try again.',
      onSuccess: this.onSuccess,
    };
    return (this.props.disabled ? null :
      <Form
        onClose={this.props.onClose}
        title="Edit User"
        elements={elements}
        submitLabel="Save"
        fetch={fetch}
      />
    );
  }
});


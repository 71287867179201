import React from 'react';
import Dialog from '../elements/Dialog.js';
import makeSnack from '../elements/Snack.js';
import Maps from '../maps/countries/all.js';
import Button from '../elements/Button.js';
import DialogButton from '../elements/DialogButton.js';
import Upload from './Upload.js';
import ApiButton from '../elements/ApiButton.js';
import ConfirmDialog from '../elements/ConfirmDialog.js';
import MyPattern from '../maps/Pattern.js';
import {get} from '../helpers/fetch.js';
import autobind from 'auto-bind';
const {regionName} = require('../helpers/region.js');

export default makeSnack (class Image extends React.Component
{
  constructor (props)
  {
    super (props);
    autobind(this);
    this.state = {
      region: props.region,
      image: props.region.image,
      rotation: props.region.image_rotation,
      scale: props.region.image_scale,
      x: props.region.image_x,
      y: props.region.image_y,
      dirty: false,
      isConfirmingClose: false,
      num: 0,
      enabled: true,
      counter: 0,
    };
  }

  async didUpdate ()
  {
    this.setState({
      image: null,
    });
    let region = await get ('/region/' + this.props.region.id);
    this.setState({
      region,
      image: region.image,
      rotation: 0,
      scale: 1,
      x: 0,
      y: 0,
      num: this.state.num + 1,
    });
    this.props.inc();
  }

  setRotation (e)
  {
    this.setState({
      rotation: e.target.value,
      dirty: true,
      counter: this.state.counter + 1,
    });
  }

  setScale (e)
  {
    this.setState({
      scale: e.target.value / 10,
      dirty: true,
      counter: this.state.counter + 1,
    });
  }

  setX (e)
  {
    this.setState({
      x: e.target.value,
      dirty: true,
      counter: this.state.counter + 1,
    });
  }

  setY (e)
  {
    this.setState({
      y: e.target.value,
      dirty: true,
      counter: this.state.counter + 1,
    });
  }

  close ()
  {
    if (this.props.onClose)
    {
      this.props.onClose();
    }
  }

  closeX ()
  {
    if (!this.state.dirty)
    {
      if (this.props.onClose)
      {
        this.props.onClose();
      }
    }
    else
    {
      this.setState({
        isConfirmingClose: true,
      });
    }
  }

  clearImage ()
  {
    this.setState({
      region: {
        ...this.state.region,
        image: '',
        image_rotation: 0,
        image_scale: 1,
        image_x: 0,
        image_y: 0,
      },
    });
  }

  render ()
  {
    if (this.props.disabled) return (null);
    let Map = Maps[this.state.region.original_name.split(' ').join('').split('-').join('')];
    if (this.props.disabled) return (null);
    return (
      <React.Fragment>
        <Dialog
          counter={this.state.counter}
          num={this.state.num}
          onClose={this.closeX}
          layout="idCardImageRight"
          title={'Image for ' + regionName(this.props.region)}
          image={
              <Map
                num={this.state.num}
                over={() => null}
                click={() => null}
                rotation={this.state.rotation}
                scale={this.state.scale}
                x={this.state.x}
                y={this.state.y}
                id={() => '_image'}
                getClass={this.props.getClass}
                defs={
        					<defs>
                      <MyPattern
                        num={this.state.num}
                        region={this.state.region}
                        suffix="_image"
                        image={this.state.image}
                        x={this.state.x}
                        y={this.state.y}
                        rotation={this.state.rotation}
                        scale={this.state.scale}
                      />
        					</defs>
        				}
              />
          }
        >
        { (!this.state.image || this.state.image === '') &&
          <div>
            <i>There is not currently an image for this country.</i>
            <br /><br />
          </div>
        }

        { (this.state.image && this.state.image !== '') &&
          <React.Fragment>
            <table>
              <tr><td>Rotation:</td><td><input type="range" min="0" max="360" value={this.state.rotation} onChange={this.setRotation} style={{width:'400px'}} /></td></tr>
              <tr><td>Size:</td><td><input type="range" min="0.1" max="100" step="0.1" value={this.state.scale * 10} onChange={this.setScale} style={{width:'400px'}} /></td></tr>
              <tr><td>Horizontal:</td><td><input type="range" min="-250" max="250" value={this.state.x} onChange={this.setX} style={{width:'400px'}} /></td></tr>
              <tr><td>Vertical:</td><td><input type="range" min="-250" max="250" value={this.state.y} onChange={this.setY} style={{width:'400px'}} /></td></tr>
            </table>
            <br />

            { !this.state.dirty ? null :
            <ApiButton
              label="Save"
              fetch={{
                method: 'put',
                url: `/region/${this.props.region.id}/imagePlacement`,
                body: {
                  rotation: this.state.rotation,
                  scale: this.state.scale,
                  x: this.state.x,
                  y: this.state.y,
                },
              }}
              onSuccess={this.close}
            />
            }
            &nbsp;

            <Button label={!this.state.dirty ? 'Close' : 'Cancel'} confirm={!this.state.dirty ? null : 'Are you sure you want to discard changes?'} onClick={this.close} />
            <br /><br />
          </React.Fragment>
        }

          <DialogButton
            label={(this.state.image && this.state.image !== '') ? 'Upload or Remove Image' : 'Upload Image'}
            dialog={<Upload didUpdate={this.didUpdate} region={this.props.region} onClearImage={this.clearImage} />}
            onClose={this.didUpdate}
          />
        </Dialog>
        { this.state.isConfirmingClose &&
          <ConfirmDialog
            onConfirm={this.close}
            onCancel={() => this.setState({isConfirmingClose:false})}
            onClose={() => this.setState({isConfirmingClose:false})}
            label="Are you sure?"
            description="Are you sure you want to discard any changes?"
          />
        }
      </React.Fragment>
    );
  }
});


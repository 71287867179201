import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M23.466780238500675 18.654173764906304L31.89097103918199 31.88245315161811L31.132879045997093 97.59795570698475C27.68313458262386 98.19420783645671 24.21635434412287 98.94378194207852 20.613287904599023 99.99999999999999L11.294718909709946 82.55536626916539L10.238500851788865 32.77683134582626L4.667802385009202 30.9710391822822L5.570698466780448 12.02725724020399L0 7.376490630323332L0.4599659284495027 0L16.848381601362636 0.6047700170354572L14.139693356047328 9.327086882452955L23.466780238500675 18.654173764906304Z" id="Togo" className={this.props.getClass('Togo')} fill={`url(#regionImg${this.props.id('Togo')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

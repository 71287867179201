import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M72.7365002030045 49.644742184328244L73.28461226146949 59.866017052375504L62.890783597238816 82.25740966301223L43.89971579374744 100C32.96792529435667 97.31019082419769 22.57409663012552 93.89971579374745 15.042630937880563 89.24076329679312C10.028420625254022 86.19569630531859 5.004060089322103 82.25740966301223 0 77.588306942753L3.0450669914740596 56.80064961429116H24.005278116118852L38.87535525781552 52.32440113682517L49.45188794153454 41.93057247259449L19.529029638651927 15.052781161185939L29.92285830288261 0H62.890783597238816L71.84328055217219 35.840438489646836L72.7365002030045 49.644742184328244Z" id="Guatemala" className={this.props.getClass('Guatemala')} fill={`url(#regionImg${this.props.id('Guatemala')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';
import autobind from 'auto-bind';

export default class Dialog extends React.Component
{
  constructor (props)
  {
    super (props);
    autobind(this);
    this.state = {
      disabled: props.disabled,
      onClose: props.onClose,
      layout: props.layout,
      title: props.title,
      description: props.description,
      footer: props.footer,
      image: props.image,
      counter: props.counter ? props.counter : 0,
    };
  }

  componentDidUpdate (props)
  {
    if (props.counter !== this.state.counter || props.title !== this.state.title)
    {
      this.setState({
        counter: props.counter,
        disabled: props.disabled,
        onClose: props.onClose,
        layout: props.layout,
        title: props.title,
        description: props.description,
        footer: props.footer,
        image: props.image,
      });
    }
  }

  onClose ()
  {
    if (this.state.onClose)
    {
      this.state.onClose();
    }
  }

  renderSimple () // this is default layout
  {
    return ((!this.state.layout || this.state.layout === 'simple') &&
      <React.Fragment>
        <div className="text-center m-5" style={{fontSize:'24pt'}}>
          <h3><b>{ this.state.title }</b></h3>
          <hr />
        </div>
        <div className="text-center m-3">
          { this.state.description }
        </div>
        <div>
          { this.props.children }
        </div>
        <div>
          { this.state.footer }
        </div>
      </React.Fragment>
    );
  }

  renderIdCardImageRight ()
  {
    return (this.state.layout === 'idCardImageRight' &&
        <div className="grid grid-cols-2 gap-4">

          <div className="grid grid-flow-row">
            <div>
              <b style={{fontSize:'24pt'}}>{ this.state.title }</b>
              <hr />
              <br />
              { this.props.children }
            </div>

            <div>
              { this.state.footer }
            </div>

          </div>

          <div className="text-center m-3">
            { this.state.image }
          </div>
        </div>
    );
  }

  render ()
  {
    return (this.state.disabled ? null :
        <div className="z-20 flex h-screen" style={{position:'fixed', top:0, left:0, width:'100%', height:'100%', fontFamily:'Montserrat, sans-serif'}}>
          <div onClick={this.onClose} className="opacity-75 bg-white" style={{width:'100%', height:'100%', position:'fixed', top:0, left:0}} />

          <div className="z-20 rounded-md p-7 drop-shadow-2xl shadow-2xl m-auto bg-white max-w-full w-3/4" style={{minHeight:'75%'}}>

            <div className="text-right cursor-pointer" onClick={this.onClose}>
              X
            </div>

            { this.renderSimple() }
            { this.renderIdCardImageRight() }

          </div>
        </div>
    );
  }
}


import React from 'react';
import MapChild from './MapChild.js';
import MyPattern from './Pattern.js';



import autobind from 'auto-bind';

export default class Map extends React.Component
{
	constructor (props)
	{
		super (props);
		autobind(this);
		this.state = {
			regions: null,
		};
	}

	async componentDidUpdate (props)
  {
    await this.refresh(props);
  }

  async componentDidMount ()
  {
    await this.refresh(this.props);
  }

	async refresh (props)
	{
		if (this.state.regions && this.state.regions.length) return;
		this.setState({
			regions: props.regions,
		});
	}

	id (name)
	{
		name = name.toLowerCase();
		let matchingRegions = this.state.regions.filter(region => region.original_name.toLowerCase() === name);
		if (matchingRegions.length)
		{
			return (matchingRegions[0].id);
		}
		else
		{
			//console.log('dbg', name + ' is unknown');//ega why this being triggered
			return ('unknown place');
		}
	}

	getClass (name)
	{
		name = name.toLowerCase();
		if (this.state.regions.some(region => region.image && region.image !== '' && region.original_name.toLowerCase() === name))
		{
			return ("region custom");
		}
		return ("region standard");
	}

	renderPatterns ()
	{
		return (this.state.regions.filter(region => region.image).map((region, i) =>
			<MyPattern
				key={i}
				notifyImage={this.props.notifyImage}
				imageDidLoad={this.props.imageDidLoad}
				region={region}
				suffix={region.id}
				image={region.image}
				x={region.image_x}
				y={region.image_y}
				rotation={region.image_rotation}
				scale={region.image_scale}
			/>
		));
	}

	render ()
	{
		return (!this.state.regions ? null :
			<MapChild
				id={this.id}
				getClass={this.getClass}
				over={this.props.over}
				click={this.props.click}
				defs={

						<defs>{this.renderPatterns()}</defs>




				}
			/>
		);
	}
}


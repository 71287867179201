import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M96.70228899940102 41.223150989313304L96.32843085387837 57.97270130144968L80.38655521461587 58.78037597432354L81.94547314217218 87.17596021585045L91.10147074383694 96.32843085387773C88.420978379713 96.76577434486676 85.36662787006708 97.32303459951356 83.99816597890893 97.6334073995695C81.94547314217218 98.19419461785347 69.49176453990746 98.94191090889845 66.87828448488708 99.50269812718241C64.32476281169556 100 55.232250555496854 97.13610552675223 55.232250555496854 97.13610552675223L52.61877050047614 94.5226254717312H16.06531936655746C16.06531936655746 94.5226254717312 12.393750220435182 91.40831658025606 11.395619511162835 90.4101858709837C10.460974147356541 89.47906747081457 5.170528691849381 92.09607448947213 0.12344372729494404 93.77490918068622C0 91.53528727118815 0.18340210912448293 89.16516770712082 0.6207456001128601 86.79857510669063C4.920114273621678 64.19779212076314 17.744154057771883 51.7405565548619 12.64063767502572 30.324833350968063C10.337530420061272 20.48460480372438 7.843967128698986 11.829436038514372 3.611610764293063 2.8638944732477687L37.17419673403187 0C37.17419673403187 0 40.288505625507014 10.894790674708078 41.0961802983812 12.079850456741426C41.843896589426166 13.1978979296722 45.765880153775825 18.304941276055526 45.765880153775825 18.304941276055526L58.59697386519938 17.8675977850665L61.70775579303801 9.339399710788925H69.49176453990746V11.642506965753048L79.63883892357089 12.450181638627232L80.38655521461587 29.57711705992277L82.75314781504636 34.62067506084004L83.50086410609133 43.96007477162897L96.70228899940102 41.223150989313304Z" id="Angola" className={this.props.getClass('Angola')} fill={`url(#regionImg${this.props.id('Angola')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';
import Dialog from '../elements/Dialog.js';
import makeSnack from '../elements/Snack.js';
import DataGrid from '../elements/DataGrid.js';
import {get} from '../helpers/fetch.js';
import {withCommas, asCurrency} from '../helpers/str.js';
import DialogButton from '../elements/DialogButton.js';
import Customize from '../region/Customize.js';
import Image from '../region/Image.js';
import autobind from 'auto-bind';
const {regionName} = require('../helpers/region.js');

export default makeSnack (class Regions extends React.Component
{
  constructor (props)
  {
    super (props);
    autobind(this);
    this.state = {
      regions: null,
    };
  }

  async componentDidUpdate ()
  {
    await this.refresh();
  }

  async componentDidMount ()
  {
    await this.refresh();
  }

  async refresh ()
  {
    if (!this.state.regions)
    {
      let regions = await get('/region');
      this.setState({
        regions,
      });
    }
  }

  renderColumns (num, column, value, region, row)
  {
    if (column === 'bought_price')
    {
      return (asCurrency(value) + ' MATIC');
    }
    else if (column === 'population')
    {
      return (withCommas(value));
    }
    else if (column === 'area')
    {
      return (<span>{withCommas(value)} km<sup>2</sup></span>);
    }
    else if (column === 'actions')
    {
      return (
        <React.Fragment>
          <DialogButton
            tiny={true}
            label="Rename"
            dialog={ <Customize region={row} onClose={this.refresh} /> }
          />
          &nbsp;
          <DialogButton
            tiny={true}
            label="Image"
            dialog={ <Image inc={this.props.inc} region={row} getClass={() => !row.id_owner ? 'region individual standard' : 'region individual custom'} onClose={this.refresh} /> }
          />
          <br /><br />
        </React.Fragment>
      );
    }
    return (value);
  }

  render ()
  {
    return ((!this.state.regions || this.props.disabled) ? null :
      <Dialog onClose={this.props.onClose} title={"Manage Countries"} >
        <div className="h-2/6 overflow-y-scroll max-h-screen">
          <div className="visible block md:invisible md:hidden">
            { this.state.regions.map((region, i) =>
              <div className={'align-middle text-center' + (i % 2 === 1 ? '' : ' bg-cyan-50')}>
                { this.renderColumns (0, 'original_name', regionName(region), region) } <br />
                { this.renderColumns (1, 'bought_price', region.bought_price, region) } <br />
                { this.renderColumns (2, 'owner', region.owner, region) } <br />
                { this.renderColumns (3, 'actions', null, region) }
              </div>
            )}
          </div>
          <div className="invisible hidden md:visible md:block">
            <DataGrid
              data={this.state.regions}
              orderColumns={[
                'original_name',
                'custom_name',
                'bought_price',
                'owner',
                'image',
                'actions',
              ]}
              highlightColumns={[
                true,
                true,
                true,
                true,
                true,
                false,
              ]}
              renderColumns={this.renderColumns}
            />
          </div>
        </div>
      </Dialog>
    );
  }
});


import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.js';
import './styles/index.css';

// see if user is requesting admin login
let adminLogin = window.location.href.includes('/admin');

ReactDOM.render(
  <React.StrictMode>
    <App adminLogin={adminLogin} />
  </React.StrictMode>,
  document.getElementById('root')
);


import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M85.84354516956397 66.1620119692221L82.82986605870613 69.17925334853216L73.89925904816214 69.93445426047282L69.42861214021106 72.57409518381287L66.28669136506115 69.68153320034202L58.17184383015097 72.07181533200334L48.73895696779711 63.14120832145902L46.41279566828167 65.5314904531205L41.9492732972357 63.95696779709299L39.306070105443254 66.28669136506115L29.81262467939558 58.86648617839829L29.623824451410663 58.677685950413206L29.873183243089073 53.708321459104994L23.083499572527657 51.88444001139924V46.665716728412626L14.149330293530948 39.30607010544293L10.25220860644071 33.01866628669137L0 30.37190082644622L0.5022798518098576 22.007694499857298L9.432886862353856 21.505414648047932L12.82772869763489 16.788971216870838L18.114135081219445 15.72029068110574L22.014819036762724 22.007694499857298L34.842547734397286 23.32929609575401L39.87247078939866 25.90837845540041L45.09119407238561 22.007694499857298L51.631518951267964 18.110572812767224L62.63892846964932 21.758335708179054L71.57309774864636 12.574807637503605V6.287403818751884L68.42761470504406 3.14192077514975L76.28954117982312 1.8238814477057501L77.29410088344251 0L79.43146195497303 0.7552009119406506L78.11342262752936 11.506127101738507L82.576944998575 16.222570532915434L85.6583072100311 18.299373040752467L85.1560273582219 22.5135366201197L85.4089484183527 30.37190082644622L75.72314049586772 31.194784838985456L74.71501852379595 37.7351097178683L85.1560273582219 40.37475064120836L87.79566828156163 45.59703619264752L92.26275292105998 46.91507552009119V56.600883442576176L99.61883727557681 57.356084354516824L100 63.58292960957522L97.48147620404693 63.39412938158997L96.15987460815022 60.50156739811912L91.69635223710458 60.248646337987836L85.84354516956397 66.1620119692221Z" id="South Sudan" className={this.props.getClass('South Sudan')} fill={`url(#regionImg${this.props.id('South Sudan')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

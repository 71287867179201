import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M98.70248538011681 26.772660818713565L100 51.955409356725376C76.77266081871399 46.125730994152 36.12938596491164 41.301169590644946 0 31.944444444445182L34.19225146198785 0L66.44736842105274 16.136695906433452L98.70248538011681 26.772660818713565Z" id="El Salvador" className={this.props.getClass('El Salvador')} fill={`url(#regionImg${this.props.id('El Salvador')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

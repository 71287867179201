import Zimbabwe from './Zimbabwe.js';
import Zambia from './Zambia.js';
import Yemen from './Yemen.js';
import WesternSahara from './Western Sahara.js';
import Vietnam from './Vietnam.js';
import Venezuela from './Venezuela.js';
import Uzbekistan from './Uzbekistan.js';
import UnitedStates from './United States.js';
import Uruguay from './Uruguay.js';
import UnitedKingdom from './United Kingdom.js';
import UnitedArabEmirates from './United Arab Emirates.js';
import Ukraine from './Ukraine.js';
import Uganda from './Uganda.js';
import Turkmenistan from './Turkmenistan.js';
import Turkey from './Turkey.js';
import Tunisia from './Tunisia.js';
import Togo from './Togo.js';
import Thailand from './Thailand.js';
import Tanzania from './Tanzania.js';
import Tajikistan from './Tajikistan.js';
import Taiwan from './Taiwan.js';
import Syria from './Syria.js';
import Switzerland from './Switzerland.js';
import Sweden from './Sweden.js';
import Swaziland from './Swaziland.js';
import SvalbardandJanMayen from './Svalbard and Jan Mayen.js';
import Sudan from './Sudan.js';
import SriLanka from './Sri Lanka.js';
import Spain from './Spain.js';
import SouthSudan from './South Sudan.js';
import SouthAfrica from './South Africa.js';
import Somalia from './Somalia.js';
import Slovenia from './Slovenia.js';
import Slovakia from './Slovakia.js';
import Singapore from './Singapore.js';
import SierraLeone from './Sierra Leone.js';
import Serbia from './Serbia.js';
import Senegal from './Senegal.js';
import SaudiArabia from './Saudi Arabia.js';
import Rwanda from './Rwanda.js';
import Russia from './Russia.js';
import Romania from './Romania.js';
import PuertoRico from './Puerto Rico.js';
import DominicanRepublic from './Dominican Republic.js';
import Portugal from './Portugal.js';
import PapuaNewGuinea from './Papua New Guinea.js';
import Poland from './Poland.js';
import Philippines from './Philippines.js';
import Brunei from './Brunei.js';
import Brunei_2 from './Brunei_2.js';
import Peru from './Peru.js';
import Paraguay from './Paraguay.js';
import Panama from './Panama.js';
import Pakistan from './Pakistan.js';
import Oman from './Oman.js';
import Norway from './Norway.js';
import NorthernIreland from './Northern Ireland.js';
import NorthKorea from './North Korea.js';
import Nigeria from './Nigeria.js';
import NewZealand from './New Zealand.js';
import Netherlands from './Netherlands.js';
import Nepal from './Nepal.js';
import Namibia from './Namibia.js';
import Myanmar from './Myanmar.js';
import Mozambique from './Mozambique.js';
import Morocco from './Morocco.js';
import Moldova from './Moldova.js';
import Mexico from './Mexico.js';
import Mauritania from './Mauritania.js';
import Mali from './Mali.js';
import Malawi from './Malawi.js';
import Macedonia from './Macedonia.js';
import Lithuania from './Lithuania.js';
import Libya from './Libya.js';
import Liberia from './Liberia.js';
import Lebanon from './Lebanon.js';
import Latvia from './Latvia.js';
import Laos from './Laos.js';
import Kyrgyzstan from './Kyrgyzstan.js';
import Kazakhstan from './Kazakhstan.js';
import Jordan from './Jordan.js';
import Japan from './Japan.js';
import Jamaica from './Jamaica.js';
import Italy from './Italy.js';
import Israel from './Israel.js';
import Ireland from './Ireland.js';
import Iraq from './Iraq.js';
import Iran from './Iran.js';
import India from './India.js';
import Indonesia from './Indonesia.js';
import Iceland from './Iceland.js';
import Hungary from './Hungary.js';
import Honduras from './Honduras.js';
import Haiti from './Haiti.js';
import Guyana from './Guyana.js';
import Guinea from './Guinea.js';
import Guatemala from './Guatemala.js';
import Greenland from './Greenland.js';
import Greece from './Greece.js';
import Ghana from './Ghana.js';
import Germany from './Germany.js';
import Georgia from './Georgia.js';
import Gambia from './Gambia.js';
import Gabon from './Gabon.js';
import FrenchGuyana from './French Guyana.js';
import France from './France.js';
import Finland from './Finland.js';
import FalklandIslands from './Falkland Islands.js';
import Ethiopia from './Ethiopia.js';
import Estonia from './Estonia.js';
import Eritrea from './Eritrea.js';
import ElSalvador from './El Salvador.js';
import Egypt from './Egypt.js';
import Djibouti from './Djibouti.js';
import Denmark from './Denmark.js';
import Congo from './Congo.js';
import CzechRepublic from './Czech Republic.js';
import Cyprus from './Cyprus.js';
import Cuba from './Cuba.js';
import Croatia from './Croatia.js';
import Crimea from './Crimea.js';
import IvoryCoast from './Ivory Coast.js';
import CostaRica from './Costa Rica.js';
import Colombia from './Colombia.js';
import China from './China.js';
import CentralAfricanRepublic from './Central African Republic.js';
import Canada from './Canada.js';
import Cameroon from './Cameroon.js';
import Cambodia from './Cambodia.js';
import Burundi from './Burundi.js';
import BurkinaFaso from './Burkina Faso.js';
import Bulgaria from './Bulgaria.js';
import BosniaandHerzegovina from './Bosnia and Herzegovina.js';
import Botswana from './Botswana.js';
import Bolivia from './Bolivia.js';
import Bhutan from './Bhutan.js';
import Benin from './Benin.js';
import Belgium from './Belgium.js';
import Belarus from './Belarus.js';
import Bangladesh from './Bangladesh.js';
import Azerbaijan from './Azerbaijan.js';
import Austria from './Austria.js';
import Australia from './Australia.js';
import Armenia from './Armenia.js';
import Argentina from './Argentina.js';
import Angola from './Angola.js';
import Algeria from './Algeria.js';
import Albania from './Albania.js';
import Afghanistan from './Afghanistan.js';
import Suriname from './Suriname.js';
import SouthKorea from './South Korea.js';
import Niger from './Niger.js';
import Montenegro from './Montenegro.js';
import Mongolia from './Mongolia.js';
import Malaysia from './Malaysia.js';
import Madagascar from './Madagascar.js';
import Luxembourg from './Luxembourg.js';
import Lesotho from './Lesotho.js';
import Kuwait from './Kuwait.js';
import Kenya from './Kenya.js';
import GuineaBissau from './Guinea Bissau.js';
import EquatorialGuinea from './Equatorial Guinea.js';
import Ecuador from './Ecuador.js';
import Chad from './Chad.js';
import Brazil from './Brazil.js';
import Belize from './Belize.js';

const all = {
	Zimbabwe,
	Zambia,
	Yemen,
	WesternSahara,
	Vietnam,
	Venezuela,
	Uzbekistan,
	UnitedStates,
	Uruguay,
	UnitedKingdom,
	UnitedArabEmirates,
	Ukraine,
	Uganda,
	Turkmenistan,
	Turkey,
	Tunisia,
	Togo,
	Thailand,
	Tanzania,
	Tajikistan,
	Taiwan,
	Syria,
	Switzerland,
	Sweden,
	Swaziland,
	SvalbardandJanMayen,
	Sudan,
	SriLanka,
	Spain,
	SouthSudan,
	SouthAfrica,
	Somalia,
	Slovenia,
	Slovakia,
	Singapore,
	SierraLeone,
	Serbia,
	Senegal,
	SaudiArabia,
	Rwanda,
	Russia,
	Romania,
	PuertoRico,
	DominicanRepublic,
	Portugal,
	PapuaNewGuinea,
	Poland,
	Philippines,
	Brunei,
	Brunei_2,
	Peru,
	Paraguay,
	Panama,
	Pakistan,
	Oman,
	Norway,
	NorthernIreland,
	NorthKorea,
	Nigeria,
	NewZealand,
	Netherlands,
	Nepal,
	Namibia,
	Myanmar,
	Mozambique,
	Morocco,
	Moldova,
	Mexico,
	Mauritania,
	Mali,
	Malawi,
	Macedonia,
	Lithuania,
	Libya,
	Liberia,
	Lebanon,
	Latvia,
	Laos,
	Kyrgyzstan,
	Kazakhstan,
	Jordan,
	Japan,
	Jamaica,
	Italy,
	Israel,
	Ireland,
	Iraq,
	Iran,
	India,
	Indonesia,
	Iceland,
	Hungary,
	Honduras,
	Haiti,
	Guyana,
	Guinea,
	Guatemala,
	Greenland,
	Greece,
	Ghana,
	Germany,
	Georgia,
	Gambia,
	Gabon,
	FrenchGuyana,
	France,
	Finland,
	FalklandIslands,
	Ethiopia,
	Estonia,
	Eritrea,
	ElSalvador,
	Egypt,
	Djibouti,
	Denmark,
	Congo,
	CzechRepublic,
	Cyprus,
	Cuba,
	Croatia,
	Crimea,
	IvoryCoast,
	CostaRica,
	Colombia,
	China,
	CentralAfricanRepublic,
	Canada,
	Cameroon,
	Cambodia,
	Burundi,
	BurkinaFaso,
	Bulgaria,
	BosniaandHerzegovina,
	Botswana,
	Bolivia,
	Bhutan,
	Benin,
	Belgium,
	Belarus,
	Bangladesh,
	Azerbaijan,
	Austria,
	Australia,
	Armenia,
	Argentina,
	Angola,
	Algeria,
	Albania,
	Afghanistan,
	Suriname,
	SouthKorea,
	Niger,
	Montenegro,
	Mongolia,
	Malaysia,
	Madagascar,
	Luxembourg,
	Lesotho,
	Kuwait,
	Kenya,
	GuineaBissau,
	EquatorialGuinea,
	Ecuador,
	Chad,
	Brazil,
	Belize,
};

export default all;


import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M77.03349282296517 2.373205741626358L84.4593301435405 3.04306220095613V12.842105263156053L100.00000000000001 15.540669856457754L95.94258373205791 59.80861244018903L20.95693779904465 89.20574162679145L0 64.86124401913624L2.698564593302581 39.5215311004768L0.3253588516753418 19.92344497607607L21.282296650718227 10.124401913874385L44.93779904306193 11.48325358851566L46.29665071770497 0L77.03349282296517 2.373205741626358Z" id="Macedonia" className={this.props.getClass('Macedonia')} fill={`url(#regionImg${this.props.id('Macedonia')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M86.15564657694581 29.350497366880965L100 43.81509654768898C100 43.81509654768898 92.97834991222982 42.98420128730214 92.14745465184352 45.66413107080202C91.32826214160296 48.14511410181409 93.80924517261612 61.989467524868274 93.80924517261612 61.989467524868274C93.80924517261612 61.989467524868274 84.50555880632004 59.50848449385621 79.34464599180863 70.66120538326531C76.65301345816356 76.65301345816248 73.9613809245174 85.95669982445962 72.29959040374482 93.38794616734955C61.369221767115526 88.63662960795789 49.994148624926595 86.56524283206663 43.59274429490861 80.58513750731467C43.59274429490861 75.00292568753724 42.761849034523394 67.15038033938022 38.82972498537067 61.15857226448252C31.808074897600477 50.836746635460244 0 14.675248683439944 0 14.675248683439944V0C0 0 7.840842598010745 9.514335868929075 14.663545933293666 8.68344060854278C21.486249268577662 7.864248098302763 27.677004095962634 0 27.677004095962634 0H53.516676418957964L65.50029256875445 2.480983031012069L86.98654183733211 10.345231129314833L96.48917495611545 18.186073727326114L91.32826214160296 25.008777062610115L86.15564657694581 29.350497366880965Z" id="Bosnia and Herzegovina" className={this.props.getClass('Bosnia and Herzegovina')} fill={`url(#regionImg${this.props.id('Bosnia and Herzegovina')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';
import Spinner from '../elements/Spinner.js';
import {doFetch} from '../helpers/fetch.js';
import makeSnack from '../elements/Snack.js';
import autobind from 'auto-bind';

export default makeSnack (class ApiButton extends React.Component
{
  constructor (props)
  {
    super (props);
    autobind(this);
    this.state = {
      pending: false,
    };
  }

  async onClick ()
  {
    this.setState({
      pending: true,
    });
    if (this.props.onPending)
    {
      this.props.onPending();
    }
    if (this.props.onClick)
    {
      this.props.onClick();
    }
    let fetchSpecs = (typeof this.props.fetch === 'function') ? this.props.fetch() : this.props.fetch;
    let body = fetchSpecs.body ? fetchSpecs.body : {};
    let res;
    try
    {
      res = await doFetch(fetchSpecs.method, fetchSpecs.url, body);
      this.setState({
        pending: false,
      });
    }
    catch (error)
    {
      this.setState({
        pending: false,
      });
      this.props.openSnackbar(<span>{ JSON.stringify(error) }</span>);
      if (this.props.onError)
      {
        this.props.onError(error);
      }
      return;
    }
    if (this.props.onSuccess)
    {
      this.props.onSuccess(res);
    }
  }

  render ()
  {
    if (this.props.disabled) return (null);
    let className = 'text-center w-3/6 rounded-md p-3 hover:bg-green-400 text-white bg-green-500 uppercase cursor-pointer';
    if (this.props.tiny)
    {
      className = 'border border-solid p-1 text-center lowercase inline cursor-pointer';
    }
    return (
      <span onClick={this.onClick} className={className}>
        <b>{ this.state.pending ? <Spinner tiny={this.props.tiny} /> : this.props.label }</b>
      </span>
    );
  }
});


import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M92.42189698845962 14.410357444413291L94.53278919223192 22.115113988179242L85.71629608781228 28.820714888826583L85.21671826625362 38.13678581480421L94.53278919223192 41.73937517590754L92.92147481001894 56.76892766676013L90.31100478468863 72.29805797917267L83.6054038840413 72.79059949338576L82.6062482409227 67.69631297495074L78.38446383338007 72.29805797917267H71.17928511117343L64.47368421052543 61.98283141007578L62.98198705319374 73.29017731494505L45.34196453701021 71.29890233605407L46.82662538699697 81.61412890515031L58.26062482409182 88.31972980579765L52.05460174500378 93.5336335491133L45.841542358570166 89.93104418801063L41.73937517590754 100C33.90796509991472 88.43934703067829 24.345623416830225 75.90064734027534 12.426118772867229 61.743596960315145C0 46.953278919223195 12.426118772867229 43.09738249366743 17.140444694624225 22.115113988179242C17.140444694624225 22.115113988179242 17.759639741063527 20.243456234168892 18.37883478750347 17.02082746974423L42.7385308190255 17.38671545173109L43.85026737967853 0.36588798198750677L73.41683084717063 0L74.78187447227674 15.402476780185674L92.42189698845962 14.410357444413291Z" id="Gabon" className={this.props.getClass('Gabon')} fill={`url(#regionImg${this.props.id('Gabon')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';
import Dialog from '../elements/Dialog.js';
import {get} from '../helpers/fetch.js';
import makeSnack from '../elements/Snack.js';
import ordinal from '../helpers/ordinal.js';
import autobind from 'auto-bind';
const {regionName} = require('../helpers/region.js');

export default makeSnack (class TopRegions extends React.Component
{
  constructor (props)
  {
    super (props);
    autobind(this);
    this.state = {
      regions: null,
    };
  }

  async componentDidUpdate ()
  {
    await this.refresh();
  }

  async componentDidMount ()
  {
    await this.refresh();
  }

  async refresh ()
  {
    if (!this.state.regions)
    {
      let regions = await get ('/region/top');
      this.setState({
        regions,
      });
    }
  }

  renderRegions ()
  {
    if (!this.state.regions)
    {
      return (null);
    }
    else if (!this.state.regions.length)
    {
      return (
        <div>There is not enough activity yet to display top countries.</div>
      );
    }
    else
    {
      return (
        <div className="max-h-96 overflow-y-auto">
          <div className="visible block md:invisible md:hidden">
            <table>
              { this.state.regions.map((region, i) =>
                  <tr className={'align-middle' + (i % 2 === 1 ? '' : ' bg-cyan-50')}>
                    <td className="p-3 text-center">
                      <b>{ ordinal(i+1) }:</b> <br />
                      { regionName(region) } <br />
                      Total GDP: { region.bought_price.toLocaleString('en-US') } MATIC <br />
                      Owner: { region.user.username }
                    </td>
                  </tr>
              )}
            </table>
          </div>
          <div className="invisible hidden md:visible md:block">
            <table>
              { this.state.regions.map((region, i) =>
                  <tr className={'align-middle' + (i % 2 === 1 ? '' : ' bg-cyan-50')}>
                    <td className="p-3">{ ordinal(i+1) }</td>
                    <td className="p-3">{ regionName(region) }</td>
                    <td className="p-3">Total GDP: { region.bought_price.toLocaleString('en-US') } MATIC</td>
                    <td className="p-3">Owner: { region.user.username }</td>
                  </tr>
              )}
            </table>
          </div>
        </div>
      );
    }
  }

  render ()
  {
    return (this.props.disabled ? null :
      <Dialog onClose={this.props.onClose} title={"Top Countries"}>
        { this.renderRegions() }
      </Dialog>
    );
  }
});


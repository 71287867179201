import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M9.727755644090328 46.99867197875173C5.630810092960938 43.12749003984063 0 38.09428950863236 0 38.09428950863236L6.5670650730410385 35.15936254980105L6.912350597609248 22.264276228419735L15.358565737051267 20.51128818061068L16.527224435591044 11.719787516600757L31.5338645418321 18.63213811421031L41.035856573704756 17.11155378486063L41.38114209827296 9.840637450199472L52.05179282868478 11.719787516600757L52.403718459494996 7.968127490040188L60.4980079681268 9.130146082337353L69.98671978751607 0L75.49136786188569 2.56972111553801L90.49800796812735 2.802124833997382C90.49800796812735 2.928286852589918 90.49800796812735 2.928286852589918 90.49800796812735 2.928286852589918L100 14.648074369190065C100 14.648074369190065 90.49800796812735 22.616201859229946 88.28021248339955 25.192563081009347C86.04913678618836 27.77556440903075 82.41699867197832 46.062416998671935 78.4329349269587 47.93492695883183C74.3227091633459 49.81407702523251 74.3227091633459 52.742363877822115 65.53120849933568 52.742363877822115C65.06640106241693 52.742363877822115 64.59495351925558 52.742363877822115 64.12350597609483 52.742363877822115C56.035856573705026 52.86188579017265 49.82735723771559 53.798140770252445 49.82735723771559 53.798140770252445C49.82735723771559 53.798140770252445 47.01195219123451 56.14209827357247 43.7250996015933 58.4926958831342V58.60557768924304C40.44488711819347 60.836653386454536 36.69986719787429 63.30013280212479 35.17264276228352 63.30013280212479C32.24435590969453 63.30013280212479 14.309428950863246 53.10092961487372 13.950863213811031 51.580345285524956C13.83798140770189 51.1088977423639 12.078353253651443 49.229747675963225 9.727755644090328 46.99867197875173Z" id="Hungary" className={this.props.getClass('Hungary')} fill={`url(#regionImg${this.props.id('Hungary')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

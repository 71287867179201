import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M87.53891715392211 10.198192725339602C87.53891715392211 10.198192725339602 94.90470043283428 12.734452122408518 100 14.086111322043653C98.39015870605226 15.422583339660768 96.25635963247045 16.8957399954432 95.31475434733021 16.8957399954432C93.70491305338247 16.8957399954432 89.94608550383455 28.825271470878544 89.40694054218282 30.564203811982072L72.24542486141748 30.306021717669807L65.95033791479995 27.3597084061046L55.099096362669805 37.80848963474798L45.84250892246974 36.47201761713086L45.44004859898263 40.762396537322374L33.237147847217 38.61341028172185L32.84228111473942 46.92839243678273L21.975852380590915 48.6673247778873L4.814336699826269 40.762396537322374L0.3948667324782768 35.8037816083223L0 24.269116865365238L8.299794973042857 18.505581289391294H18.900448021869572V9.529956716531045L24.5272989596782 7.380970460930523L30.025058850331042 5.642038119826995L32.16645151492168 3.4930518642264725L32.303136153086996 4.032196825878549L43.4353405725573 0L49.06219151036593 11.268889057634922C49.06219151036593 11.268889057634922 59.65525096818311 5.1028931581742185 64.07472093553041 7.920115422582949C68.63087554104371 10.729744095982495 73.05793909939983 6.173589490469888 73.05793909939983 6.173589490469888L81.37292125446176 5.642038119826995L87.53891715392211 10.198192725339602Z" id="Slovakia" className={this.props.getClass('Slovakia')} fill={`url(#regionImg${this.props.id('Slovakia')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';
import Dialog from '../elements/Dialog.js';
import {get} from '../helpers/fetch.js';
import Customize from '../region/Customize.js';
import Image from '../region/Image.js';
import makeSnack from '../elements/Snack.js';
import DialogButton from '../elements/DialogButton.js';
import autobind from 'auto-bind';
const {regionName} = require('../helpers/region.js');

export default makeSnack (class MyRegions extends React.Component
{
  constructor (props)
  {
    super (props);
    autobind(this);
    this.state = {
      regions: null,
    };
  }

  async componentDidUpdate (props)
  {
    await this.refresh(props.user);
  }

  async componentDidMount ()
  {
    await this.refresh(this.props.user);
  }

  async refresh (user)
  {
    if (user)
    {
      if (!this.state.regions)
      {
        let regions = await get ('/region/ownedBy', {
          id_user: user.id,
        });
        this.setState({
          regions,
        });
      }
    }
  }

  renderRegions ()
  {
    if (!this.state.regions)
    {
      return (null);
    }
    else
    {
      if (!this.state.regions.length)
      {
        return (<div>You don't currently own any countries.</div>);
      }
      else
      {
        return (
          <div className="max-h-96 overflow-y-auto">
            <div className="visible block md:invisible md:hidden">
              <table>
                { this.state.regions.map((region, i) =>
                  <tr className={'align-middle' + (i % 2 === 1 ? '' : ' bg-cyan-50')}>
                    <td className="p-3 text-center">
                      <b> {i+1}. { regionName(region) } </b> <br /><br />
                      <DialogButton
                        label="Rename"
                        dialog={ <Customize region={region} /> }
                        onClose={() => this.refresh(this.props.user)}
                      />
                      &nbsp;
                      <DialogButton
                        label="Image"
                        dialog={ <Image inc={this.props.inc} onClose={this.refreshRegion} region={region} getClass={() => 'region custom'} /> }
                      />
                      <br /><br />
                    </td>
                  </tr>
                )}
              </table>
            </div>
            <div className="invisible hidden md:visible md:block">
              <table>
                { this.state.regions.map((region, i) =>
                  <tr className={'align-middle' + (i % 2 === 1 ? '' : ' bg-cyan-50')}>
                    <td className="p-3">{i+1}.</td>
                    <td className="p-3">{ regionName(region) }</td>
                    <td>
                      <DialogButton
                        label="Rename"
                        dialog={ <Customize region={region} /> }
                        onClose={() => this.refresh(this.props.user)}
                      />
                      &nbsp;
                      <DialogButton
                        label="Image"
                        dialog={ <Image inc={this.props.inc} onClose={this.refreshRegion} region={region} getClass={() => 'region custom'} /> }
                      />
                      &nbsp;
                    </td>
                  </tr>
                )}
              </table>
            </div>
          </div>
        );
      }
    }
  }

  render ()
  {
    return (this.props.disabled ? null :
      <Dialog onClose={this.props.onClose} title={"My Countries"} >
        { this.renderRegions() }
      </Dialog>
    );
  }
});


import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M50.28192157747183 92.8923542868186L41.69539581789452 92.43483584109278L38.8504043560909 100L34.584528143828315 94.31323903727801L28.44347069626573 93.85894255243743L24.628669007958184 92.43483584109278L18.03009311466963 99.54570351515913L12.340110191062237 100L10.406933659825317 90.04736282501513L7.565164158907236 78.21632245384559L10.406933659825317 73.49614975674183L6.653349228340385 71.55652930373428L3.808357766536763 62.57048039436793L0 59.725488932564154L1.6496439733221755 52.84338048136085L5.458001739858939 49.318555272738685L1.8751812352998434 47.668911299416806L2.841769500918377 43.11950252923933V37.42951960563179L5.458001739858939 34.13023165898773V24.406353706865907L6.14105744756258 19.90849631085472L3.299287946644351 14.676031832973893L1.5916486773849507 9.498340690144008L9.498340690144008 9.952637174984885L23.71685407739148 0H32.25182846280235L33.1636433933692 16.09691658343271L38.39610787125032 18.941908045236183L46.927860295775794 20.362792795695594L57.338015916486654 27.476882430647198L66.83635660663052 28.89776718110661L68.71153784193021 39.362696136868855L65.86976834101213 41.24109933305409L68.25724135708977 43.11950252923933L68.71153784193021 49.772851757579566L82.02468022038177 49.318555272738685V55.00531623546098L87.71466314398916 61.14959564390881C87.71466314398916 61.14959564390881 88.62325611367046 64.44888359055317 86.74807487837062 67.80616683313458C85.607500724941 69.79733866030872 85.15320424010028 73.49614975674183 84.98244031317444 76.11238199568254L78.20987853207436 72.5295614911233L56.88049747076068 74.40152076553804L50.7394400231981 92.8923542868186H50.28192157747183Z" id="Bolivia" className={this.props.getClass('Bolivia')} fill={`url(#regionImg${this.props.id('Bolivia')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M37.87185354690924 3.5469107551481898L72.19679633867425 0C74.7711670480524 3.5469107551481898 76.77345537757343 7.608695652172538 78.77574370709183 11.641876430206402C85.35469107551468 24.771167048053723 92.41990846681927 36.35583524027388 100 46.967963386727966L72.7402745995422 93.44965675057158L0 90.38901601830511V35.86956521738953L31.321510297480824 4.061784897024348L37.87185354690924 3.5469107551481898Z" id="Djibouti" className={this.props.getClass('Djibouti')} fill={`url(#regionImg${this.props.id('Djibouti')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

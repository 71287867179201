import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M98.06357325539011 2.2652539276576795C100 5.791012056997056 99.01351845085911 10.632078918523433 96.78480087687416 14.194373401534694C94.51954694921564 17.72013153087407 90.95725246620438 20.31421264157871 87.74205334307686 22.89002557544909C76.43405188162272 31.275118743149104 65.16258677384047 39.33138472780468 53.544026306172796 47.07709170624756C51.62586773840138 48.04530507855418 49.671172816951355 49.34234563390608 47.75301424917658 49.9817318231649C43.88016075995514 51.91815856777561 39.36792108147488 51.91815856777561 34.837413226159526 52.246985750822574C26.78114724150647 52.246985750822574 18.085495067592916 51.60759956156376 11.308001461454138 47.38765071245857C4.201680672270081 43.204238217026116 0 33.85093167701948 3.872853489221438 26.452320058458668C7.745706978442876 19.01717208622597 17.427840701497313 17.40957252466221 26.14176105224597 16.75191815856829C36.13445378151226 16.11253196931031 46.45597369382552 15.491413956887435 56.77749360613709 14.833759590793514C60.97917427840717 14.504932407746553 65.47314578005064 14.194373401534694 69.67482645232073 12.568505663134152C74.51589331384626 10.632078918523433 78.69930580928124 7.088052612350638 83.21154548775982 4.493971501644316C87.74205334307686 1.9181585677756179 93.22250639386122 0 98.06357325539011 2.2652539276576795Z" id="Cyprus" className={this.props.getClass('Cyprus')} fill={`url(#regionImg${this.props.id('Cyprus')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

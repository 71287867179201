import React from 'react';
import Dialog from '../elements/Dialog.js';
import {get, reqdelete} from '../helpers/fetch.js';
import makeSnack from '../elements/Snack.js';
import Button from '../elements/Button.js';
import autobind from 'auto-bind';
const {regionName} = require('../helpers/region.js');

export default makeSnack (class ViewWatchlist extends React.Component
{
  constructor (props)
  {
    super (props);
    autobind(this);
    this.state = {
      watchlistItems: null,
    };
  }

  async componentDidUpdate (props)
  {
    await this.refresh(props.user);
  }

  async componentDidMount ()
  {
    await this.refresh(this.props.user);
  }

  async refresh (user)
  {
    if (user)
    {
      if (!this.state.watchlistItems)
      {
        let watchlistItems = await get (`/watchlist/${user.id}`);
        this.setState({
          watchlistItems,
        });
      }
    }
  }

  async remove (item)
  {
    await reqdelete (`/watchlist/${item.id}`);
    this.setState({
      watchlistItems: this.state.watchlistItems.filter(i => i.id !== item.id),
    });
    this.props.openSnackbar(<span>{regionName(item.region)} has been removed from your watchlist.</span>);
  }

  renderWatchlistItems ()
  {
    if (!this.state.watchlistItems)
    {
      return (null);
    }
    else
    {
      if (!this.state.watchlistItems.length)
      {
        return (<div>You aren't currently watching any countries.</div>);
      }
      else
      {
        return (
          <div className="max-h-96 overflow-y-auto">
            <div className="visible block md:invisible md:hidden">
              <table>
                {this.state.watchlistItems.map((item, i) =>
                  <tr className={'align-middle' + (i % 2 === 1 ? '' : ' bg-cyan-50')}>
                    <td className="p-3 text-center">
                      <b> {i+1}. { regionName(item.region) } </b> <br /> <br />
                      <Button color="red" label="Remove" onClick={() => this.remove(item)} />
                      <br /><br />
                    </td>
                  </tr>
                )}
              </table>
            </div>
            <div className="invisible hidden md:visible md:block">
              <table>
                {this.state.watchlistItems.map((item, i) =>
                  <tr className={'align-middle' + (i % 2 === 1 ? '' : ' bg-cyan-50')}>
                    <td className="p-3">{i+1}.</td>
                    <td className="p-3">
                      <span className="ml-1 mr-4">
                        { regionName(item.region) }
                      </span>
                      &nbsp;
                    </td>
                    <td className="p-3">
                      <Button color="red" label="Remove" onClick={() => this.remove(item)} />
                    </td>
                  </tr>
                )}
              </table>
            </div>
          </div>
        );
      }
    }
  }

  render ()
  {
    return (this.props.disabled ? null :
      <Dialog onClose={this.props.onClose} title={"My Watchlist"} >
        { this.renderWatchlistItems() }
      </Dialog>
    );
  }
});


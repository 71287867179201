import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M99.71969536704056 7.652845356463193L100 23.89993653480006L59.75777448698957 24.465834567378806L62.65601861645874 56.5845144912208L48.08546646921966 63.02094351597222V83.4726041887032H0.930822932092229C0 75.81447006558064 1.5866299978848686 72.36090543685218 4.849799026867526 66.47979691136052C10.741485085678162 56.018616458641816 41.0831394118895 13.444044848741441 44.63190184049097 6.441717791411276C45.377617939496524 4.855087793526651 47.99555743600601 2.6126507298501167 51.63422889782089 0H99.62449756716754L99.71969536704056 7.652845356463193Z" id="Western Sahara" className={this.props.getClass('Western Sahara')} fill={`url(#regionImg${this.props.id('Western Sahara')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M2.167541408220404 22.38429554904238L2.8900552109613757 20.339445163929035L11.198963942472192 15.04328266648477L23.22950037488947 10.585508826937216L24.067889032786496 0H32.25410674119057L44.652716242928705 8.540658441824498C44.652716242928705 9.740304001091069 44.76859109808539 10.953581896258076 45.00715697634819 12.030536432417904C46.56805943698493 18.049212732601887 48.62654215799871 30.563697089496394 45.613795923931846 35.13734578420031C42.601049689864986 39.58830345579686 39.588303455797494 49.096857746575196 36.58237338968069 49.096857746575196C33.576443323563886 49.096857746575196 29.486742553337194 52.102787812692 29.486742553337194 52.102787812692L29.118669484016333 97.83245859177958C24.313271078999986 98.07102447004333 19.132983436712088 98.80035444073343 13.598255061005336 100L14.204894008588369 47.41326426283139L7.463703905664357 36.827755435894176L0 29.36405153022982L2.167541408220404 22.38429554904238Z" id="Benin" className={this.props.getClass('Benin')} fill={`url(#regionImg${this.props.id('Benin')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

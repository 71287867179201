import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M100 21.530753031733347L84.86674896521673 47.17885411371705C76.79180887372037 34.3548035727252 83.20383414421629 19.22881417471519 68.84031660736338 18.466342313558087C54.36061288214401 17.56589935371441 47.17159247694446 25.640839445211114 47.17159247694446 25.640839445211114C47.17159247694446 25.640839445211114 50.38123593057889 55.25379420521409 29.48224529809033 39.23462348413333C18.967395250889563 31.14516011909111 8.844673589427066 25.125263234333122 0 20.25270495969792L6.288577445356205 3.5945102025997735C21.283857381453906 11.153874083218485 34.616222496550826 10.769007334253471 55.12308474330111 5.635030135792306C77.69225183356338 0 100 21.530753031733347 100 21.530753031733347Z" id="Panama" className={this.props.getClass('Panama')} fill={`url(#regionImg${this.props.id('Panama')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';
import Dialog from '../elements/Dialog.js';
import {get} from '../helpers/fetch.js';
import makeSnack from '../elements/Snack.js';
import autobind from 'auto-bind';
const {regionName} = require('../helpers/region.js');

export default makeSnack (class Transactions extends React.Component
{
  constructor (props)
  {
    super (props);
    autobind(this);
    this.state = {
      transactions: null,
    };
  }

  async componentDidUpdate ()
  {
    await this.refresh();
  }

  async componentDidMount ()
  {
    await this.refresh();
  }

  async refresh ()
  {
    if (!this.state.transactions)
    {
      let transactions = await get ('/transaction');
      this.setState({
        transactions,
      });
    }
  }

  renderTransactions ()
  {
    if (!this.state.transactions)
    {
      return (null);
    }
    else if (!this.state.transactions.length)
    {
      return (
        <div>There is not enough activity yet to display transactions.</div>
      );
    }
    else
    {
      return (
        <div className="max-h-96 overflow-y-auto">
          <div className="visible block md:invisible md:hidden">
            <table>
              { this.state.transactions.map((transaction, i) =>
                <tr className={'align-middle' + (i % 2 === 1 ? '' : ' bg-cyan-50')}>
                  <td className="p-3 text-center">
                    <b> {regionName(transaction)} </b> <br />
                    Sold by {transaction.seller ? transaction.seller : 'System'} to {transaction.buyer} <br />
                    for {transaction.price} MATIC <br />
                    on {transaction.when_bought}
                  </td>
                </tr>
              )}
            </table>
          </div>
          <div className="invisible hidden md:visible md:block">
            <table>
              { this.state.transactions.map((transaction, i) =>
                <tr className={'align-middle' + (i % 2 === 1 ? '' : ' bg-cyan-50')}>
                  <td className="p-3"><b> {regionName(transaction)} </b></td>
                  <td className="p-3">Sold by {transaction.seller ? transaction.seller : 'System'} to {transaction.buyer}</td>
                  <td className="p-3">for {transaction.price} MATIC</td>
                  <td className="p-3">on {transaction.when_bought}</td>
                </tr>
              )}
            </table>
          </div>
        </div>
      );
    }
  }

  render ()
  {
    return (this.props.disabled ? null :
      <Dialog onClose={this.props.onClose} title={"Transactions"}>
        { this.renderTransactions() }
      </Dialog>
    );
  }
});


import React from 'react';
import Form from '../elements/Form.js';
import makeSnack from '../elements/Snack.js';
import ConfirmDialog from '../elements/ConfirmDialog.js';
import autobind from 'auto-bind';

export default makeSnack (class Register extends React.Component
{
  constructor (props)
  {
    super(props);
    autobind(this);
    this.state = {};
  }

  render ()
  {
    let elements = [
      {
        label: 'Username',
        name: 'username',
        type: 'text',
      },
      {
        label: 'Password',
        name: 'password',
        type: 'password',
      },
      {
        label: 'Confirm password',
        name: 'password2',
        type: 'password',
      },
      {
        label: 'Email',
        name: 'email',
        type: 'text',
      },
      {
        label: 'Already have an account?',
        type: 'link',
        func: this.props.switchToLogin,
        footer: true,
      },
    ];
    let fetch = {
      method: 'post',
      url: '/user',
      errorMsg: 'Your user could not be created due to a server error.  Please try again.',
      onSuccess: this.props.onSuccess,
      body: {
        type: 'player',
      },
    };
    return (this.props.disabled ? null :
      <Form
        onClose={this.props.onClose}
        title="CryptoConquerors"
        description="Join the Crusade"
        elements={elements}
        submitLabel="Sign Up"
        fetch={fetch}
        confirmDialog={
          <ConfirmDialog
            label="Thank you for registering!"
            description={
              <React.Fragment>
                Your email needs to be verified before you can purchase countries.
                <br />
                Please check your inbox for a verification email that was just sent to you.
              </React.Fragment>
            }
            showNoBtn={false}
            yesBtnLabel="Ok"
          />
        }
      />
    );
  }
});


import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M76.0231577824023 63.68123365882276L71.17016167760507 64.10810522384065L62.31791259804716 69.95090977002292L58.21994557387539 69.38530494637405L54.967717837895584 73.80876153887176L50.25879088629195 78.23755402593233L45.832666346512745 82.94914892481695L41.449228963235676 84.97145296408942L42.91126407342174 88.50915105917478L41.734699322341356 93.21807801077841L41.684008323995386 93.82903793821029L37.58604129982375 95.00827063657204L35.23291179766261 91.47590843604915L30.521316898778096 95.00827063657204L26.097860306280257 94.39464276185893L25.81238994717458 90.86228056133592L21.999893282108616 89.68571581025542L23.74206285683788 85.30227842697798L18.747665546128808 81.15895629902333L17.005495971399547 83.79488821300879L4.898351208580099 82.94914892481695L4.994397310708947 80.6387065791577L5.229176671468899 76.45002934741994L1.4140120591215755 74.65983672162619L2.2624192945947668 69.66543941091712L0 66.55728082813053L3.4416519929565124 66.74403713782611V64.6737100474893L3.156181633850837 61.75497572167964H6.1229390107250925L8.47873646016747 64.6737100474893L10.831865962328486 61.4241502587906C10.831865962328486 61.4241502587906 41.449228963235676 61.75497572167964 42.30030414598998 60.85854543514196C43.1940664852463 59.96211514860453 42.91126407342174 56.42975294808153 42.91126407342174 56.42975294808153L41.12373939490948 54.97038578517682L35.23291179766261 0.1894242569765816L45.36043967771197 0L46.726428685769065 0.8030521316896972L79.69692118883727 25.81505789445582L86.76164558988305 31.98335200896428L93.82636999092881 34.95010938583842V39.66437223200453L99.76522063924004 38.623872792273396L100 56.14695053625721L96.18216744037132 62.035110186222475L76.0231577824023 63.68123365882276Z" id="Mali" className={this.props.getClass('Mali')} fill={`url(#regionImg${this.props.id('Mali')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

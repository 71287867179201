import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M76.27839536007743 4.27259545674235L77.47704204929865 17.747704204930308H81.73997100048275L86.00289995166686 23.383276945384765H96.75205413243106L100 26.118898018366767L97.43837602706623 26.28322861285677L96.07539874335457 33.44610923151334H90.27549540840967L87.53987433542677 45.3939101014983L71.84146930884508 38.39536007733218L66.89221846302578 56.143064282262046L54.78008699854994 37.544707588207004L42.65828902851629 53.243112614790036L22.861285645239153 54.61575640405993L19.28467858869021 36.86805219913007L0 46.08023199613391L6.486225229579187 24.069598840019044L22.861285645239153 14.847752537457856L20.64765587240187 2.735621072982003L34.80908651522456 4.949250845819285L46.244562590623026 2.0492991783472774L65.52924117931323 0L76.1043982600283 1.3726437892703418L76.27839536007743 4.27259545674235Z" id="Switzerland" className={this.props.getClass('Switzerland')} fill={`url(#regionImg${this.props.id('Switzerland')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

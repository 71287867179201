import React from 'react';
import ApiButton from '../elements/ApiButton.js';
import makeSnack from '../elements/Snack.js';
import autobind from 'auto-bind';
const {regionName} = require('../helpers/region.js');

export default makeSnack (class AddWatchlist extends React.Component
{
  constructor (props)
  {
    super (props);
    autobind(this);
    this.state = {
    };
  }

  onWatchlist (watchlistItem)
  {
    if (watchlistItem)
    {
      this.props.openSnackbar(`${regionName(this.props.region)} has been added to your watchlist.`);
    }
    else
    {
      this.props.openSnackbar(`${regionName(this.props.region)} has been removed from your watchlist.`);
    }
  }

  render ()
  {
    return (
      <ApiButton
        label={this.props.watchlistStatus ? 'Unwatch' : 'Watch'}
        fetch={{
          method: 'put',
          url: `/watchlist/${this.props.user.id}/toggleRegion/${this.props.region.id}`,
        }}
        onSuccess={this.onWatchlist}
      />
    );
  }
});


import React from 'react';
import ClipLoader from "react-spinners/ClipLoader";

export default class Spinner extends React.Component
{
  render ()
  {
    return (
      <ClipLoader color={'#FFFFFF'} loading={true} size={20} />
    );
/*
    let className = 'animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-white';
    if (this.props.tiny)
    {
      className = 'animate-spin rounded-full h-1 w-1 border-t-2 border-b-2 border-white';
    }
    return (this.props.disabled ? null :
      <div className="flex justify-center items-center">
        <div className={className} />
      </div>
    );
*/
  }
}


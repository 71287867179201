import React from 'react';
import config from '../config/config.js';
import makeSnack from '../elements/Snack.js';





export default makeSnack (class MyPattern extends React.Component
{
  render ()
  {
    let serverName = config().staticServer.path;
    let x = this.props.x, y = this.props.y;

    // get maximum viewport size respecting region's aspect ratio
    let maxViewportWidth, maxViewportHeight;
    if (this.props.suffix[0] === '_')
    {
      let regionAspectRatio = this.props.region.individual_width / this.props.region.individual_height;
      maxViewportWidth = 100;
      maxViewportHeight = 100;
      if (regionAspectRatio < 1) // portrait
      {
        maxViewportWidth = 100 * regionAspectRatio;
      }
      else  // landscape
      {
        maxViewportHeight = 100 / regionAspectRatio;
      }
    }
    else
    {
      maxViewportWidth = this.props.region.global_width_percentage;
      maxViewportHeight = this.props.region.global_height_percentage;
    }

    // adjust for image's aspect ratio
    let width = maxViewportWidth;
    let height = width / this.props.region.image_aspect_ratio;
    if (maxViewportHeight === 0) console.log('mvh is zero');// just to prevent unreferenced var warning

    // adjust translation
    if (this.props.suffix[0] !== '_')
    {
      x = x * this.props.region.global_width_percentage / 100;
      y = y * this.props.region.global_height_percentage / 100;
    }

    // do transform
    let scaleStr = `scale(${this.props.scale})`;
    let translateStr = `translate(${x}px,${y}px)`;
    let rotateStr = `rotate(${this.props.rotation}deg)`;


    let transformStr = `${scaleStr} ${translateStr} ${rotateStr}`;











    let imageStyle = {
      WebkitTransform: transformStr,
      MsTransform: transformStr,
      transform: transformStr,
      transformOrigin: '0 0',
    };
    if (this.props.notifyImage)
    {
      this.props.notifyImage('regionActualImg' + this.props.suffix);
    }
    return (

				<pattern id={'regionImg' + this.props.suffix} patternUnits="objectBoundingBox" x={0} y={0} width="1" height="1">
			    <image
            id={'regionActualImg' + this.props.suffix}
            style={imageStyle}
            href={`${serverName}${this.props.image}`}
            x={0}
            y={0}
            width={width}
            height={height}
            preserveAspectRatio="none"
            onLoad={() => {if (this.props.imageDidLoad) this.props.imageDidLoad('regionActualImg' + this.props.suffix)} }
          />
			  </pattern>















    );
  }
});


import React from 'react';
import Button from './Button.js';
import autobind from 'auto-bind';

export default class DialogButton extends React.Component
{
  constructor (props)
  {
    super (props);
    autobind(this);
    this.state = {
      open: props.startOpen ? true : false,
      onClose: this.props.dialog.props.onClose || this.props.onClose,
      onSuccess: this.props.dialog.props.onSuccess || this.props.onSuccess,
      unclonedDialog: null,
      dialog: React.cloneElement(this.props.dialog, {
        onClose: this.onClose.bind(this),
        onSuccess: this.onSuccess.bind(this),
      }),
    };
    if (props.receive)
    {
      props.receive({
        open: this.onClick,
        close: this.onClose,
      });
    }
  }

  componentDidUpdate (props)
  {
    if (props.dialog !== this.state.unclonedDialog)
    {
      this.setState({
        onClose: props.dialog.props.onClose || props.onClose,
        onSuccess: props.dialog.props.onSuccess || props.onSuccess,
        unclonedDialog: props.dialog,
        dialog: React.cloneElement(props.dialog, {
          onClose: this.onClose.bind(this),
          onSuccess: this.onSuccess.bind(this),
        }),
      });
    }
  }

  async onClick ()
  {
    this.setState({
      open: true,
    });
    if (this.props.onOpen)
    {
      this.props.onOpen();
    }
  }

  onSuccess (obj)
  {
    this.setState({
      open: false,
    });
    if (this.state.onSuccess)
    {
      this.state.onSuccess(obj);
    }
  }

  onClose ()
  {
    this.setState({
      open: false,
    });
    if (this.state.onClose)
    {
      this.state.onClose();
    }
  }

  maybeShowDialog ()
  {
    return (this.state.open ? this.state.dialog : null);
  }

  render ()
  {
    if (this.props.disabled) return (null);
    if (this.props.sideMenu)
    {
      return (
        <React.Fragment>
          <button onClick={this.onClick} className="block px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-transparent rounded-lg dark-mode:bg-gray-700 dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">{this.props.label}</button>
          { this.state.open && this.state.dialog }
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Button
          tiny={this.props.tiny}
          color={this.props.color}
          label={this.props.label}
          onClick={this.onClick}
        />
        { this.maybeShowDialog() }
      </React.Fragment>
    );
  }
}


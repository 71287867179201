import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path fillRule="evenodd" clipRule="evenodd" d="M72.42439774474474 41.158380317785834C90.0563813428994 33.008713480266316 100 19.425935417733392 82.8036904151716 9.943618657098247L81.90671450538106 8.124038954382678C58.38031778574989 0 31.676063557150687 1.7939518195799091 9.943618657098247 13.557150179394904C6.817016914402711 14.91542798564737 2.7421834956429514 17.63198359815584 1.819579702716748 21.681189133778762C0 28.498206048180293 8.149666837519518 33.9313172731937 14.941055868785389 36.18657098923553C33.495643259865076 43.41363403382884 54.305484366991315 49.30804715530417 72.42439774474474 41.158380317785834Z" id="Puerto Rico" className={this.props.getClass('Puerto Rico')} fill={`url(#regionImg${this.props.id('Puerto Rico')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M14.524712503058534 12.47553217518964L17.439442133594383 7.502446782481009C24.348544164423988 17.063249327134937 34.664790800097826 17.763640812331673 47.56850990946907 13.497063861022792C62.0932224125276 8.747247369708848 73.59310007340366 0 71.86811842427205 11.123684854416467C70.19207242476145 22.24431123073164 55.777465133349544 53.89038414484972 45.46427697577686 62.313432835821C37.093222412527325 69.11854661120628 17.81563494005355 85.58539270858839 4.480670418399544 100L0 95.51932958160046L0.3211402006364159 68.84328358208973C0.3211402006364159 68.84328358208973 3.345975042818612 65.98666503547834 6.85404942500629 62.95877171519442C10.686322485931258 59.61279667237581 15.114998776608925 56.046611206263805 16.898091509664717 55.66735992170306C20.247125030584765 55.02507952043065 29.373623684854355 54.318571079031045 29.373623684854355 54.318571079031045L49.62074871543912 33.04379740641054C49.62074871543912 33.04379740641054 40.49730853927097 32.07425984829955 37.472473697088205 32.07425984829955C34.39258624908241 32.07425984829955 21.923171030095364 24.944947394176687 19.598727673109906 24.29655003670183C17.22534866650344 23.64815267922669 12.796672375825773 15.54930266699298 12.796672375825773 15.54930266699298L14.524712503058534 12.47553217518964Z" id="Somalia" className={this.props.getClass('Somalia')} fill={`url(#regionImg${this.props.id('Somalia')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

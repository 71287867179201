import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M78.1706792777287 62.994411006018716C78.1706792777287 68.87360275150488 78.75107480653419 77.80524505589031 69.830180567497 75.33319002579555C60.9092863284598 73.05460017196945 54.46044711951885 71.72184006878774 54.46044711951885 71.72184006878774L53.880051590712355 90.12252794497019L38.1341358555456 100L33.78116938950833 58.824161650902866C33.78116938950833 58.824161650902866 24.290627687015352 37.37102321582116 21.829320722269813 33.78116938951031C20.303095442819934 31.309114359415553 7.394668959586328 15.176268271711468 0 5.6857265692175C1.7089423903693233 2.84823731728336 2.2785898538260945 0.7631126397249403 2.2785898538260945 0.7631126397249403L23.720980223559568 0C23.720980223559568 0 30.739466895958284 8.910146173688966 38.52106620808194 12.521496130696772C46.09845227858969 16.12209802235585 61.661650902837 16.12209802235585 61.661650902837 16.12209802235585L60.35038693035275 40.98237317282847L68.69088564058444 38.703783319002376L69.830180567497 55.79320722270006C69.830180567497 55.79320722270006 78.1706792777287 56.921754084264876 78.1706792777287 62.994411006018716Z" id="Moldova" className={this.props.getClass('Moldova')} fill={`url(#regionImg${this.props.id('Moldova')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

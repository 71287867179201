import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M50.59002022926469 7.754551584625546C66.35198921105857 8.041132838840602 82.13081591368858 9.828051247472363 97.62306136210354 12.204989885367265L99.99999999999999 88.99190829399825C67.85232636547605 90.49224544841573 35.721510451787466 89.59878624410062 3.573836817261972 86.02494942683865C0 75.59002022926468 11.59811193526644 65.79568442346692 22.60620364126741 64.27848954821252C33.63115306810452 62.795010114633506 44.92582602832132 66.08226567768043 55.95077545515842 63.70532703978474C66.67228590694589 61.31153068105293 76.78691840863098 48.81995954147061 70.81928523263642 39.58192852326372C61.90155091031685 39.90222521915104 52.66351989211073 39.90222521915104 44.35266351989198 36.32838840188829C36.294672960215244 32.75455158462632 29.753877275792906 24.12339851652025 30.950775455158425 15.492245448415733C32.147673634524715 6.557653405260028 43.74578556979116 0 50.59002022926469 5.664194200944147V7.754551584625546Z" id="Haiti" className={this.props.getClass('Haiti')} fill={`url(#regionImg${this.props.id('Haiti')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

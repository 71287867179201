import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M100 49.786543202453366V58.38494377968851L94.69063796524568 60.40526727196201L96.06758463111073 68.99765498166063L90.12687150502074 70.69929649449803L86.09825025554679 68.36630389032523L80.78888822079247 76.32734050868872L76.86248571943972 73.66965305754303L74.09656665263685 59.76790331309009H63.27340508688608L63.477842583127654 55.84150081173762L57.53712945703821 43.839817208826844L44.27274367145692 33.864469965726926L27.075942516986355 50.53213877698274L21.766580482232037 49.46786122301754C18.89844266730796 41.82550658408987 13.372617401238742 32.168841320426054 0 22.403944441104237L5.838494377968796 15.290722145391038L16.451205579941465 14.647345318982866L18.47152907221469 0L47.77223257771612 2.8621249473872723L47.55576934640149 8.069268234020669L74.84216222716623 9.975347243100469L78.76856472851925 4.034634117010473L90.02465275689981 13.372617401238465L86.09825025554679 23.251758763754534L94.69063796524568 25.903433347363432L95.33401479165414 33.864469965726926L92.0389633816365 45.216763874691615L100 49.786543202453366Z" id="Guinea" className={this.props.getClass('Guinea')} fill={`url(#regionImg${this.props.id('Guinea')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M52.79076161671703 5.340940335441323L52.41957657409968 11.286774814407528L56.9150398680234 15.046741820181111L56.186417376959724 30.334066538355266L60.68188067088281 31.791311520483887L61.5342315094856 71.96178168820451L69.05416552103341 86.03931811932891C57.64366235908771 88.95380808358551 45.14709925763011 93.44239758042373 31.921913665108825 100C31.921913665108825 100 22.20236458619771 98.30217211987929 8.8603244432225 97.33296673082194L8.372284850150914 84.94638438273307L0 71.96178168820451L7.643662359086918 62.13912565301048L16.015947209238465 49.27137750893585L11.410503161946643 31.069562826505084L7.767390706626033 29.24113280175943L8.372284850150914 7.519934011547803L31.186417376959326 0H53.155072862249185L52.79076161671703 5.340940335441323Z" id="Ghana" className={this.props.getClass('Ghana')} fill={`url(#regionImg${this.props.id('Ghana')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

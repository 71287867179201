import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M85.07917174177845 36.41900121802693L91.53471376370304 45.29537149817333L89.11388550548088 59.54628501827064L100 64.64677222898939L99.33008526187541 76.60627283800247L62.233556638245915 72.98264311814879L13.306942752740573 76.0657734470165C11.297198538367862 72.8532277710112 9.55389768574897 69.48842874543301 8.206455542021464 66.12362971985412L26.887941534713647 67.19701583434878L27.694884287454133 59.54628501827064L39.5173568818513 60.47503045067036L53.76827040194861 66.26065773447037L63.847442143727235 60.47503045067036L56.31851400730799 58.73172959805147L47.04628501826985 53.76065773447028H35.3532277710106L15.194884287454045 54.56760048721077L3.227771010961946 52.42082825822251C0.6851400730812475 43.54445797807541 0 36.83008526187602 0 35.2161997563947C0 32.79537149817324 11.023142509134662 23.112058465286353 16.945797807551617 5.511571254567857L53.76827040194861 0L57.93239951278896 11.6930572472596H67.2046285018271L85.07917174177845 36.41900121802693Z" id="Senegal" className={this.props.getClass('Senegal')} fill={`url(#regionImg${this.props.id('Senegal')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M61.79983457402826 0L90.59718775847834 17.230769230769234L78.03804797353175 17.462365591397884L85.34325889164613 85.39950372208433L87.55996691480566 87.20595533498751C87.55996691480566 87.20595533498751 87.91066997518641 91.58643507030608 86.80231596360642 92.69809760132331C85.74689826302753 93.80976013234054 47.778329197684144 93.39950372208436 47.778329197684144 93.39950372208436L44.860215053763575 97.43258891645972L41.93879239040545 93.80976013234054H38.25971877584794L38.61373035566593 97.43258891645972V99.99999999999999L34.34574028122437 99.76509511993365L26.577336641852998 89.0190239867658H22.547559966914886L20.737799834574155 83.93713813068635L4.734491315136708 86.33250620347387C6.484698097601541 81.19437551695592 7.186104218362433 74.41852770885014 4.674937965260657 66.00496277915622C1.9288668320926823 56.77750206782456 0.46650124069499876 51.2853598014886 0 47.430934656740945H29.49875930521112V34.636889991728616L38.61373035566593 30.610421836228205L36.8006617038875 10.514474772539046L61.97518610421841 10.1637717121586L61.79983457402826 0Z" id="Mauritania" className={this.props.getClass('Mauritania')} fill={`url(#regionImg${this.props.id('Mauritania')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M68.87810877288855 31.72998086908982L72.37633233123817 32.809510795299225C71.7750751571469 34.38097840940155 71.41295435911452 36.430718775622005 71.7750751571469 39.204700737906265C73.82481552336735 56.33369773162046 69.36321399289439 78.8876742279316 61.28040448209895 78.8876742279316C53.197594971303836 78.8876742279316 36.19158239956278 96.01667122164517 18.099207433724935 98.06641158786562C0 100 16.049467067504796 69.84148674501252 17.006012571740733 60.79529926209343C18.099207433724935 51.74911177917466 14.600983875375935 33.1647991254442 11.458048647170965 27.13856244875662C8.567914730800641 21.105493304181326 15.08608909538083 23.640338890407307 32.092101667122506 22.68379338617105C42.456955452309586 22.06887127630498 42.456955452309586 18.092374965837536 40.89232030609466 13.753757857338352C39.20470073790689 9.046187482918768 41.62339437004648 3.8603443563813724 46.4402842306638 2.5280131183382664C49.58321945886877 1.5646351462149297 53.79885214539512 0.3621207980320579 58.14430172178201 0L52.234216999180184 10.610822629133384L44.75266466247635 18.092374965837536L44.15140748838475 24.118611642525433L50.18447663295973 29.666575567094885L58.74555889587329 21.105493304181326L63.81525006832493 31.72998086908982H68.87810877288855Z" id="Ireland" className={this.props.getClass('Ireland')} fill={`url(#regionImg${this.props.id('Ireland')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

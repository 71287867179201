import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M100 18.438058572803094L85.92052798019945 24.267839956001254L66.98748796920056 30.331362573902982L67.71621064210056 44.658325312800166L60.92396535129883 45.38704798569954L54.13172006049709 62.14766946239464L48.06819744259536 61.405197305100934L34.222466657500284 53.63673862230129L32.050048123195225 61.405197305100934L5.56854117970593 61.88642925890241C6.063522617901731 56.317888079195846 6.805994775195433 51.6980613226998 3.1486319263029685 51.4505706036019L4.606077272101717 48.30193867729957L0 29.368898666300037L1.938677299602121 13.336999862505577C1.938677299602121 13.336999862505577 24.515330675099786 17.228103946101612 34.222466657500284 17.228103946101612C44.17709335899869 17.228103946101612 46.36326137769745 7.507218479306784 46.36326137769745 7.507218479306784L75.96590127870105 5.087309225903823L79.85700536229899 0C79.85700536229899 0 91.51656812869405 10.422109170905548 100 18.438058572803094Z" id="Slovenia" className={this.props.getClass('Slovenia')} fill={`url(#regionImg${this.props.id('Slovenia')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

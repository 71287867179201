import React from 'react';
import Form from '../elements/Form.js';
import makeSnack from '../elements/Snack.js';
import Button from '../elements/Button.js';
import {put} from '../helpers/fetch.js';
import autobind from 'auto-bind';
const {regionName} = require('../helpers/region.js');

export default makeSnack (class Upload extends React.Component
{
  constructor(props)
  {
    super(props);
    autobind(this);
    this.state = {};
  }

  async clearImage ()
  {
    await put (`/region/${this.props.region.id}/clearImage`);
    if (this.props.onClearImage)
    {
      this.props.onClearImage();
    }
    this.props.openSnackbar(<span>The image has been removed for {regionName(this.props.region)}</span>);
    this.props.didUpdate();
  }

  onSuccess (res, elements)
  {
    this.props.didUpdate();
    this.props.onClose();
  }

  render ()
  {
    let elements = [
      {
        label: 'Custom image',
        name: 'image',
        type: 'file',
        fetch: {
          method: 'put',
          url: `/region/${this.props.region.id}/image`,
        },
      },
    ];
    if (this.props.region.image)
    {
      elements.push({
        footer: true,
        type: 'custom',
        body: <Button full={true} color="red" label="Remove Image" confirm="Are you sure you want to remove the custom image?" onClick={this.clearImage} />,
      });
    }
    let fetch = {
      onSuccess: this.onSuccess,
    };
    return (this.props.disabled ? null :
      <Form
        onClose={this.props.onClose}
        title={`Upload Image for ${regionName(this.props.region)}`}
        elements={elements}
        submitLabel="Save"
        fetch={fetch}
      />
    );
  }
});


import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M96.52098453770839 35.37393499526688L100 39.41306405806236L83.14925844114751 50.41811296939075C83.14925844114751 50.41811296939075 79.66235405490549 65.59640265067769 79.66235405490549 69.07541811296895C79.66235405490549 72.57021142316137 66.29062795834608 77.15367623856062 61.139160618490735 78.97601767118933C55.97980435468571 80.64846954875306 44.84064373619352 79.53613127169392 44.84064373619352 79.53613127169392L34.53770905648501 65.74629220574266L30.2224676554119 65.59640265067769L29.378352792679266 56.54780687914107L33.28337014831095 51.814452508677206L30.640580624802286 43.452193120858205L19.359419375196985 44.43041969075391L8.488482171031666 37.04638687283061L2.082675923003709 36.35216156516222C1.5304512464498878 32.58914484064365 0.8362259387815005 26.877563900283718 0.2761123382765473 18.239192174187444C0.14200063111383973 16.0224045440203 0 13.931839697065458 0 11.983275481224457L3.6210160934040028 12.259387819501368L19.359419375196985 0L38.01672451877518 4.315241401073116L56.82391921741762 7.794256863363643L69.91953297570122 0L70.32975702114119 7.794256863363643L84.25370779425589 9.198485326601592L99.29788576838048 24.510886715051967L96.52098453770839 35.37393499526688Z" id="Lithuania" className={this.props.getClass('Lithuania')} fill={`url(#regionImg${this.props.id('Lithuania')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

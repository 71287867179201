import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M95.86431653355355 42.545671532176584L98.46231524832396 48.701000642614375C94.97383640870235 49.5960708712017 90.84274304599244 50.895070228587116 86.46378408152006 51.37703112090324C79.661250344257 52.02423574772809 73.58395299733724 63.531625814743606 73.58395299733724 65.55586156247107C73.58395299733724 67.5846874139355 69.53089139814534 74.3091893876801 62.80638942440073 74.3091893876801C56.07729734691998 74.3091893876801 59.40053245203285 76.98521986596897 58.10612319838398 83.06251721288916C56.729092077480544 89.13981455980893 43.18828605526489 87.84540530615963 31.033691361424516 80.3864867346003C31.033691361424516 80.3864867346003 28.362250986872205 88.49720003672104 28.362250986872205 90.5214357844485C28.362250986872205 92.54567153217637 17.584687413934855 100 17.584687413934855 100H6.724501973744196L8.10153309464764 87.84540530615963L0 77.06784173322313C0 62.07656293032214 0 46.598733131368895 0 46.598733131368895C0 46.598733131368895 5.43009272009533 54.0530615991921 8.753327825208627 45.94693840080791C12.154594693839526 37.845405306160266 10.130358946112072 4.778297989534766 10.130358946112072 4.778297989534766L33.06251721288895 0.7252363903424582L37.11098870834428 6.077297346920186L48.61837877536022 0L63.85752318002326 0.3213072615443625C63.210318553199244 1.216377490131686 62.48049205912023 2.0242357477278774 62.48049205912023 2.0242357477278774L60.45625631139236 9.152666850271014L65.47782979895347 18.231892040760556L70.9079225190488 21.63774901312801H73.9052602588816L76.33801523914411 29.092077480951218L81.03828146516086 27.715046360047772V36.87230331405492L84.76544569907226 39.86964105388772L90.11291655191343 40.195538419168635L95.86431653355355 42.545671532176584Z" id="Botswana" className={this.props.getClass('Botswana')} fill={`url(#regionImg${this.props.id('Botswana')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';
import Dialog from '../elements/Dialog.js';
import makeSnack from '../elements/Snack.js';
import Button from '../elements/Button.js';
import autobind from 'auto-bind';

export default makeSnack (class ConfirmDialog extends React.Component
{
  constructor (props)
  {
    super (props);
    autobind(this);
    this.state = {};
  }

  onConfirm ()
  {
    if (this.props.onConfirm)
    {
      this.props.onConfirm();
    }
  }

  onCancel ()
  {
    if (this.props.onCancel)
    {
      this.props.onCancel();
    }
  }

  render ()
  {
    return (this.props.disabled ? null :
      <Dialog onClose={this.props.onClose} title={this.props.label} description={this.props.description} >
        <Button full={true} label={this.props.yesBtnLabel ? this.props.yesBtnLabel : 'Yes'} onClick={this.onConfirm} />
        { (this.props.showNoBtn === false) ? null :
          <React.Fragment>
            <br />
            <Button full={true} label="No" onClick={this.onCancel} />
          </React.Fragment>
        }
      </Dialog>
    );
  }
});


import React from 'react';
import Dialog from '../elements/Dialog.js';
import moment from 'moment';
import makeSnack from '../elements/Snack.js';
import {get} from '../helpers/fetch.js';
import DialogButton from '../elements/DialogButton.js';
import Customize from '../region/Customize.js';
import Image from '../region/Image.js';
import Purchase from './Purchase.js';
import ViewOpenSea from './ViewOpenSea.js';
import AddWatchlist from '../watchlist/Add.js';
import Maps from '../maps/countries/all.js';
import MyPattern from '../maps/Pattern.js';
import autobind from 'auto-bind';
const {regionNameHeader} = require('../helpers/region.js');

export default makeSnack (class RegionInfo extends React.Component
{
  constructor (props)
  {
    super (props);
    autobind(this);
    this.state = {
      historyItems: null,
      region: props.region,
    };
  }

  async componentDidMount ()
  {
    await this.refresh(this.state.region);
  }

  async refresh (region)
  {
    if (region)
    {
      let historyItems = await get(`/transaction/history/${region.id}`);
      let regionInfo = await get(`/region/${region.id}`);
      let watchlistStatus = !this.props.user ? null : await get(`/watchlist/status/${this.props.user.id}/${region.id}`);
      this.setState({
        region: regionInfo,
        currentPrice: regionInfo.current_price,
        currentOwner: regionInfo.user.id ? regionInfo.user.username : ' None',
        historyItems,
        watchlistStatus,
      });
    }
  }

  renderTransactionHistory ()
  {
    if (!this.state.historyItems)
    {
      return (null);
    }
    else if (!this.state.historyItems.length)
    {
      return (
        <div>(none)<br /><br /></div>
      );
    }
    else
    {
      return (
        <div className="h-2/6 overflow-y-auto border">
          { this.state.historyItems.map(item => item.user.username === 'admin' ? null :
            <div>
              {moment(item.when_bought).format('MM.DD.YY')} - {item.price} MATIC by {item.user.username}
            </div>
          )}
          <br />
        </div>
      );
    }
  }

	getClass (name)
	{
    if (this.state.region.image && this.state.region.image !== '')
		{
			return ("region individual custom");
		}
		return ("region individual standard");
	}

  async refreshRegion ()
  {
    let region = await get (`/region/${this.state.region.id}`);
    this.setState({
      region,
    });
  }

  render ()
  {
    let {user} = this.props;
    if (this.props.disabled) return (null);
    let Map = Maps[this.state.region.original_name.split(' ').join('').split('-').join('')];
    return (
      <Dialog
        onClose={this.props.onClose}
        layout="idCardImageRight"
        title={regionNameHeader(this.state.region)}
        image={
          <Map
            over={() => null}
            click={() => null}
            rotation={0}
            scale={1}
            x={0}
            y={0}
            id={() => '_regionInfo'}
            getClass={this.getClass}
            defs={
              <defs>
                  <MyPattern
                    region={this.state.region}
                    suffix="_regionInfo"
                    image={this.state.region.image}
                    x={this.state.region.image_x}
                    y={this.state.region.image_y}
                    rotation={this.state.region.image_rotation}
                    scale={this.state.region.image_scale}
                  />
              </defs>
            }
          />
        }
        footer={
          <React.Fragment>
            <br />
            <ViewOpenSea
              region={this.state.region}
            />
            &nbsp;
            { user && user.type === 'admin' && this.state.region.id_owner !== user.id &&
              <Purchase
                region={this.state.region}
                user={user}
                onClose={this.props.onClose}
              />
            }
            &nbsp;
            { user &&
              <AddWatchlist
                region={this.state.region}
                user={user}
                onClose={this.props.onClose}
                watchlistStatus={this.state.watchlistStatus}
              />
            }
            &nbsp;
            { (!user || this.state.region.id_owner === user.id) ? null :
            <DialogButton
              label="Rename"
              dialog={ <Customize region={this.state.region} /> }
              onClose={() => this.refresh(this.state.region)}
            />
            }
            &nbsp;
            { (!user || this.state.region.id_owner !== user.id) ? null :
            <DialogButton
              label="Image"
              dialog={ <Image inc={this.props.inc} onClose={this.refreshRegion} region={this.state.region} getClass={this.getClass} /> }
              onClose={() => this.refresh(this.state.region)}
            />
            }
          </React.Fragment>
        }
      >
        Current Price: { this.state.currentPrice } MATIC <br />
        Current Owner: { this.state.currentOwner } <br />
        <br />
        Population: {this.state.region.population.toLocaleString('en-US')} <br />
        Size: {this.state.region.area.toLocaleString('en-US')} km<sup>2</sup> <br />
        <br />
        <b>Transaction History:</b><br />
        { this.renderTransactionHistory() }
        <br /><br /><br />
      </Dialog>
    );
  }
});


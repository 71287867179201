import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M95.54089578889945 56.9118595307771L94.31575814691713 77.0596696592432L89.6731312930904 75.91885323148678L90.46178264966997 86.07707950994526L78.7262536580528 94.83160557512029L69.27235752194845 100L46.67923218094323 97.80764843013719C46.67923218094323 97.80764843013719 62.70522295521053 73.73146173304877 55.43375824611871 70.04612866425295C48.07301225137641 66.37071573830671 26.184217052725554 53.23644660483087 26.184217052725554 53.23644660483087L17.429690987550536 48.85670353653096L4.2954218540746965 38.69847725807248L0 31.34765140618001H0.7043301423543602L10.158226278458713 2.8818014979420923L42.993899112147176 0L53.41996924755728 5.515599424631977C53.24636674768115 8.223798422697477 53.24636674768115 10.242547492683697 53.24636674768115 10.242547492683697L53.94573681861026 33.535042904618024H64.1932443827192H75.13516194633178L84.59401815386138 43.777590397301715L83.10599672635294 55.42383810326888L95.54089578889945 56.9118595307771Z" id="Paraguay" className={this.props.getClass('Paraguay')} fill={`url(#regionImg${this.props.id('Paraguay')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

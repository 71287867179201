import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M29.88910286207666 54.98373065940627L2.34411315492424 31.303539411647282C2.34411315492424 31.303539411647282 6.799920313433858 26.841091705956885 4.807756159107996 23.91261039909693C2.9284813068602586 20.984129092237286 0 17.584168935520356 0 17.584168935520356L33.17617371671424 0C33.17617371671424 0 38.56829802775825 0.4648383026761975 40.560462182084116 0.4648383026761975C41.27100073046043 0.4648383026761975 42.081147486553306 0.9363171525334792 42.90457533700835 1.646855700909791C44.43190118865832 2.815592004781522 45.83969719104878 4.449166611329145 45.83969719104878 4.449166611329145C45.83969719104878 4.449166611329145 59.08094827013795 4.920645461185814 60.960223122385685 7.384288465369876C62.95238727671155 9.72840162029381 66.82382628328575 19.57633308984683 69.28082874028874 22.97629324656376C71.74447174447188 26.37625340328069 74.20811474865502 15.120525931336601 78.54439205790574 14.649047081479932C83.00019921641535 14.184208778803733 82.07052261106296 20.984129092237286 82.07052261106296 20.984129092237286L98.59220399760954 25.44657679792829L100 36.23082542001479L98.12736569493366 38.10345972508144L93.31960953582566 39.63078557673141L88.39896407463954 41.51006042897946V49.359187197024916H79.12876020984115L71.87064214091217 54.399362507470556L71.2729928946146 54.75131150806832L62.01607012417838 55.222790357925604L56.15246696327769 50.2955043495584L50.28886380237762 46.90218474002225L43.37605418686503 44.4385417358391L39.97609403014871 53.23062620359913V58.15127166478494L34.69685902118343 59.094229364499505L29.88910286207666 54.98373065940627Z" id="Czech Republic" className={this.props.getClass('Czech Republic')} fill={`url(#regionImg${this.props.id('Czech Republic')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

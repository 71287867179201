import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M99.99999999999999 23.636101914495523L80.4375989635815 29.609903555491783L70.90830574348658 26.428674247876685L59.21980711098323 41.29840218799512L50.827695408090044 43.4576076004032L42.320426083201475 45.49445803944188L38.246725205124775 68.36044335684488C37.10954368792272 63.782927882539205 36.21707211746112 58.960702461494385 32.02101626601403 56.412840074852696C30.754282424068204 55.65711818050955 28.58787966028543 55.023751259536475 25.917662300273673 54.39038433856339L25.406650352670333 44.47963149560956L12.703325176335332 40.28357564416313L0 33.92831438030795L7.37008780768684 18.050957247733205L6.9814308334535635 10.8032244134161L21.980711098316092 6.103353965740685C23.247444940261918 7.37008780768684 25.16194040593086 8.010652080034276 27.954512739312023 8.010652080034276C39.90211602130454 8.010652080034276 70.90830574348658 0 82.84151432272938 6.3480639124804865C90.348351806535 10.292212465812757 97.07787534187408 13.595796746797259 99.99999999999999 23.636101914495523Z" id="Honduras" className={this.props.getClass('Honduras')} fill={`url(#regionImg${this.props.id('Honduras')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M88.68750000000098 83.31250000000026L82.75781250000091 100L79.31250000000094 98.75781249999994C80.26562499999972 89.10156249999969 76.68750000000018 75.86718750000026 45.656250000000156 87.03125000000007C37.92187499999996 89.93749999999996 29.921875000000618 93.2421875 21.92187500000056 96.96875000000017L20.687500000000497 62.89843750000033H12.000000000000806L15.44531250000149 39.59374999999979L0 37.10156250000039L1.656250000000557 30.210937500000103L20.687500000000497 24.148437500000455L0 14.625000000000487L6.476562500001228 6.7578125000007185L8.546875000000487 4.281250000000239L14.625000000000847 0L28.41406250000034 12.000000000000087L30.070312500000178 6.8984374999999245L36.96875000000118 23.312500000000178L80.96875000000078 30.210937500000103L68.9687500000007 44.00000000000032L57.65625000000024 48.2812500000002L58.609375000000455 67.17968750000021L68.13281250000006 63.72656250000025L70.60937499999982 56.000000000000405L77.51562499999974 56.83593749999996L80.96875000000078 73.24218749999986L88.68750000000098 83.31250000000026Z" id="Bangladesh" className={this.props.getClass('Bangladesh')} fill={`url(#regionImg${this.props.id('Bangladesh')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

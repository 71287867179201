import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M80.52901192000783 92.17811053098204L76.44074475421081 93.47847502709078L69.31336814106965 92.3505073391784L66.61412668702579 95.1334843857749L67.13131711161445 100L60.09260171411656 97.82780021672721L57.30962466752006 93.5671362427345L59.575411289528354 93.47847502709078L61.13683380947652 79.82464781794907L52.44310905329519 70.61373263717861V60.34873411486535L41.65599448330194 48.34991626440753L33.479460151708814 49.3941483597675L29.6571766328439 55.999408925228906L20.436410205891267 52.177125406364L10.61471776179688 56.52645059600044L12.786917545068988 34.26263422322912H4.610383213476769L3.048960693527694 27.130331986996637L0 23.21446162939645L8.437592355433228 10.255147276130408H8.092798739040939L12.259875874298142 6.521524972909226V12.520933898138246L19.919219781302154 14.087282041178414L18.26421042261916 8.693724756181787L16.092010639346146 0L23.135651659934673 2.172199783272787L23.224312875578406 1.3052901192000637L28.002167274160453 7.565757068269186L31.307260368436705 8.176534331593352L33.479460151708814 17.387449512363347L38.873017436705894 20.08669096640743L42.7002265786619 18.431681607723306L49.743867599251786 18.958723278494833L48.09378386365898 22.781006797359748L53.04403507043649 27.130331986996637L50.87183528716393 35.306866318589535L41.65599448330194 33.12974091222543L46.00531967293906 40.17338193281441L52.44310905329519 44.00059107477087L71.4855679243422 63.5651659934981L72.09141956457506 71.13092306176728L76.96286080189103 73.3917840606838L81.82937641611635 75.48024825140372L80.18421830361508 79.82464781794907L85.13446951039306 84.1739730075855L83.4843857748007 91.30627524381845L80.52901192000783 92.17811053098204Z" id="Laos" className={this.props.getClass('Laos')} fill={`url(#regionImg${this.props.id('Laos')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import config from '../config/config.js';

const doFetch = async (method, url, data, json = true) =>
{
  if (!url.includes('http'))
  {
    url = config().api.path + url;
  }
  if (data && method === 'get')
  {
    url += '?id_user=' + data.id_user;
    data = null;
  }
  return (new Promise ((resolve, reject) => {
    let options = {
      method,
      body: data ? (json ? JSON.stringify(data) : data) : null,
    };
    if (json)
    {
      options.headers = new Headers ({
        'Content-Type': 'application/json',
      });
    }
    fetch (url, options)
    .then(async res => {
      if (res.status >= 300)
      {
        let msg = await res.text();
        reject(msg);
      }
      else
      {
        return (res.json());
      }
    })
    .then (resolve);
  }));
};

const get = async (url, data) =>
{
  let res = await doFetch ('get', url, data);
  return (res);
};

const post = async (url, data) =>
{
  let res = await doFetch ('post', url, data);
  return (res);
};

const put = async (url, data) =>
{
  let res = await doFetch ('put', url, data);
  return (res);
};

const patch = async (url, data) =>
{
  let res = await doFetch ('patch', url, data);
  return (res);
};

const reqdelete = async (url, data) =>
{
  let res = await doFetch ('delete', url, data);
  return (res);
};

export {doFetch, get, post, put, patch, reqdelete};


import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
					<path id="Equatorial Guinea" d="M100 13.64640883978224L95.63535911602177 80.44198895027857L0 79.00552486188151C4.917127071820703 60.49723756906307 10.248618784531532 31.215469613261597 9.779005524859448 0L87.29281767955939 13.64640883978224H100Z" className={this.props.getClass('Equatorial Guinea')} fill={`url(#regionImg${this.props.id('Equatorial Guinea')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

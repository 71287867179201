import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M66.5375132088761 51.73652694610734L60.5776681930254 54.72349418809398L56.71715392743905 60.20429728777695H45.76963719619503L37.68228249383613 57.456851003874014L22.888340965128965 56.71715392743872L10.081014441704944 61.69778090877043L8.827051778795491 62.190912293060414L10.320535399788648 45.89644240929849L4.600211342021647 44.9031349066566V41.29623106727684L1.8668545262409595 41.04966537513185L2.486791123634725 39.67594223318022L7.213807678759509 25.748502994011492L13.434307854878748 18.414934836209483L9.207467418104903 15.801338499470972L0 10.200774920746797L6.22050017611859 9.580838323353355L7.213807678759509 3.3673828812960505L10.82071151813959 0.25361042620627494L11.19408242338869 0.38041563930941247L15.047551954913432 9.20746741810458L50.49665375132046 12.314195139132746C50.49665375132046 12.314195139132746 64.42409299048853 0.7537865445575395 69.15110954561462 0.38041563930941247C72.75801338499404 0 91.5463191264523 6.840436773511707 100 10.081014441704296C97.88657978161243 14.05424445227154 95.51954913701923 18.908066220499787 93.77949982388141 26.24867911236308C90.41916167664664 39.80274744628368 91.785840084536 43.66326171187036 95.15322296583174 48.88340965128513H83.45896442409212L77.23846424797482 46.51637900669193L66.5375132088761 51.73652694610734Z" id="Bulgaria" className={this.props.getClass('Bulgaria')} fill={`url(#regionImg${this.props.id('Bulgaria')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
					<path id="Kuwait" d="M93.15597511263371 97.36108131302049L60.97403990559694 96.61016949152338L34.09139669598954 67.79661016948991L0 65.17914610598372C10.212400772366623 48.487449045269614 29.543016520062206 17.807337481228043 34.09139669598954 9.482943574340858C39.755417292426856 0 75.37009225488097 15.53314739326438 100 25.78845741257172C73.48208538940054 44.325252091825526 77.62282771937329 68.56897661445836 93.15597511263371 97.36108131302049Z" className={this.props.getClass('Kuwait')} fill={`url(#regionImg${this.props.id('Kuwait')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';
import ApiButton from '../elements/ApiButton.js';
import makeSnack from '../elements/Snack.js';
import {get} from '../helpers/fetch.js';
import Button from '../elements/Button.js';
import autobind from 'auto-bind';
const {regionName} = require('../helpers/region.js');

export default makeSnack (class Purchase extends React.Component
{
  constructor (props)
  {
    super (props);
    autobind(this);
    this.state = {
      salePrice: null,
    };
  }

  onPurchase ()
  {
    this.props.openSnackbar(<span>You have just purchased <b>{regionName(this.props.region)}</b>!</span>);
  }

  async componentDidUpdate (props)
  {
    await this.refresh(props.region);
  }

  async componentDidMount ()
  {
    await this.refresh(this.props.region);
  }

  async refresh (region)
  {
    if (region)
    {
      if (!this.state.salePrice)
      {
        let salePrice = await get (`/transaction/price/${region.id}`);
        this.setState({
          salePrice,
        });
      }
    }
  }

  showVerifyMsg ()
  {
    this.props.openSnackbar(<span><b>You cannot purchase a country without verifying your account.</b></span>);
  }

  render ()
  {
    if (this.props.disabled) return (null);
    if (!this.props.user.verified)
    {
      return (
        <Button
          label="Purchase"
          onClick={this.showVerifyMsg}
        />
      );
    }
    return (
      <ApiButton
        label={!this.state.salePrice ? null : 'Purchase'}
        oldlabel={!this.state.salePrice ? null : `Purchase for ${this.state.salePrice.price} plus ${this.state.salePrice.fee} fee`}
        fetch={{
          method: 'put',
          url: `/region/${this.props.region.id}/purchase`,
          body: {
            id_buyer: this.props.user.id,
            expectedPrice: this.state.salePrice,
          },
        }}
        onSuccess={this.onPurchase}
      />
    );
  }
});


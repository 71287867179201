import React from 'react';
import Dialog from '../elements/Dialog.js';
import Fees from './Fees.js';
import Regions from './Regions.js';
import Users from './Users.js';
import makeSnack from '../elements/Snack.js';
import {put} from '../helpers/fetch.js';
import Button from '../elements/Button.js';
import autobind from 'auto-bind';

export default makeSnack (class Admin extends React.Component
{
  constructor (props)
  {
    super (props);
    autobind(this);
    this.state = {
      mode: 'idle',
    };
  }

  enter (mode)
  {
    this.setState({
      mode,
    });
  }

  reset ()
  {
    this.setState({
      mode: 'idle',
    });
  }

  async clearTransactions ()
  {
    await put ('/transaction/clear');
    this.props.openSnackbar(<span>All transactions have been cleared.</span>);
  }

  async resetTestRegions ()
  {
    await put ('/transaction/reset');
    this.props.openSnackbar(<span>All regions have been reset to test cases.</span>);
  }

  render ()
  {
    return (
      <React.Fragment>
        { this.props.disabled ? null :
          <Dialog onClose={this.props.onClose} title={"Admin Settings"} >
            <Button full={true} color="red" label="Configure Transaction Fees / Increments" onClick={() => this.enter('fees')} />
            <Button full={true} color="red" label="Manage Countries" onClick={() => this.enter('regions')} />
            <Button full={true} color="red" label="Manage Users" onClick={() => this.enter('users')} />
            <Button full={true} color="red" label="Clear All Transactions" confirm="Are you sure you want to clear all transactions?" onClick={this.clearTransactions} />
            <Button full={true} color="red" label="Reset Test Regions" confirm="Are you sure you want to reset regions?" onClick={this.resetTestRegions} />
          </Dialog>
        }

        { this.state.mode === 'fees' &&
          <Fees
            onClose={this.reset}
            onDone={this.reset}
          />
        }

        { this.state.mode === 'regions' &&
          <Regions
            inc={this.props.inc}
            onClose={this.reset}
          />
        }

        { this.state.mode === 'users' &&
          <Users
            onClose={this.reset}
          />
        }

      </React.Fragment>
    );
  }
});


import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M96.07589079129983 0.1573345673298574L100.00000000000001 8.162887552059216C100.00000000000001 8.162887552059216 84.80333179083702 21.564090698750356 83.66496992133234 27.616844053678868C82.6746876446087 33.81767700138842 89.87505784359114 60.28690421101421 89.87505784359114 60.28690421101421L82.19342896807031 80.55529847292959L75.49282739472473 81.70291531698352L73.04025913928659 76.14067561314307L63.405830633965344 81.70291531698352L38.24155483572376 58.334104581212515L21.406756131420074 64.3776029615922C21.73068024062903 61.60111059694639 21.5733456732996 59.48172142526687 21.406756131420074 58.334104581212515C20.425728829244818 52.124016658954574 4.081443776029608 52.124016658954574 1.962054604349662 36.91809347524362C0 21.5733456732996 5.071726052753254 8.172142526608031 17.325312355389613 5.229060620083537C29.578898658028532 2.1193891716795195 61.277186487737005 0 82.6746876446087 2.1193891716795195C87.91300323924148 2.6191577973163893 92.31837112448004 1.7954650624709896 96.07589079129983 0.1573345673298574Z" id="Estonia" className={this.props.getClass('Estonia')} fill={`url(#regionImg${this.props.id('Estonia')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';
import Dialog from '../elements/Dialog.js';
import {get} from '../helpers/fetch.js';
import makeSnack from '../elements/Snack.js';
import ordinal from '../helpers/ordinal.js';
import autobind from 'auto-bind';
const {regionName} = require('../helpers/region.js');

export default makeSnack (class Leaderboard extends React.Component
{
  constructor (props)
  {
    super (props);
    autobind(this);
    this.state = {
      users: null,
    };
  }

  async componentDidUpdate ()
  {
    await this.refresh();
  }

  async componentDidMount ()
  {
    await this.refresh();
  }

  async refresh ()
  {
    if (!this.state.users)
    {
      let users = await get ('/user/top');
      this.setState({
        users,
      });
    }
  }

  renderUsers ()
  {
    if (!this.state.users)
    {
      return (null);
    }
    else if (!this.state.users.length)
    {
      return (
        <div>There is not enough activity yet to display a leaderboard.</div>
      );
    }
    else
    {
      return (
        <div className="max-h-96 overflow-y-auto">
          <div className="visible block md:invisible md:hidden">
            <table>
                { this.state.users.map((user, i) =>
                  <tr className={'align-top' + (i % 2 === 1 ? '' : ' bg-cyan-50')}>
                    <td className="p-3 text-center">
                      <b>{ ordinal(i+1) }</b> <br />
                      { user.username } <br />
                      Total GDP: { user.gdp } MATIC <br />
                      { user.regions.map(regionName).join(', ') }
                    </td>
                  </tr>
                )}
            </table>
          </div>
          <div className="invisible hidden md:visible md:block">
            <table>
                { this.state.users.map((user, i) =>
                  <tr className={'align-top' + (i % 2 === 1 ? '' : ' bg-cyan-50')}>
                    <td className="p-3"> { ordinal(i+1) } </td>
                    <td className="p-3"> { user.username } </td>
                    <td className="p-3"> Total GDP: { user.gdp } MATIC </td>
                    <td className="p-3">
                      { user.regions.map(region =>
                          <div>{regionName(region)}</div>
                      )}
                    </td>
                  </tr>
                )}
            </table>
          </div>
        </div>
      );
    }
  }

  render ()
  {
    return (this.props.disabled ? null :
      <Dialog onClose={this.props.onClose} title={"World Leaders"} >
        { this.renderUsers() }
      </Dialog>
    );
  }
});


import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M100 85.5792600594106L91.89846070753379 86.41641911963191C88.80637321090984 81.88630839859485 82.48717256278634 73.18390494193838 80.93437753173066 71.63786119362611C79.03051579800164 69.61247637050955 59.12773426951068 52.80178233864347 59.12773426951068 52.80178233864347H30.2727518228457L21.928166351606272 46.367809883877705L15.379422090197618 56.73778017823306L0.2430461787738371 55.421280043207176L0 42.31028895490119L6.440723737509792 27.889549014312106L7.50742641101781 10.012152308938319C7.50742641101781 10.012152308938319 15.973534971644154 10.012152308938319 17.404806913312928 10.012152308938319C18.356737780178054 10.012152308938319 25.513097488522547 4.165541452875374 30.637321091007074 0C36.11936267890833 10.964083175802825 42.07399405887045 20.976235484741768 47.920604914933705 29.435592762624392C59.72184715095846 46.4825816905206 80.5765595463133 56.49473399945922 95.59141236834955 78.18660545503538C97.15095868214927 80.57655954631299 98.69700243046093 82.95976235484628 100 85.5792600594106Z" id="Eritrea" className={this.props.getClass('Eritrea')} fill={`url(#regionImg${this.props.id('Eritrea')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

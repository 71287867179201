import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M33.340693309782424 2.3515124751603493L33.53941267388035 1.7553543828655462C42.87922278648758 3.7204680945016366 64.51755354382878 0.20975932877054432 74.07816294987917 0.20975932877054432C83.62773239125593 0 94.74497681607446 3.9081474939289835 97.46080812541433 13.270037535879919C100 21.4506513579159 68.22698167365883 27.103113270036793 60.43276661514711 31.011260763965776C37.226760874365155 41.91874586001375 33.53941267388035 37.03908147493934 9.174210642526639 34.51092956502527C6.646058732612575 22.620887613159255 0 14.826672554647535 9.748288805476719 7.805255023183318C16.38330757341668 2.936630602782537 25.149039523074336 0.9715168911454297 33.340693309782424 2.3515124751603493Z" id="Falkland Islands" className={this.props.getClass('Falkland Islands')} fill={`url(#regionImg${this.props.id('Falkland Islands')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
					<path id="Madagascar" d="M48.574707652222294 12.379416234994999C50.60020472098986 21.63528645429453 49.9426160861067 23.33198920562066 47.86438785322132 23.33198920562066C45.8357889512702 23.33198920562066 45.8357889512702 27.438816340457446 45.8357889512702 34.994881975247296C45.8357889512702 42.49511461273605 36.91181488259537 63.03545395328623 32.14739911287572 81.54099072551875C27.382983343155498 100 11.61016160550881 100 6.132324203604348 95.89007103197966C0.6575886348834369 91.78324389714287 0 80.8306709265175 0 72.61701665684421C0 64.40336238717065 4.764415769719651 65.1136821861719 6.132324203604348 56.900027916498615C7.5033344706720255 48.68637364682505 6.132324203604348 43.86612487980402 7.5033344706720255 33.57114054406151C8.871242904556723 23.33198920562066 19.826917708365364 24.699897639504783 28.040571978038937 19.225162070783874C35.103446136666776 14.51347746518176 37.13204503861789 5.694965724743431 40.19665622382815 1.150780111045729C40.906976022829404 0.11166599460303642 42.49511461273634 0 43.31710040634006 1.042215949626245C44.90523899624671 2.9591488569746094 47.25953038245599 6.572784515648817 48.574707652222294 12.379416234994999Z" className={this.props.getClass('Madagascar')} fill={`url(#regionImg${this.props.id('Madagascar')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

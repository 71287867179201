import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M33.53146853146717 20.571095571096336L34.97668997668892 32.50582750582777L33.333333333331545 47.93706293706297L46.095571095570506 62.76223776223789L44.24242424242304 63.36829836829763L33.333333333331545 81.27039627039558L5.547785547784717 100C4.941724941724436 94.6503496503493 4.720279720278797 89.0909090909085 4.522144522144242 83.12354312354252C4.114219114217443 71.80652680652638 4.522144522144242 59.8717948717944 5.1398601398600645 49.18414918414962C5.967365967364912 34.3589743589747 3.904428904428419 23.86946386946394 0 16.04895104895102C2.470862470862218 11.52680652680678 4.720279720278797 6.993006993006997 5.1398601398600645 5.139860139860601C5.967365967364912 0.8275058275064567 20.372960372960172 0 20.372960372960172 0L29.00932400932293 6.794871794871905L33.333333333331545 18.72960372960441L33.53146853146717 20.571095571096336Z" id="Albania" className={this.props.getClass('Albania')} fill={`url(#regionImg${this.props.id('Albania')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

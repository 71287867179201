import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
					<path id="Lesotho" d="M58.22900763358761 18.061068702289564C76.27480916030537 35.862595419847146 80.85496183206006 60.65648854961755 69.54198473282453 69.54198473282453C58.22900763358761 78.70229007633671 25.068702290075596 100 14.015267175572061 90.83969465648782C2.687022900761923 81.93893129771044 0 67.38931297709838 0 67.38931297709838L7.267175572519419 42.0610687022894L29.114503816794507 22.381679389312268C29.114503816794507 22.381679389312268 40.42748091603143 0 58.22900763358761 18.061068702289564Z" className={this.props.getClass('Lesotho')} fill={`url(#regionImg${this.props.id('Lesotho')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M87.06193615544784 29.9444829979181L63.47154753643312 28.487161693269123L52.90596807772387 47.62751561415729L45.25069396252593 56.206627342123845L28.096807772380657 55.29146426093052L23.27376821651642 59.12126995142301C23.27376821651642 59.12126995142301 10.795454545454644 62.94673837612845 6.046148507980576 60.032095766828895C3.90353920888226 58.813324080499996 1.756592643996897 55.20905621096506 0 51.6134628730054C1.830326162387065 51.00190839694684 4.051006245662995 50.850104094379454 6.662040249826592 50.850104094379454C15.006939625260312 50.850104094379454 18.832408049964958 47.020298403886564 27.715128383067082 40.58379597501752C33.002255378209554 36.832061068702444 42.11051353226922 29.176786953504905 51.30117973629395 21.443442054129154L55.508327550312174 24.65735600277603L61.714954892436225 22.67088827203354L61.17713393476742 17.45749479528124L59.949687716863636 14.018043025677011C63.32408049965279 11.185808466343111 66.4642609299099 8.427307425399178 69.22276197085343 6.128556557946035C69.22276197085343 6.128556557946035 72.97015961138126 0 74.96530187369883 0.38601665510082045C80.39989590562125 1.457321304649779 78.79077029840428 9.346807772380357 91.57702984038866 15.08934767522577C100 18.914816099931016 93.41603053435102 24.583622484386062 87.06193615544784 29.9444829979181Z" id="Brunei_2" className={this.props.getClass('Brunei_2')} fill={`url(#regionImg${this.props.id('Brunei_2')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';
import Form from '../elements/Form.js';
import {get} from '../helpers/fetch.js';
import makeSnack from '../elements/Snack.js';
import autobind from 'auto-bind';

export default makeSnack (class Fees extends React.Component
{
  constructor (props)
  {
    super (props);
    autobind(this);
    this.state = {
      settings: null,
    };
  }

  async componentDidUpdate ()
  {
    await this.refresh();
  }

  async componentDidMount ()
  {
    await this.refresh();
  }

  async refresh ()
  {
    if (!this.state.settings)
    {
      let settings = await get ('/setting');
      let settingsObj = {};
      settings.forEach(setting => {
        settingsObj[setting.name] = setting.value;
      });
      this.setState({
        settings: settingsObj,
      });
    }
  }

  onDone ()
  {
    this.props.openSnackbar(<span>The settings have been saved.</span>);
    this.props.onDone();
  }

  render ()
  {
    if (!this.state.settings) return(null);
    let elements = [
      {
        label: 'Percentage fee to add to each transaction',
        name: 'transactionFeePercent',
        type: 'text',
        value: this.state.settings.transactionFeePercent,
      },
      {
        label: 'Flat fee to add to each transaction',
        name: 'transactionFeeFlat',
        type: 'text',
        value: this.state.settings.transactionFeeFlat,
      },
      {
        label: 'Percentage fee to add to each sale price',
        name: 'salesPriceIncrementPercentage',
        type: 'text',
        value: this.state.settings.salesPriceIncrementPercentage,
      },
      {
        label: 'Flat fee to add to each sale price',
        name: 'salesPriceIncrementFlat',
        type: 'text',
        value: this.state.settings.salesPriceIncrementFlat,
      },
    ];
    let fetch = {
      method: 'put',
      url: '/setting',
      errorMsg: '',
      onSuccess: this.onDone,
    };
    return (this.props.disabled ? null :
      <Form
        onClose={this.props.onClose}
        title={"Configure Transaction Fees / Increments"}
      	submitLabel="Save Changes"
      	elements={elements}
      	fetch={fetch}
      >
      </Form>
    );
  }
});


import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M14.467238211879426 91.90140845070495L5.541947336190264 95.95070422535247L0 100L1.6227801592160191 70.13931414574385L4.462645437843877 67.29944886711598V58.251684017146424L28.65125535823578 36.34415186772841L19.59583588487404 35.13472137170874V12.69902020820543L37.03306797305531 7.562767911818644L43.78444580526665 13.778322106551817L53.390998162890895 8.106246172687571L72.58113900796076 6.483466013471552L79.05695039804048 0L95.14696876913663 41.88609920391867L74.87752602571898 73.23943661971826V91.75597060624662H14.872933251683607L14.467238211879426 91.90140845070495Z" id="Uganda" className={this.props.getClass('Uganda')} fill={`url(#regionImg${this.props.id('Uganda')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

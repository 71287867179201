import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M15.4851625058878 34.508949599623C17.669571361280763 31.49434762129043 22.238577484691316 25.465143664625284 27.231512011304655 21.720442769665684C34.19689119170957 16.521431935939603 40.22609514837471 16.521431935939603 40.22609514837471 16.521431935939603L39.80805463966058 6.129298162977187L60.598210080075155 0C60.598210080075155 0 61.94653791803985 5.19312293923688 63.19477154969414 6.129298162977187C64.54309938765884 6.959491285916254 83.5668864813935 10.392133772962417 91.78638718794188 14.342910975035297C100 18.187706076306938 94.3829486575598 26.919453603391222 96.14931700423917 32.94865756005637C97.91568535091854 38.97786151672151 93.97079604333486 48.12764955251993 93.97079604333486 48.12764955251993L96.56735751295383 62.370466321243185L87.9415920866697 71.102213848328L89.18982571832291 83.57277437588324L70.99034385303811 98.12764955251993C66.6274140367403 96.98539802166688 58.625765426283046 95.3191238813001 54.98115873763494 95.3191238813001C53.21479039095611 95.3191238813001 51.136363636363846 95.73716439001369 48.75176636834626 96.25529910503985L45.41921808761214 88.35374470089464L38.041686292981204 85.33914272256261L31.182289213376993 84.92699010833714L26.40131888836559 81.0763071125763V69.33584550164863L20.372114931700445 71.102213848328L17.25153085256717 61.54027319830412H13.406735751295534L6.441356570890623 57.16556759302899L0 45.52520018841283L2.59656146961844 36.375412152613876C2.59656146961844 36.375412152613876 3.5327366933587467 35.33914272256261 4.362929816297271 34.19689119170957L15.4851625058878 34.508949599623Z" id="Zimbabwe" className={this.props.getClass('Zimbabwe')} fill={`url(#regionImg${this.props.id('Zimbabwe')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M43.50901209217434 61.63016198950504C47.93520419803766 66.05635409536836 42.19142140086693 84.8904859685146 42.19142140086693 84.8904859685146L35.24982888432549 86.20237280401574L33.43029888204412 100C33.43029888204412 100 22.7583846680356 82.97969427332835 20.841889117043095 81.77047684234512C18.936801277663633 80.56125941136189 23.96760209901883 69.18206707734461 23.96760209901883 69.18206707734461L22.7583846680356 62.83937942048827L13.28998402920352 62.23477070499692L5.030800821354668 57.80857859913307L0 51.56285649098787L6.947296372347173 45.31713438284266V37.057951174994336L13.28998402920352 35.249828884326014L8.259183207848325 28.901437371662883V16.31302760666238L13.900296600501656 10.067305498517172L8.863791923340203 1.4145562400185525L19.535706137348726 0C19.535706137348726 0 25.878393794205074 6.245722108145207 25.878393794205074 10.067305498517172C25.878393794205074 13.797627195984782 27.292950034222574 22.558749714807586 27.292950034222574 22.558749714807586L24.57221081451018 28.901437371662883C20.841889117043095 30.213324207164035 25.176819530002057 47.130960529318294 25.878393794205074 50.35363906000464C26.48870636550321 53.47364818617411 39.07141227469745 57.19826602783494 43.50901209217434 61.63016198950504Z" id="Malawi" className={this.props.getClass('Malawi')} fill={`url(#regionImg${this.props.id('Malawi')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M1.0634286145264553 31.163737838449357L6.124142092164011 22.91273851723348L0 19.443396937928974L0.25642959499263823 6.659627422882481V6.531412625386509C3.989742816200614 3.861528018704295 7.187570706690466 1.1991854589336077 7.187570706690466 1.1991854589336077C7.187570706690466 1.1991854589336077 14.239384568972739 0.1357568444074996 23.42559770721771 0L29.68549664378922 7.858812881816089L30.08522513010054 17.844482992684394L42.20529451693224 22.91273851723348L40.47062372728016 33.29059506750123L47.52997963647362 39.41473715966489L52.05520778339305 40.749679463006345L60.44196394901608 34.889509012745805L62.9760917112908 37.42363677502052L62.17663473866885 47.80903537220014L67.10159137189821 49.407949317444725L63.24006335319392 52.733992005430544L62.17663473866885 59.39361942831303L55.517007315786024 60.057319556527816L65.37446262915802 66.05324685119481L69.89969077607745 68.84380420846217L63.24006335319392 76.70261709027791L58.17934987555636 91.61324383437628L57.51564974734123 93.08394298212522L45.403122407422096 92.14872916509543L44.867637076702586 96.67395731201417L35.54566709404941 96.13847198129535L27.287125725922007 100L27.158910928425687 98.80081454106639L24.360811524247847 91.07775850365745L18.77215476280335 86.68828720114587L24.89629685496736 78.8370163662413L17.708726148276895 74.96794630062583L5.4604419639495685 64.45433290595024C5.4604419639495685 64.45433290595024 3.861528018704295 55.532091409608384 4.389471302511932 53.93317746436381C4.92495663323075 52.206048721622565 9.450184780149488 52.733992005430544 9.450184780149488 52.733992005430544L0.5279432838076374 43.419564069688214L3.861528018704295 40.62146466551037L7.187570706690466 36.22445131608726L1.0634286145264553 31.163737838449357Z" id="Serbia" className={this.props.getClass('Serbia')} fill={`url(#regionImg${this.props.id('Serbia')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

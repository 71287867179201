import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M48.896293211162124 6.122448979592124L54.41482715535201 4.5328335415800645L59.69040677495473 17.520477578786327L76.60002776620904 12.98070248507553L89.71261974177426 19.60294321810378L93.39164237123451 21.442454532833423L98.04248229904246 39.82368457587126L89.5876718034153 52.818270165208894L81.86866583368071 62.73774816048884L90.32347632930723 75.85728168818544L90.81632653061261 88.35901707621832C70.71359155907271 86.88740802443448 42.41288352075557 87.13036234902133 26.724975704567512 97.18172983479127C23.656809662640878 99.26419547410809 19.852839094822002 100.00000000000001 15.687907816187737 99.87505206164106L17.65236706927697 78.80049979175311L0 65.80591420241612L6.129390531722858 57.47605164514791L0.7427460780232935 50.485908649174185L2.332361516035034 50.11800638622774L9.19061502151908 48.15354713313883L7.600999583507019 38.2340691378592L13.730390115229877 35.901707621824166V25.975288074413484L4.539775093710797 20.69970845481013L8.343745661529994 7.594058031375966L19.852839094822002 9.19061502151876L32.11162015826708 0L38.2340691378592 9.19061502151876L48.896293211162124 6.122448979592124Z" id="Ivory Coast" className={this.props.getClass('Ivory Coast')} fill={`url(#regionImg${this.props.id('Ivory Coast')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M86.09306480428785 0L100 27.529641058957353C94.55497807373727 31.037843105408754 89.10183530940407 34.1237615721944 83.87201559200899 36.42195874614247C64.80428780250145 44.875751177521416 27.740782848790325 65.88435926587647 13.18824102647371 59.21309079096963C9.9602078934544 57.779762871528725 7.881273347409223 54.91310703264598 7.308754263439873 50.324833522819574C6.159655676465742 41.22137404580167 3.6543771317199365 33.33604027935683 0 25.592821179145655L6.378918304368826 23.444859509501548L7.743219100210985 13.980022738346705L40.429592333929044 16.704563910995407L43.154133506577566 19.42504466460943L57.917817118726404 5.522169887932515L86.09306480428785 0Z" id="Yemen" className={this.props.getClass('Yemen')} fill={`url(#regionImg${this.props.id('Yemen')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M97.84026079870277 81.29584352078096L39.52730236348993 100L0 69.76365118174121C28.076609616957803 46.0472697636495 55.41972290138639 22.290138549304924 81.25509372453747 0L92.78728606357159 32.355338223306894L97.84026079870277 81.29584352078096Z" id="Brunei" className={this.props.getClass('Brunei')} fill={`url(#regionImg${this.props.id('Brunei')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

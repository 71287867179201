import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M47.39202555753556 50.53756834797552L68.76574307304797 89.69711863365492L48.80506235792857 92.51704859617865L39.25784849788061 100L28.414326964428295 93.16827425201225L22.338268722737666 75.5974688210357L25.704982490631213 59.97419671929712L17.570805430976264 53.91042575413136L20.925231922344373 46.42133071204744L9.215457393868865 44.24648276709475C8.779259077225415 42.5139767770475 7.913006082201937 40.89205627572607 6.721140259261657 39.694046814523496C4.01179578546401 36.87411685199948 0 34.26921422866619 0 34.26921422866619C0 34.26921422866619 14.855317318916041 31.455427904405038 14.855317318916041 28.08871413651149C14.855317318916041 24.728144006881088 11.488603551022779 9.104871905141934 11.488603551022779 9.104871905141934L24.18136020151127 0C36.65294587454675 8.564231738034977 47.723782023714655 16.163912268845547 55.747373594642674 17.46636358051219C57.590465073416276 17.78583277016642 59.86975486883333 18.21588744854701 62.35792836517796 18.977698593106698L60.3059531854765 43.70584259998779L47.39202555753556 50.53756834797552Z" id="Guyana" className={this.props.getClass('Guyana')} fill={`url(#regionImg${this.props.id('Guyana')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

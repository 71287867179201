import React from 'react';
import Form from '../elements/Form.js';
import makeSnack from '../elements/Snack.js';
import autobind from 'auto-bind';

export default makeSnack (class Create extends React.Component
{
  constructor (props)
  {
    super(props);
    autobind(this);
    this.state = {};
  }

  render ()
  {
    let elements = [
      {
        label: 'Username',
        name: 'username',
        type: 'text',
      },
      {
        label: 'Password',
        name: 'password',
        type: 'password',
      },
      {
        label: 'Confirm password',
        name: 'password2',
        type: 'password',
      },
      {
        label: 'Email',
        name: 'email',
        type: 'text',
      },
      {
        label: 'User type',
        name: 'type',
        type: 'select',
        options: [
          { name:'admin', value:'admin' },
          { name:'player', value:'player' },
        ],
      },
    ];
    let fetch = {
      method: 'post',
      url: '/user',
      errorMsg: 'Your user could not be created due to a server error.  Please try again.',
      onSuccess: this.props.onSuccess,
    };
    return (this.props.disabled ? null :
      <Form
        onClose={this.props.onClose}
        title="Create User"
        elements={elements}
        submitLabel="Create"
        fetch={fetch}
      />
    );
  }
});


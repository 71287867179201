import React from 'react';
import Form from '../elements/Form.js';
import makeSnack from '../elements/Snack.js';
import autobind from 'auto-bind';
const {regionName} = require('../helpers/region.js');

export default makeSnack (class Customize extends React.Component
{
  constructor(props)
  {
    super(props);
    autobind(this);
    this.state = {};
  }

  render ()
  {
    let elements = [
      {
        label: 'Custom name',
        name: 'custom_name',
        type: 'text',
        value: this.props.region.custom_name,
      },
      {
        label: 'NFT guid (for OpenSea)',
        name: 'guid',
        type: 'text',
        value: this.props.region.guid,
      },
    ];
    let fetch = {
      method: 'put',
      url: `/region/${this.props.region.id}`,
      errorMsg: 'Your changes could not be saved due to a server error.  Please try again.',
      onSuccess: this.props.onClose,
    };
    return (this.props.disabled ? null :
      <Form
        onClose={this.props.onClose}
        title={`Rename ${regionName(this.props.region)}`}
        elements={elements}
        submitLabel="Save"
        fetch={fetch}
      />
    );
  }
});


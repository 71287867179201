import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M100 91.34306832522111L3.1837029024696117 90.57869118442402V22.469803497386195L1.8460429060753745 21.132143500991624L0 16.61438615467837L2.350820263205087 12.605011718045755L2.1020371371909916 7.063277447269038L7.193077339102226 0C24.250946457544394 6.7495943753380985 36.47376960519194 10.311880295655493 40.93023255813944 8.53073733549663C46.91184424012969 6.107806021272846 58.81377321074461 1.3340544438436426 69.57274202271466 4.903551469262724C73.58211645934728 6.237605913106367 76.63962502253462 5.667928610059644 78.93275644492489 4.009374436632615C80.39661078060239 10.311880295655493 82.30394808004334 19.030106363800467 82.43374797187636 21.38813773210774C82.49504236524261 21.896520641788545 82.68974220299265 22.3436091581036 83.00703082747418 22.722192175951218L81.9253650621959 24.824229313142208L82.24265368667709 36.92085812150732C76.44853073733535 37.17324680007218 73.64701640526395 36.03028664142781 71.99206778438818 32.399495222642976C70.01983053903021 28.007932215612318 60.02523886785659 16.866774833243394 65.18117901568417 29.219397872724127C70.40201910942842 41.56841535965393 73.96791058229658 44.74851270957295 80.33171083468537 55.442581575626484C85.42275103659628 63.973318911123286 94.46187128177388 77.8510906796466 100 91.34306832522111Z" id="Egypt" className={this.props.getClass('Egypt')} fill={`url(#regionImg${this.props.id('Egypt')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
					<path id="Suriname" d="M81.9042654851873 22.395969073061035L68.10007818608184 45.556424289809904L79.60211971157948 61.81044218573489L69.32499348449221 90.65241942489729H45.09599513508778L42.177048041003815 98.32334288940982L30.22326470332677 99.99999999999999L0 44.635565980366856L18.260794023107813 34.97524107375533L21.16236643210796 0C36.81695769264143 3.8398054035270177 59.82104074363549 12.118842845972969 81.9042654851873 22.395969073061035Z" className={this.props.getClass('Suriname')} fill={`url(#regionImg${this.props.id('Suriname')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M53.575650118202546 76.87155240346732C53.575650118202546 100 23.128447596532222 88.51457840819518 11.485421591804826 75.47281323877009C0 62.43104806934592 8.697793538219443 30.427501970054887 13.041765169424172 5.910165484634062C13.041765169424172 5.910165484634062 17.39558707643798 2.9550827423165775 24.527186761228997 1.5563435776202545C31.826241134751665 0 33.21513002363936 15.996847911741657 40.53388494877837 26.0835303388488C47.83293932230104 36.347517730496214 53.575650118202546 53.7431048069351 53.575650118202546 76.87155240346732Z" id="Sri Lanka" className={this.props.getClass('Sri Lanka')} fill={`url(#regionImg${this.props.id('Sri Lanka')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
					<path id="Kenya" d="M20.352109505155916 4.214507632041099L32.74798505110558 5.326669368274394L41.01490386780125 14.944391913188468H56.28348867576194L69.87257418163775 7.312350848755466L75.5189337655905 11.92309424107372C75.5189337655905 11.92309424107372 77.97739655094814 12.24278445675167 80.60245846278573 12.557971993335977C75.43788554189744 17.015624296456448 70.98473591787166 21.221126570309636 70.98473591787166 21.221126570309636L70.51195461299488 60.493493628709395L77.1083794857938 67.08991850150831C65.18528524472092 79.73344139763181 56.43657976496035 91.33684542302674 56.12139222837583 98.25296051150444C56.12139222837583 98.81129271916812 56.04034400468278 99.36512224773712 56.12139222837583 99.99999999999999L38.23675086676608 87.28443423837197V82.35850330946941L0 61.44806159665023V50.55608086811654L11.92309424107372 32.1131072988427L2.458462785357636 7.474447296141575L9.8563645368998 0L15.49822144175788 0.3196902156785729L17.16871538565506 3.9758656400557877L20.352109505155916 4.214507632041099Z" className={this.props.getClass('Kenya')} fill={`url(#regionImg${this.props.id('Kenya')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

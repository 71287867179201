import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
					<path id="Luxembourg" d="M52.059925093630774 0.8895131086159086L82.63108614232284 48.73595505617938L70.22471910112024 100L65.30898876404208 96.72284644194718L4.915730337078158 94.24157303370882L0 24.85955056179945L52.85580524344523 0L52.059925093630774 0.8895131086159086Z" className={this.props.getClass('Luxembourg')} fill={`url(#regionImg${this.props.id('Luxembourg')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

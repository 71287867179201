import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M100 47.34657776568196L97.7956184514893 51.755340862702084L88.68553544699911 46.448496394066936L78.28275955912349 44.14206014423671L75.27554769356328 49.44890461287248L75.37760239488345 49.55095931419233L60.96067492175779 49.945570825962115L54.75574908150763 52.25200707579235L42.937814668662135 43.44128452850674H21.32262892910579C25.826643080691117 35.930058511361835 29.92924207375138 22.227513947475774 14.219621717240297 12.212545924615592C9.307388760375558 9.205334059055392 4.599265206150524 5.606204925840363 0 2.102326847190527L5.408899169955627 0L25.73139202612592 6.701592053340739L38.44060416383178 6.007620084365742L56.45666077017246 17.315280990611036L60.96067492175779 21.724044087630855L69.96870322492907 18.716832222071282L80.47353381412424 17.315280990611036L84.28357599673394 20.118383453530903L90.29119608109937 32.133623622261446L95.08776704313401 33.8345353109266L95.4891822016597 42.74731255953174L100 47.34657776568196Z" id="Georgia" className={this.props.getClass('Georgia')} fill={`url(#regionImg${this.props.id('Georgia')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

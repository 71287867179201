import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
					<path id="Belize" d="M29.987413467589757 0L53.61862806796789 4.452485840150857C50.28319697923243 14.159848961610107 48.04908747640092 23.33228445563317 47.2152297042176 31.104468219005874C44.71365638766545 59.15670232850836 39.159848961611374 80.82127123977416 48.04908747640092 89.72624292007515L15.261170547514782 100L13.876651982379483 78.6028949024541L0 23.049087476399652L29.987413467589757 0Z" className={this.props.getClass('Belize')} fill={`url(#regionImg${this.props.id('Belize')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M67.75134305448907 14.090560245586707C100 64.2210283960091 71.00537221795909 56.36224098234824 63.4075211051423 75.33384497313857L55.548733691480734 72.90867229470405H44.17498081350791L32.78587874136637 49.040675364542814L13.553338449731273 68.27321565617791L0 55.80966999232551L1.3507290867236448 42.27168073676083L18.158096699923526 25.46431312356024L31.435149654643432 1.6270145817343034C45.525709900230844 0 59.89255564082892 1.887950882577664 67.75134305448907 14.090560245586707Z" id="Northern Ireland" className={this.props.getClass('Northern Ireland')} fill={`url(#regionImg${this.props.id('Northern Ireland')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

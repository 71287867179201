import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
					<path id="Ecuador" d="M85.15427242252251 27.892180337962326C87.9250188137113 33.21474994868994 87.32298009167499 41.06861873161363 89.25908189095007 43.723062187863086C92.27611685024374 47.7047273722379 70.17855921187696 66.79209140042379 67.15468290346925 66.79209140042379C64.13764794417527 66.79209140042379 52.06266675788532 74.87856605322615 45.0571252651028 79.95484709584701C37.928439488267216 84.90798385441637 32.975302729698484 100 32.975302729698484 100L11.842375316412541 96.01833481562582L12.8001641923787 79.95484709584701L6.759252924677013 64.85598960114966L0 61.955257576794075C0.7251830060887389 46.740097147157066 5.555175480605038 15.46144899774188 19.805705685161215 6.3966614216318565C22.46699049052471 4.706848190462603 25.24457823082723 2.5381405213104284 27.90586303619104 0C36.71752069508152 5.192583977559881 52.295272627762735 14.975713210644601 53.02729698296583 18.47848395703586C54.115071492098785 23.554764999657344 65.1022781692558 21.502360265444196 77.1841007046598 22.58329342546342C81.40521310802575 22.93904357939359 83.70390641034503 25.005131011835452 85.15427242252251 27.892180337962326Z" className={this.props.getClass('Ecuador')} fill={`url(#regionImg${this.props.id('Ecuador')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

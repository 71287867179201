import React from 'react';

export const regionName = (region) =>
{
  if (region.custom_name === undefined)
  {
    alert ('custom name undefined in regionName');
  }
  if (region.original_name === undefined)
  {
    alert ('original name undefined in regionn');
  }
  if (region.custom_name && region.custom_name !== '')
  {
    let str = region.custom_name;
    if (str !== region.original_name)
    {
      str += ` (${region.original_name})`;
    }
    return (str);
  }
  else
  {
    return (region.original_name);
  }
};

export const regionNameHeader = (region) =>
{
  if (region.custom_name === undefined)
  {
    alert ('custom name undefined in regionName');
  }
  if (region.original_name === undefined)
  {
    alert ('original name undefined in regionn');
  }
  if (region.custom_name && region.custom_name !== '' && region.custom_name !== region.original_name)
  {
    return (
      <React.Fragment>
        <div className="text-4xl">
          {region.custom_name}
        </div>
        <div className="text-2xl">
          {region.original_name}
        </div>
      </React.Fragment>
    );
  }
  else
  {
    return (
      <div className="text-4xl">
        {region.original_name}
      </div>
    );
  }
};


import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M99.99999999999999 34.32231962238721L97.08923353562598 36.3714692440248V38.02352588596697L73.94170974750871 39.21855098524009V47.08548737544008L78.10743987412913 48.7375440173819L77.71409305461898 54.16198396643451L71.88881396568497 53.70495242376565L60.31692515171942 64.87600209784945L47.089233535626235 64.0818161384579L33.86528807971835 71.88881396568497L33.99265752603598 81.41529931819856H0C8.728553232936417 75.13298868659604 25.398966059788975 65.6065033340826 25.990859369146914 59.5227391923277C26.852476211882994 51.652056641941904 25.196673409755025 30.553682475462576 47.089233535626235 21.098374166479495C59.58642391548643 15.606503334082698 54.09829924327561 0.7941859593915452 58.26777553008183 0.39334681951013756C62.36982093354316 0 68.18760770210521 7.866936390199991 81.41529931819856 7.406158687345663C83.26964861017434 7.342473964186589 85.18768262530901 7.076496590994253 87.16940136360206 6.611972727953945C90.61212257436145 9.058215329287654 93.78137409155636 12.366074773357443 93.78137409155636 12.366074773357443L92.98718813216446 28.103693713943063L99.99999999999999 34.32231962238721Z" id="Morocco" className={this.props.getClass('Morocco')} fill={`url(#regionImg${this.props.id('Morocco')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

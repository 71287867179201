import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M100 6.473466315393345L98.67331576966444 22.92058712834025L85.22770041400076 20.75649228453172L84.22092585622894 33.38351524275511L92.52916823485096 41.691757621377555H80.07150922092599L73.75799774181365 63.45502446368023L74.42604441098949 78.23673315769689C68.451260820474 73.91795257809552 62.467068121941516 70.58712834023373 62.467068121941516 70.58712834023373L17.78321415129859 65.44975536319164L0 37.37297704177618L1.4960481746326872 34.7101994730898C8.148287542340146 38.200978547233895 15.619119307489193 40.02634550244644 23.927361686112075 39.52766277756903C58.14828754234058 37.37297704177618 84.22092585622894 5.3161460293566405 90.36507339104156 2.1546857357919746C94.69326307866035 0 97.01731275875075 0.8280015054572782 100 6.473466315393345Z" id="United Arab Emirates" className={this.props.getClass('United Arab Emirates')} fill={`url(#regionImg${this.props.id('United Arab Emirates')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

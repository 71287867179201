const filterUnderscore = (str) =>
{
  let res = '';
  for (let i=0; i<str.length; i++)
  {
    res += (str[i] === '_') ? ' ' : str[i];
  }
  return (res);
};

const withCommas = (num) =>
{
  num = Number(num);
  return (num.toLocaleString());
};

const asCurrency = (num) =>
{
  num = Number(num);
  let whole = Math.floor(num);
  let partial = num - whole;
  let partialStr = partial.toFixed(2);
  partialStr = partialStr.substr(partialStr.indexOf('.'));
  return (withCommas(partial) + partialStr);
}

export {filterUnderscore, withCommas, asCurrency};


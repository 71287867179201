import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M38.43430369787617 6.477838971938852L35.40519276160654 58.33988985051085L14.817728822450006 100C13.663781799109424 98.62313139260378 12.955677943877705 96.99711513244145 12.732756359821462 95.14817728822469C12.260687123001523 86.57225281930236 5.323891948598875 54.864935746131756 0 31.94335169158178C10.411749278783553 24.77052189876735 16.666666666667975 11.81484395489085 20.600576973512073 0L38.43430369787617 6.477838971938852Z" id="Israel" className={this.props.getClass('Israel')} fill={`url(#regionImg${this.props.id('Israel')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

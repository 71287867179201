import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M95.96370728398259 0L100 11.515305689816168L97.3119647248378 35.9196133299413L89.82447214449239 44.305944204188606L77.54600186551434 45.19630289154583L76.65564317815672 53.59111337233978L67.37047401000595 54.642584584074605L69.15967099126547 68.5576189264816L51.4966505554148 69.6090901382176L49.55482065632119 77.98694140591904L32.638005596539905 81.72644789281763C23.64962265750911 64.96226575086928 13.93199355549988 52.38700924277085 4.3415585516832556 43.85652505723726L0 25.591452556600768L16.764182141947977 4.036292716017801L55.99084202492981 2.382769439497532L60.7818197235648 9.71762910200923L72.89917747816524 13.46561519545486L72.0088187908084 5.087763927753413L76.65564317815672 0.2883066225725606L88.92563385058892 2.2386161282112518L95.96370728398259 0Z" id="Cambodia" className={this.props.getClass('Cambodia')} fill={`url(#regionImg${this.props.id('Cambodia')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M91.72613737734282 26.38269402319338L88.18019625334719 47.65834076717337L48.818019625335126 100L14.942016057092426 97.65834076717233L0 17.350579839428388L35.81623550401642 19.692239072256044L48.818019625335126 0L68.88938447814508 2.3639607493297405L70.07136485281097 26.38269402319338H91.72613737734282Z" id="Burundi" className={this.props.getClass('Burundi')} fill={`url(#regionImg${this.props.id('Burundi')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

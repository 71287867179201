import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M80.78758949881174 19.212410501188256C80.78758949881174 71.83770883055341 57.8758949880625 100 3.4606205250522697 82.57756563245681L0 21.002386634844317L54.41527446301023 0L80.78758949881174 19.212410501188256Z" id="Singapore" className={this.props.getClass('Singapore')} fill={`url(#regionImg${this.props.id('Singapore')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

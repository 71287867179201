import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M94.02008876430834 34.220976407382075L100 45.56178462976008L96.07568325157769 55.66456435412317L82.88951179631005 61.86638635832836L84.11586078019222 79.18710581639857L79.38565755664607 78.97687456201939L65.77902359261901 72.99696332632666L58.77131511329214 50.712450362065645L51.96215837421224 61.86638635832836L44.33543564587683 61.03714085494119L42.46671338472386 44.53398738612541L24.526979677645656 43.7047418827377L20.193879934594513 26.594253679047206L7.206260219573834 23.91964494277094L0 13.197850969399852C7.206260219573834 10.102779724363629 14.832982947909239 6.598925484699657 20.415790703106968 2.4760569960287624L32.153702405979985 9.483765475356707L42.46671338472386 4.321420228918935L45.153001635132334 0L68.25508058864777 3.2936229852837253L79.38565755664607 5.979911235692735L85.56412053258684 12.777388460640433L82.88951179631005 25.7650081756595L94.02008876430834 34.022424667134565V34.220976407382075Z" id="Belgium" className={this.props.getClass('Belgium')} fill={`url(#regionImg${this.props.id('Belgium')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
					<path id="Guinea Bissau" d="M100 0L94.77205539131846 37.90259841294634L67.30978683678151 39.567449821067456L52.20164929204837 57.958612105182475C49.46320211607202 55.76474249260951 46.42912711996201 53.83538198226283 43.13054302162716 51.6415123696913C24.19480317410875 39.007312898708946 10.175820756184537 23.074529329392455 0 6.301540376537489L100 0Z" className={this.props.getClass('Guinea Bissau')} fill={`url(#regionImg${this.props.id('Guinea Bissau')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M100 35.30187331758507V58.776025929791835L72.74625061803049 56.35334834917327L70.90589463275356 50.91468439268293L62.36334670109365 51.5024995879801L53.93616436851101 42.38312366093512L47.23946602208485 44.80580124155368L32.69241333846137 41.80629566554979L0 24.24875020601008L1.1646431906827446 13.964731088282358L8.438169532494737 5.427676756578752L15.13486787892191 0.19227599846194332L19.98571664011483 0L29.681920562544878 6.592319947261497L44.816788441465775 19.97472944020223L52.66714277866309 17.55205185958341L57.51799153985703 22.402900620777093L76.9213865846287 29.67642696258858L92.1496456628032 38.21348129429218L99.31879360545001 35.109597319123125L100 35.30187331758507Z" id="Nepal" className={this.props.getClass('Nepal')} fill={`url(#regionImg${this.props.id('Nepal')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

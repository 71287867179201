import React from 'react';
import Form from '../elements/Form.js';
import makeSnack from '../elements/Snack.js';
import ConfirmDialog from '../elements/ConfirmDialog.js';
import autobind from 'auto-bind';

export default makeSnack (class Forgot extends React.Component
{
  constructor (props)
  {
    super(props);
    autobind(this);
    this.state = {};
  }

  render ()
  {
    let elements = [
      {
        label: 'Email',
        name: 'email',
        type: 'text',
      },
    ];
    let fetch = {
      method: 'put',
      url: '/user/forgot',
      errorMsg: 'Your username and/or password were incorrect.  Please try again.',
      onSuccess: this.props.onSuccess,
    };
    return (this.props.disabled ? null :
      <Form
        onClose={this.props.onClose}
        title="Forgot Username/Password"
        description="Please enter your email address below to continue with resetting your password."
        elements={elements}
        submitLabel="Continue"
        fetch={fetch}
        confirmDialog={
          <ConfirmDialog
            label="Email sent!"
            description={
              <React.Fragment>
                If an account was found matching the email address you entered, <br />
                an email was sent.   Check your inbox and click on the link to <br />
                reset your password.
              </React.Fragment>
            }
            showNoBtn={false}
            yesBtnLabel="Ok"
          />
        }
      />
    );
  }
});


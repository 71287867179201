import React from 'react';
import makeSnack from '../elements/Snack.js';
import Button from '../elements/Button.js';
import autobind from 'auto-bind';

export default makeSnack (class ViewOpenSea extends React.Component
{
  constructor (props)
  {
    super (props);
    autobind(this);
    this.state = {
    };
  }

  goOpenSea ()
  {
    let url = 'https://opensea.io/assets/matic/0x5c162716c49d78128344a0723db90e90a63ce4f0/' + this.props.region.guid;
    window.open(url, '_blank');
  }

  render ()
  {
    if (this.props.disabled || !this.props.region || !this.props.region.guid) return (null);
    return (
      <Button
        label="View on OpenSea"
        onClick={this.goOpenSea}
      />
    );
  }
});


import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M67.21158544711514 21.962596064146442C77.95744302785327 23.450757407489725 88.778819243925 27.84416507485227 70.65878903647085 49.88227977433251C56.54568877437708 66.97614499577975 35.76029496690535 86.74425836257817 15.214783883434682 100L0 69.8813913197992L1.9590422460134542 69.49047132512995L26.511483274843684 62.19181733374793C26.511483274843684 62.19181733374793 40.23366354226839 43.52982986095712 38.74105992625841 39.60730309626393C38.114699480253954 37.79929812091848 35.60925769623735 35.68477633157114 33.174892274887846 33.881213628892596L32.85949091555238 26.90240326951234L35.84025587490584 16.62742659144417H41.72182488561147L37.799298120918884 12.704899826751385L38.27462129625534 6.747812180711759L44.622628936964034 7.765092621385078L45.24898938296849 0C45.95531073697344 1.2571631646749954 46.66607436364447 2.9807649593530576 47.6078361689844 5.335169472702067C50.504197947670065 12.704899826751385 56.39020923104259 20.54551108347026 67.21158544711514 21.962596064146442Z" id="Oman" className={this.props.getClass('Oman')} fill={`url(#regionImg${this.props.id('Oman')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M47.63112929115075 57.68135452803772L48.17480453580456 69.83896857039282L33.003676280225854 79.80117019624065L33.366126443328234 91.59115621601977C33.366126443328234 91.59115621601977 27.79474965049449 96.80526070522426 24.41360741469442 100L18.83705276238755 72.57805623155392C18.83705276238755 72.57805623155392 10.335007507895986 68.55485942111534 6.865841661057484 66.27142339356891C3.4795215657847622 63.98280950654959 0 49.08610780303388 0 46.25381867136123C0 43.41635168021521 7.497540516750204 38.849479625122825 9.692952933257484 35.37513591881119C12.069590431315715 31.99399368301136 9.149277688603673 22.84989385388082 9.149277688603673 22.84989385388082L9.692952933257484 9.138921969657892L15.45073266711483 0.8232796561901702C21.762543364573197 0.26924869259033996 26.422616890177142 0 28.618029306684424 0C30.8134417231917 0 32.91047480971381 0.18122508155095193 34.74343706311776 0.999326878268231C39.13426189613184 2.832289131672891 42.1477761093564 7.854812820379932 41.68694661626837 21.110133070988436C41.143271371615036 39.947185833376224 36.01719049344993 51.91839693470701 46.25899653083388 57.12732356443837C46.71982602392143 57.313726505462455 47.17547765753633 57.58297519805303 47.63112929115075 57.68135452803772Z" id="Tunisia" className={this.props.getClass('Tunisia')} fill={`url(#regionImg${this.props.id('Tunisia')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

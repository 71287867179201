import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M83.36132885404899 0L80.06718924971949 30.309817095931063L73.9641657334819 31.62560656961586L75.28928704740515 52.220977976857135L53.378126166479085 61.77678238148536L62.27136991414697 70.67002612915239L54.69391564016388 83.0253826054498L58.80925718551658 91.26539753639409V99.99999999999999L49.91601343784956 93.40238895110036L52.05300485255668 83.0253826054498L47.11646136618079 77.58491974617385L38.223217618513765 75.44792833146673L19.764837625979624 72.80701754385909L17.61851437103403 76.26913027248992L9.378499440089314 80.38447181784218L0 74.78536767450552C1.4744307577455011 73.62821948488228 2.631578947367878 72.64837625979796 3.620754012691092 71.49122807017515C11.356849570734884 62.597984322508125 26.689063083240402 36.40350877192976 28.331466965285067 30.80440462859267C29.80589772303057 25.037327360955558 32.446808510637766 16.79731243001127 35.58230683090657 18.9343038447184C38.54050018663603 20.91265397536397 37.56065696155214 35.41433370660655 43.15976110488966 35.41433370660655C48.91750653228788 35.41433370660655 48.91750653228788 31.289660321015386 48.432250839865596 26.02650242627834C47.779022023142424 20.91265397536397 42.66517357222805 12.67263904441968 47.779022023142424 8.557297499066554C53.04217991787904 4.441955953714284 70.51138484509083 3.452780888391499 77.75289287047302 1.3157894736843687C79.0686823441578 0.9798432250838941 81.04703247480339 0.4852556924222871 83.36132885404899 0Z" id="Netherlands" className={this.props.getClass('Netherlands')} fill={`url(#regionImg${this.props.id('Netherlands')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M100 13.299007911589817L83.37310059022941 22.843149566745037L59.86437272384775 13.299007911589817L40.3616727364058 11.75436393318996L39.0305161371343 24.375235464020275L8.21298505588314 22.604546025366286C4.445560718322189 14.630164510861318 1.7832475197786075 6.869270375485863 0 0L19.741303528820705 3.5539369584314393L52.99510234836073 2.2102222780347405H72.28431495667375L87.58005776717285 10.410649252793261L100 13.299007911589817Z" id="Gambia" className={this.props.getClass('Gambia')} fill={`url(#regionImg${this.props.id('Gambia')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

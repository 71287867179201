import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M79.81047453703734 43.68489583333333L83.51417824074109 71.14438657407494C78.41435185185256 76.75781250000057 73.6834490740746 81.48148148148191 69.21296296296285 85.18518518518566C51.46122685185208 100 11.617476851851734 79.94791666666656 3.44328703703689 80.33854166666703L0 73.43750000000065L4.210069444444893 29.75983796296329L13.40422453703731 0.6365740740744052L25.535300925926144 0L38.31018518518601 12.774884259259863L47.887731481481595 14.937789351852318L79.81047453703734 43.68489583333333Z" id="Uruguay" className={this.props.getClass('Uruguay')} fill={`url(#regionImg${this.props.id('Uruguay')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

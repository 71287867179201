import React from 'react';
import Form from '../elements/Form.js';
import makeSnack from '../elements/Snack.js';
import Cookies from 'js-cookie';
import Forgot from '../user/Forgot.js';
import autobind from 'auto-bind';

export default makeSnack (class Login extends React.Component
{
  constructor (props)
  {
    super(props);
    autobind(this);
    this.state = {
      isShowingForgot: false,
    };
  }

  onSuccess (user)
  {
    Cookies.set('user', user.id);
    if (this.props.onSuccess)
    {
      this.props.onSuccess(user);
    }
  }

  forgot (flag)
  {
    this.setState({
      isShowingForgot: flag,
    });
  }

  render ()
  {
    let elements = [
      {
        label: 'Username',
        name: 'username',
        type: 'text',
      },
      {
        label: 'Password',
        name: 'password',
        type: 'password',
      },
      {
        label: 'Forgot your username/password?',
        type: 'link',
        func: () => this.forgot(true),
        footer: true,
      },
      {
        label: 'Need to create a new account?',
        type: 'link',
        func: this.props.switchToRegister,
        footer: true,
      },
    ];
    let fetch = {
      method: 'post',
      url: '/user/auth',
      errorMsg: 'Your username and/or password were incorrect.  Please try again.',
      onSuccess: this.onSuccess,
    };
    if (this.state.isShowingForgot)
    {
      return (
        <Forgot
          onClose={() => this.forgot(false)}
        />
      );
    }
    return (this.props.disabled ? null :
      <Form
        onClose={this.props.onClose}
        title="CryptoConquerors"
        description="Back to the Crusades"
        elements={elements}
        submitLabel="Sign In"
        fetch={fetch}
      />
    );
  }
});


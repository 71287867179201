import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M100 44.37362098553527L89.3846531012503 72.49325815150708C74.66290757538658 64.48884530522096 62.110811473400126 58.42118166217102 52.59867614611456 53.015444962000025C33.77053199313572 42.191713655307304 6.717332679578151 43.7116940426569 6.717332679578151 28.781564108849615C6.717332679578151 21.635204707035285 4.118656533464157 12.331453787692164 0 3.898014219170991L39.17626869330785 0L55.405736700171005 10.811473400342566L72.06423142927203 2.598676146113994C72.93454278009285 20.127482226035276 78.57317970090773 32.45893601372801 93.28266732042185 40.90463348859933C95.45231674429967 42.191713655307304 97.81809266977204 43.2704094140717 100 44.37362098553527Z" id="Costa Rica" className={this.props.getClass('Costa Rica')} fill={`url(#regionImg${this.props.id('Costa Rica')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

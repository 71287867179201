import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M61.91943127962006 4.170616113744074C75.28436018957221 15.071090047393108 72.39336492890804 53.957345971562994 59.407582938386966 61.91943127962115C52.70142180094645 66.09004739336523 38.88625592416879 82.84360189573485 33.05687203791374 100L0 97.86729857819907C10.047393364928011 71.94312796208472 24.265402843602278 36.39810426540342 35.545023696681305 2.085308056872037C45.5924170616115 0.40284360189443247 56.46919431279609 0 61.91943127962006 4.170616113744074Z" id="Lebanon" className={this.props.getClass('Lebanon')} fill={`url(#regionImg${this.props.id('Lebanon')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M85.74090175133588 53.508880884362064L84.85902372376098 67.32082971059499L50.21736430257157 100C26.294870202459784 78.9342938765373 8.756676189293795 59.42118991429598 6.794187057508813 53.508880884362064C5.030431002360168 48.01887964228036 3.2790957645012946 40.789963979628986 0 32.232020866973244L10.96758166687363 34.43050552726331L46.49111911563794 0L73.89144205688793 20.606135883740603L86.16320953918752 45.39808719413808L85.74090175133588 53.508880884362064Z" id="Sierra Leone" className={this.props.getClass('Sierra Leone')} fill={`url(#regionImg${this.props.id('Sierra Leone')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M54.57581004204781 0C63.73979718030942 0 45.41182290378392 32.74795943606302 45.41182290378392 39.945584961662384C45.41182290378392 47.15557754143001 21.82785060598479 100 14.617858026217164 98.25624536235505C7.197625525599365 96.27751669552283 3.710116250308329 70.94978976007933 1.7437546376460877 54.57581004204781C0 38.201830324017436 20.084095968339838 12.663863467722612 34.49171407370683 1.7437546376460877C34.49171407370683 1.7437546376460877 45.41182290378392 0 54.57581004204781 0Z" id="Taiwan" className={this.props.getClass('Taiwan')} fill={`url(#regionImg${this.props.id('Taiwan')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M73.16904652233768 72.3399355135885L52.44127130354445 69.92169507139613L39.014279134038844 90.23491478581371L2.0267157991677363 87.81667434362134L0 76.02487333026309L22.777521879316236 32.51957623215149L34.1317365269457 24.3666513127573L50.80608014739498 12.18332565637865L77.66006448641043 0L88.20819898664173 26.001842468908887L100 56.49470290188841L81.29894058037662 73.16904652233981L73.16904652233768 72.3399355135885Z" id="Rwanda" className={this.props.getClass('Rwanda')} fill={`url(#regionImg${this.props.id('Rwanda')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

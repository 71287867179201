import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M90.84101382488431 6.7716333845368695L93.21556579621011 8.474142345110332L94.80286738351145 17.965949820788484C81.46441372247712 29.262672811060092 67.67793138760788 45.76292882744498 62.25038402457715 54.35227854582714C55.36354326676816 65.08576548899114 59.31899641576938 75.1408090117769 64.96415770609252 85.20225294418887L57.283666154633146 89.71454173067119L42.37071172554989 90.39938556067594L36.71915002560083 99.54557091653919L16.385048643112768 100C12.083973374294885 97.62544802867392 9.7094214029685 94.23963133640592 11.40552995391634 88.24884792626759C14.906554019456749 75.595238095238 17.057091653864806 60.003840245775905 1.4656938044030035 62.82642089093718C1.0112647209410175 62.9352278545828 0.5632360471061283 63.05043522785462 0 63.16564260112645L15.591397849461213 45.19329237071146L26.203277009727767 41.698668714797556L38.863287250383564 24.06554019457243L44.52124935995795 31.073988735279464L57.962109575011695 33.89656938044045V20.455709165386402L67.90194572452504 19.09882232462872L78.41781874039869 11.296722990271608L81.24039938555997 0L90.84101382488431 6.7716333845368695Z" id="North Korea" className={this.props.getClass('North Korea')} fill={`url(#regionImg${this.props.id('North Korea')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

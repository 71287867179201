import React from 'react';
import Dialog from '../elements/Dialog.js';
import {get} from '../helpers/fetch.js';
import DataGrid from '../elements/DataGrid.js';
import DialogButton from '../elements/DialogButton.js';
import Create from '../user/Create.js';
import Edit from '../user/Edit.js';
import ApiButton from '../elements/ApiButton.js';
import makeSnack from '../elements/Snack.js';
import autobind from 'auto-bind';

export default makeSnack (class Users extends React.Component
{
  constructor (props)
  {
    super (props);
    autobind(this);
    this.state = {
      users: null,
    };
  }

  async componentDidUpdate ()
  {
    await this.refresh();
  }

  async componentDidMount ()
  {
    await this.refresh();
  }

  async refresh ()
  {
    if (!this.state.users)
    {
      let users = await get('/user');
      this.setState({
        users,
      });
    }
  }

  didDelete ()
  {
    this.props.openSnackbar(<span>The user has been deleted.</span>);
  }

  didVerify ()
  {
    this.props.openSnackbar(<span>The user has been verified.</span>);
  }

  didCreate ()
  {
    this.props.openSnackbar(<span>The user has been created.</span>);
  }

  renderColumns (num, name, value, render)
  {
    if (name === 'status')
    {
      return (value ? 'active' : 'inactive');
    }
    else if (name === 'verified')
    {
      return (value ? 'yes' : 'no');
    }
    return (render);
  }

/*
  <div className={'align-middle text-center' + (i % 2 === 1 ? '' : ' bg-cyan-50')}>
    <b> {user.id}: {user.username} </b> ({user.type}) <br />
    {user.email} <br />
    {user.status ? 'active' : 'inactive'}, {user.verified ? 'verified' : 'unverified'} <br />
    { this.renderColumns (6, 'actions', null, user) }
  </div>
*/

  render ()
  {
    if (this.props.disabled) return (null);
    return (!this.state.users ? null :
        <Dialog
          onClose={this.props.onClose}
          title={"Manage Users"}
          footer={
            <React.Fragment>
              <br />
              <DialogButton
                label="Create New User"
                dialog={ <Create onSuccess={this.didCreate} /> }
              />
            </React.Fragment>
          }
        >
          <div className="visible block md:invisible md:hidden">
            { this.state.users.map((user, i) =>
              <div key={i} className={'align-middle text-center' + (i % 2 === 1 ? '' : ' bg-cyan-50')}>
                <b> {user.id}: {user.username} </b> ({user.type}) <br />
                {user.email} <br />
                {user.status ? 'active' : 'inactive'}, {user.verified ? 'verified' : 'unverified'} <br /><br />
                <DialogButton
                  tiny={true}
                  label="Edit"
                  dialog={ <Edit user={user} /> }
                />
                { user.verified ? null :
                <ApiButton
                  tiny={true}
                  label="Verify"
                  fetch={{method:'put', url:`/user/${user.id}/verify`}}
                  onSuccess={this.didVerify}
                />
                }
                { user.type === 'admin' ? null :
                <ApiButton
                  tiny={true}
                  label="Delete"
                  fetch={{method:'delete', url:`/user/${user.id}`}}
                  onSuccess={this.didDelete}
                />
                }
                <br /><br />
              </div>
            )}
          </div>
          <div className="invisible hidden md:visible md:block">
            <DataGrid
              data={this.state.users}
              renderColumns={this.renderColumns}
              addColumns={{
                  names: ['Actions'],
                  values: [ user =>
                    <React.Fragment>
                      <DialogButton
                        label="Edit"
                        dialog={ <Edit user={user} /> }
                      />
                      { user.verified ? null :
                      <ApiButton
                        tiny={true}
                        label="Verify"
                        fetch={{method:'put', url:`/user/${user.id}/verify`}}
                        onSuccess={this.didVerify}
                      />
                      }
                      { user.type === 'admin' ? null :
                      <ApiButton
                        tiny={true}
                        label="Delete"
                        fetch={{method:'delete', url:`/user/${user.id}`}}
                        onSuccess={this.didDelete}
                      />
                      }
                      <br /><br />
                    </React.Fragment>
                  ],
              }}
              highlightColumns={[
                true,
                true,
                true,
                true,
                true,
                true,
                false,
              ]}
            />
          </div>
        </Dialog>
    );
  }
});


import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M98.4742726542708 30.650441657915778L100 49.83630860460773L99.78380381740614 49.7312990302057L90.73444931743758 58.34826116498826L85.28012848230237 56.05658162950128L77.97887454444324 60.201371301500856L77.86151090246446 60.74495027487784L66.0819074680334 48.74915065785405L55.17944283155167 47.65581567731144L54.85823707455635 41.55290629439723L44.60436098585391 47.65581567731144L29.87831243436877 44.931743776638456L15.269627524862404 41.55290629439723L2.9464451170540764 51.14583976774335L0.11118660819068767 50.93582061893871C0 32.60856136883069 6.109086416702579 20.723948359997316 9.166718141947912 17.017728086972685C12.539378590400478 12.984125023163797 22.79325467910292 8.178392735808465 28.89616406201684 16.356785471616362C35.01142751250808 24.535178207424828 39.150040150719846 27.265427141886185 43.18982024831654 25.844709370560164C46.56248069676911 24.86873803199689 48.41559083328227 11.3410340354564 48.63796404966308 4.039780097596976L59.87398851071688 0L76.66934338130791 15.603187349434751L83.0996355550058 11.884613008833094L84.73654950892569 15.603187349434751L89.20872197170895 14.837235159676112L88.88133918092498 15.603187349434751L93.02612885292484 19.74179998764567L90.95064550003029 32.71357094323301L98.4742726542708 30.650441657915778Z" id="Latvia" className={this.props.getClass('Latvia')} fill={`url(#regionImg${this.props.id('Latvia')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

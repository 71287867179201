import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M51.125445138749086 10.320499899213978L42.22938923604106 21.23227843848677L39.26627696029022 48.881273936706435L30.370221057582807 50.29899885775748L36.29644560908448 56.22522340925915C36.29644560908448 56.22522340925915 35.82611032721889 59.31599811865874 36.77349996640435 62.756164751730374C37.841832963783965 66.1963313848014 34.2807229725191 67.62077538130734 34.2807229725191 67.62077538130734C34.2807229725191 67.62077538130734 34.2807229725191 79.00960827790063 33.333333333333336 80.54827655714583C32.7420546932739 81.5023852717865 32.02311361956571 91.10394409729197 31.66700262043938 97.98427736343493C28.112611704629423 97.74910972250245 25.028556070684427 97.98427736343493 23.368944433245996 98.58227507894927C18.504333803668416 100 12.215279177585199 99.29449707720224 9.608277900960385 88.49694282066777C6.994557548881287 77.82033192232778 0 64.4157763891688 3.3192232748774773 58.845662836793466C6.6451656252098585 53.38977356715707 16.965665524423837 29.18766377746425 15.541221527917891 21.83699522945632C14.70805617147107 17.321776523550476 14.593831888732124 8.896055902707722 14.116777531411325 2.1433850702141326L19.458442518309706 0L20.997110797553976 7.357387623462832L45.19250151179158 3.917220990391506L44.24511187260644 9.850164617348701L51.125445138749086 10.320499899213978Z" id="Portugal" className={this.props.getClass('Portugal')} fill={`url(#regionImg${this.props.id('Portugal')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

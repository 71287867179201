import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M82.77320799059986 37.967097532314064L54.34782608695608 97.29729729729766L16.81551116333798 100L2.4911868390137557 92.33842538190233H1.6568742655701123L15.55816686251501 53.32549941245496L0 31.339600470035236L18.672150411281123 0C42.73795534665083 11.210340775557572 65.98119858989406 24.27732079905991 82.77320799059986 37.967097532314064Z" id="French Guyana" className={this.props.getClass('French Guyana')} fill={`url(#regionImg${this.props.id('French Guyana')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

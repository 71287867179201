import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M94.29515735019436 98.93662882597998C90.27159074579798 100 86.24802414139896 99.78445178905027 82.45437562868362 98.30435407386142C82.03764908751215 98.103175743641 81.620922546342 98.103175743641 81.40537433539293 97.67207932174153V82.87110216985113L75.06825693346873 79.70972840925435L72.72596637447963 70.1824974852701H61.301911194136395L53.915792498922826 62.782008909325235L41.22718781434247 56.861618048569866L20.520189682426683 49.04440293145551L0 42.70728552952933L1.494467595919486 6.1359390717057645L1.063371174020016 5.7048426498056335L14.16870239977052 0.8478229630682961L44.61847966661963 0L64.90875125736527 9.52723092398292L57.09153614024959 18.81017387555614L69.78014082483126 33.395602816496144L72.72596637447963 52.018968242563176L91.9672366719359 63.21310533122537L94.29515735019436 98.93662882597998Z" id="Armenia" className={this.props.getClass('Armenia')} fill={`url(#regionImg${this.props.id('Armenia')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M100 34.637017658601515L99.30673642903834 41.80510137344828L74.83322432962714 46.17396991497881L21.255722694570714 44.57815565729368L4.159581425767501 39.03204708960288L0 29.56180510137422L27.259646827991116 0H42.95618051013772L51.72007848266781 4.395029431001363L74.83322432962714 2.773054283846607L84.98364944408053 24.486592544147523L100 34.637017658601515Z" id="Bhutan" className={this.props.getClass('Bhutan')} fill={`url(#regionImg${this.props.id('Bhutan')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M100 37.1056357972244L99.30331350892058 45.90201076182342L89.30614556782777 49.60634381195098L82.40158595298806 54.0073633531577L81.80118946474038 55.70659869725274L70.90342679127683 55.304446332483764L71.2036250354004 50.903426791277305H53.10110450297358L34.30189747946706 57.10563579722446L33.803455111865816 75.00424808836024L23.103936561880452 69.60067969413738L9.306145567827294 73.30501274426469L5.001416029453099 62.707448314924974L0.49844236760098093 64.00453129425104L0.6060606060605445 62.707448314924974L3.1039365618801398 52.71028037383138L0 45.199660152931386L9.306145567827294 40.906258850183704L18.70291702067373 30.903426791277248L28.700084961767324 21.500991220617166L35.60464457660703 12.109883885584637L44.30472953837456 13.310676862078953L63.09827244406639 0.9062588501841233L73.4013027470972 0L74.30189747946743 15.904842820730295L86.20220900594684 21.500991220617166L86.8082696120074 29.606343811950925L100 37.1056357972244Z" id="Burkina Faso" className={this.props.getClass('Burkina Faso')} fill={`url(#regionImg${this.props.id('Burkina Faso')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M100 62.414769747193155L97.03136473303226 94.2620371341652C69.44298751704586 93.33892793454284 29.25626770166732 64.81695164166564 0 39.2636106157557L29.25626770166732 11.664743522500867L30.001048987726843 0H48.88282807091168L53.70817161439207 24.25259624462361L60.55806147068049 28.88912199727274L69.82062309870935 14.990034616594748L76.84884086856158 19.0705968740161L74.44665897409006 19.626560369243876L82.5868037343959 30.17937690129016L73.32424210636704 42.767229623413385L100 62.414769747193155Z" id="Liberia" className={this.props.getClass('Liberia')} fill={`url(#regionImg${this.props.id('Liberia')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M82.7452044878756 0L91.85667752442976 35.31487513572264C84.9800941006146 35.31487513572264 77.63300760043424 36.26492942453905 69.79732175171912 37.549764748462046C49.837133550488474 40.74375678610266 41.20521172638471 44.89685124864323 47.27651103872603 50.16286644951194C53.184943901556025 55.43792978646419 76.51103872602131 72.69272529858816 55.89938472674541 81.46941730003614C35.14296055012611 90.10133912414074 31.939920376402792 100 25.877669200144194 100C20.756424176620126 100 4.786463988417896 99.52949692363356 0 93.44914947520829L14.20557365182842 64.70322113644569L16.295693087224024 28.917842924358286L3.9902280130292542 24.448063698878222C4.940282301845664 21.73362287368842 5.582699963808205 19.173000361925553 6.225117625769078 16.784292435758378C6.858487151647241 14.069851610568577 8.469055374592449 9.907709011944046 10.541078537821793 4.6326456749913785L23.16322837495481 5.44697792254836C21.08215707564275 11.346362649294395 21.57075642417669 17.426710097720502 29.22547955121174 19.81541802388726C45.204487875498344 24.773796597900848 47.27651103872603 21.88744118711619 82.7452044878756 0Z" id="Jordan" className={this.props.getClass('Jordan')} fill={`url(#regionImg${this.props.id('Jordan')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

const ordinal = (num) =>
{
  const suffixes = ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'];
  num = Number(num);
  const lastDigit = num % 10;
  return (num + ((num >= 11 && num <= 13) ? 'th' : suffixes[lastDigit]));
};

module.exports = ordinal;


import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
					<path id="Montenegro" d="M97.3109984571327 46.68283006391861L79.41370949967111 69.6495481595766C79.41370949967111 69.6495481595766 52.171038130923385 71.19241789728996 50.606127396960055 79.36962750716407C49.81265153184934 82.8741459113951 45.55873925501566 91.44809345382457 40.8860480493736 100C31.16596870178511 80.93453824112841 15.957681287195909 70.8177209609866 0 63.78664315627151C3.1298214679307286 49.812651531850356 8.199250606129159 32.29005951068908 13.26867974432556 21.005069429138274C22.988759091912016 0 40.51135111307126 4.672691205642059 40.51135111307126 4.672691205642059L76.30592902799239 35.397839982367806L97.3109984571327 46.68283006391861Z" className={this.props.getClass('Montenegro')} fill={`url(#regionImg${this.props.id('Montenegro')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

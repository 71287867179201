import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M58.49588719153973 6.627497062280485L68.8601645123387 62.23266745006038L55.1821386603984 100L0 72.62044653349047L24.065804935368213 0L58.49588719153973 6.627497062280485Z" id="Swaziland" className={this.props.getClass('Swaziland')} fill={`url(#regionImg${this.props.id('Swaziland')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

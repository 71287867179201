
  import React from 'react';
  import SnackbarProvider from 'react-simple-snackbar'
  import Cookies from 'js-cookie';
  import { MdClose } from 'react-icons/md';
  import { FiMenu } from 'react-icons/fi';



























import Map from './maps/Map.js';
import Login from './user/Login.js';
import Register from './user/Register.js';
import {get, put} from './helpers/fetch.js';
import RegionInfo from './region/Info.js';
import MyRegions from './region/My.js';
import Edit from './user/Edit.js';
import TopRegions from './region/Top.js';
import Transactions from './region/Transactions.js';
import Leaderboard from './region/Leaderboard.js';
import Admin from './admin/Admin.js';
import ViewWatchlist from './region/ViewWatchlist.js';
import DialogButton from './elements/DialogButton.js';
import Button from './elements/Button.js';
import ConfirmDialog from './elements/ConfirmDialog.js';
import PasswordReset from './user/PasswordReset.js';
import config from './config/config.js';
import Progress from './elements/Progress.js';
import autobind from 'auto-bind';

const showSignoutButton = false;
const showSigninButton = false;
const shouldShowIntro = false;
const fakeSlowImageLoad = false;
let imageLoadStates = [];

export default class App extends React.Component
{
  constructor (props)
  {
    super (props);
    autobind(this);
    this.state = {
      user: null,
      allRegions: null,
      regionShowing: null,
      inc: 0,
      burgerActive: false,
      allowSignup: false,
      attemptedLogin: false,
      regionFetchPending: false,
      isShowingProgress: true,
      totalImages: 0,
      imagesRemaining: 0,
    };
    //setInterval (this.inc, 60000);
    //setInterval(() => {this.setState({inc:this.state.inc+1});}, 10);
  }

  notifyImage(id)
  {
    if (imageLoadStates.some(s => s.id === id)) return;
    imageLoadStates.push({
      id,
      state: 'unmounted',
    });
    setTimeout(() => {
      this.setState({
        totalImages: this.state.totalImages + 1,
        imagesRemaining: this.state.imagesRemaining + 1,
      });
      setTimeout(() => {
        this.checkImageMount(id);
      }, 1);
    }, 1);
  }

  checkImageMount(id)
  {
    // see if it's mounted
    let img = document.getElementById(id);
    if (!img)
    {
      // try again in a sec
      setTimeout(() => {
        this.checkImageMount(id);
      }, 1);
    }
    else
    {
      // find this image in our list
      for (let i=0; i<imageLoadStates.length; i++)
      {
        if (imageLoadStates[i].id === id)
        {
          imageLoadStates[i].state = 'mounted';
          this.checkImageLoad(id);
          return;
        }
      }
    }
  }

  checkImageLoad(id)
  {
    /*
    let img = document.getElementById(id);
    const isLoaded = img.complete || img.naturalHeight !== 0;
    if (isLoaded)
    {
      this.imageDidLoad(id);
    }
    else
    {
      img.addEventListener('load', () => {
        this.imageDidLoad(id);
      });
    }
    */
  }

  stats ()
  {
    let str = imageLoadStates.filter(i => i.state === 'unmounted').length + ' um, ';
    str += imageLoadStates.filter(i => i.state === 'mounted').length + ' mo, ';
    str += imageLoadStates.filter(i => i.state === 'loaded').length + ' lo, ';
    str += (this.state.totalImages - this.state.imagesRemaining) + ' / ' + this.state.totalImages;
    return (str);
  }

  imageDidLoad (id)
  {
    let duration = !fakeSlowImageLoad ? 1 : Math.random() * 10000;
    setTimeout(() => {
      for (let i=0; i<imageLoadStates.length; i++)
      {
        if (imageLoadStates[i].id === id)
        {
          imageLoadStates[i].state = 'loaded';
        }
      }
      this.setState({
        imagesRemaining: this.state.imagesRemaining - 1,
      });
    });
  }

  inc ()
  {
    this.setState({
      inc: this.state.inc + 5,
    });
    this.refresh();
  }

  async componentDidUpdate ()
  {
    await this.refresh();
  }

  async componentDidMount ()
  {

    let params = (new URL(document.location)).searchParams;

    // check for verify param
    let verify = params.get('verify');
    verify = Number(verify)
    if (verify && !isNaN(verify))
    {
      await put (`/user/${verify}/verify`);
      this.setState({
        showingVerifyConfirmation: true,
      });
    }

    // check for password reset param
    let reset = params.get('reset');
    reset = Number(reset);
    if (reset && !isNaN(reset))
    {
      this.setState({
        showingPasswordReset: true,
        passwordResetUserId: reset,
      });
    }


    // do startup init
    await this.refresh();
  }

  async refresh (doLogin = true)
  {
    if (doLogin && !this.state.user && !this.state.attemptedLogin)
    {
      // auto-login as previous user, if any
      let userId = Cookies.get('user');
      let user = null;
      if (userId && userId !== '')
      {
        user = await get(`/user/${userId}`);
      }
      this.setState({
        user,
        attemptedLogin: true,
      });
    }

    // get regions
    if (!this.state.allRegions && !this.state.regionFetchPending)
    {
      this.setState({
        regionFetchPending: true,
      });
      let allRegions = await get('/region');
      this.setState({allRegions});
    }
  }

  over (region)
  {
    //console.log('dbg', region);
  }

  showRegionInfo (regionName)
  {
    if (!regionName) return;
    this.setState({
      regionShowing: this.state.allRegions.find(r => r.original_name.toLowerCase() === regionName.toLowerCase()),
    });
  }

  async hideRegionInfo ()
  {
    this.setState({
      regionShowing: null,
    });
    await this.refresh(false);
  }

  didLogin (user)
  {
    this.setState({
      user,
    });
  }

  didRegister (user)
  {
    this.setState({
      user,
    });
  }

  logout ()
  {
    this.setState({
      user: null,
    });
  }

  toggleBurger ()
  {
    this.setState({
      burgerActive: !this.state.burgerActive,
    });
  }

  renderBurgerMenu ()
  {
    if (this.state.burgerActive)
    {
      return (
        <div className="visible xl:hidden md:flex flex-col md:flex-row md:min-h-screen w-full">
          <div className="flex flex-col w-full md:w-64 text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800 flex-shrink-0 mb-14 border border-cyan-400">

            <div className="flex-shrink-0 px-8 py-4 flex flex-row items-center justify-between">
              <div className="text-lg font-semibold tracking-widest text-gray-900 uppercase rounded-lg dark-mode:text-white focus:outline-none focus:shadow-outline">Crypto Conquerors</div>
              <span onClick={this.toggleBurger}><MdClose size={25} /></span>
            </div>

            <nav className="flex-grow md:block px-4 pb-4 md:pb-0 md:overflow-y-auto">

              <DialogButton
                sideMenu={true}
                label="Leaderboard"
                dialog={ <Leaderboard /> }
              />
              <DialogButton
                sideMenu={true}
                label="Top Countries"
                dialog={ <TopRegions seed={new Date()} /> }
              />
              <DialogButton
                sideMenu={true}
                label="Transactions"
                dialog={ <Transactions /> }
              />
              { !this.state.user ? null :
              <DialogButton
                sideMenu={true}
                label="My Countries"
                dialog={ <MyRegions inc={this.props.inc} user={this.state.user} /> }
              />
              }
              { !this.state.user ? null :
              <DialogButton
                sideMenu={true}
                label="My Watchlist"
                dialog={ <ViewWatchlist user={this.state.user} /> }
              />
              }
              <Button
                sideMenu={true}
                label="Contact Us"
                onClick={this.goHelp}
              />
              <Button
                sideMenu={true}
                label="Buy Countries"
                onClick={this.goBuyCountries}
              />

              <div className="mb-16" />

              { (!this.state.user || this.state.user.type !== 'admin') ? null :
              <DialogButton
                sideMenu={true}
                label="Admin Settings"
                dialog={ <Admin inc={this.inc} /> }
              />
              }

              { !this.state.user ? null :
              <DialogButton
                sideMenu={true}
                label="Manage Account"
                dialog={ <Edit user={this.state.user} nonAdmin={true} /> }
              />
              }

              { showSignoutButton && this.state.user &&
                  <button onClick={this.logout} className="block px-4 py-2 mt-2 text-sm font-semibold text-gray-900 bg-transparent rounded-lg dark-mode:bg-transparent dark-mode:hover:bg-gray-600 dark-mode:focus:bg-gray-600 dark-mode:focus:text-white dark-mode:hover:text-white dark-mode:text-gray-200 hover:text-gray-900 focus:text-gray-900 hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline">Sign Out</button>
              }

              { this.state.allowSignup && !this.state.user &&
                <DialogButton
                  sideMenu={true}
                  label="Sign Up"
                  receive={funcs => this.setState({openRegister:funcs.open, closeRegister:funcs.close})}
                  dialog={
                    <Register
                      onSuccess={this.didRegister}
                      switchToLogin={this.switchToLogin}
                    />
                  }
                />
              }

              { showSigninButton && !this.state.user &&
                <DialogButton
                  sideMenu={true}
                  label="Sign In"
                  receive={funcs => this.setState({openLogin:funcs.open, closeLogin:funcs.close})}
                  dialog={
                    <Login
                      onSuccess={this.didLogin}
                      switchToRegister={this.switchToRegister}
                    />
                  }
                />
              }

            </nav>
          </div>
        </div>
      );
    }
    else
    {
      return (
        <span className="space-x-3 visible xl:hidden" onClick={this.toggleBurger}>
          <FiMenu size={25} />
        </span>
      );
    }
  }

  renderTopNavButtons ()
  {
    return (
      <React.Fragment>
        <DialogButton
          label="Leaderboard"
          dialog={ <Leaderboard /> }
        />
        <DialogButton
          label="Top Countries"
          dialog={ <TopRegions seed={new Date()} /> }
        />
        <DialogButton
          label="Transactions"
          dialog={ <Transactions /> }
        />
        { !this.state.user ? null :
        <DialogButton
          label="My Countries"
          dialog={ <MyRegions inc={this.props.inc} user={this.state.user} /> }
        />
        }
        { !this.state.user ? null :
        <DialogButton
          label="My Watchlist"
          dialog={ <ViewWatchlist user={this.state.user} /> }
        />
        }
        <Button
          label="Contact Us"
          onClick={this.goHelp}
        />
        <Button
          label="Buy Countries"
          onClick={this.goBuyCountries}
        />
      </React.Fragment>
    );
  }

  renderTopNav ()
  {

    return (
        <span className="space-x-3 invisible xl:visible">
          { this.renderTopNavButtons() }
        </span>
    );








  }

  goHelp ()
  {
    window.open('https://twitter.com/Conquerverse');
  }

  goBuyCountries ()
  {
    window.open('https://opensea.io/collection/cryptoconquerorslands');
  }

  switchToLogin ()
  {
    this.state.closeRegister();
    this.state.openLogin();
  }

  switchToRegister ()
  {
    this.state.closeLogin();
    this.state.openRegister();
  }

  renderLoggedOutNav ()
  {
    return (!this.state.user &&
      <span className="space-x-3 invisible xl:visible">

        { this.state.allowSignup && !this.state.user &&
          <DialogButton
            label="Sign Up"
            receive={funcs => this.setState({openRegister:funcs.open, closeRegister:funcs.close})}
            dialog={
              <Register
                onSuccess={this.didRegister}
                switchToLogin={this.switchToLogin}
              />
            }
          />
        }

        { showSigninButton && !this.state.user &&
          <DialogButton
            label="Sign In"
            receive={funcs => this.setState({openLogin:funcs.open, closeLogin:funcs.close})}
            dialog={
              <Login
                onSuccess={this.didLogin}
                switchToRegister={this.switchToRegister}
              />
            }
          />
        }

      </span>
    );
  }

  renderLoggedInNav ()
  {
    return (this.state.user &&
      <span className="space-x-3 invisible xl:visible">

        { (!this.state.user || this.state.user.type !== 'admin') ? null :
        <DialogButton
          label="Admin Settings"
          dialog={ <Admin inc={this.inc} /> }
        />
        }

        <DialogButton
          label="Manage Account"
          dialog={ <Edit user={this.state.user} nonAdmin={true} /> }
        />

        { showSignoutButton &&
          <span className="border border-red-600 rounded-md p-3 bg-red-500 hover:bg-red-700 text-white uppercase cursor-pointer" onClick={this.logout}>
            Logout
          </span>
        }

      </span>
    );
  }

  renderCountdown()
  {
    const style = {
      fontFamily: 'DigitalClock',
      fontSize: '24pt',
      backgroundColor: 'lightblue',
      //opacity: 0.5,
    };
    let end = new Date(2022, 0, 1);
    let start = new Date();
    let diff = Math.floor((end.getTime() - start.getTime()) / 1000);
    //let seconds = diff % 60;
    diff = Math.floor(diff / 60);
    let minutes = diff % 60;
    diff = Math.floor(diff / 60);
    let hours = diff % 24;
    diff = Math.floor(diff / 24);
    let days = diff;
    return (
      <div style={style} className="text-center">
        {days} days, {hours} hr, {minutes} min
      </div>
    );
  }

  renderIntro ()
  {
    if (!shouldShowIntro) return null;
    return (
      <div style={{position:'fixed', bottom:0, left:0, width:'40vw', fontFamily:'Montserrat, sans-serif'}} className="z-5 m-5">
        <h1 style={{fontSize:'24pt'}}><b>Crypto Conquerors</b></h1>
        <div><i>Select a country to make an offer.</i></div>
        <hr />
        <h2>Land Grab Challenge</h2>
        { this.renderCountdown() }
        <div>
          This month is a rapid-fire race to pick up as much land as possible.
          <br />
          All conquered countries are automatically available for purchase at a profit for the current leader.
          Hurry up, because once the map is fully loaded, the game will switch to an auction format and leaders
          will start to HODL!!
        </div>
      </div>
    );
  }

  renderRegionInfo ()
  {
    return (!this.state.regionShowing ? null :
      <RegionInfo
        inc={this.inc}
        onClose={this.hideRegionInfo}
        region={this.state.regionShowing}
        user={this.state.user}
      />
    );
  }

  didResetPassword ()
  {
    Cookies.set('user', '');
    window.location.replace(config().client.path);
  }


  render ()
  {
    let logoClass = 'fixed left-5 bottom-5 w-3/4 md:w-1/4';
    return (
      <SnackbarProvider>

            {
            <div style={{position:'fixed', top:0, left:0, width:'100%'}} className="z-10">
              <nav className="flex justify-between p-5">
                { this.renderBurgerMenu() }
                { this.renderTopNav() }
                { this.renderLoggedOutNav() }
                { this.renderLoggedInNav() }
                { (this.props.adminLogin && !this.state.user) &&
                  <Login
                    onSuccess={this.didLogin}
                    switchToRegister={this.switchToRegister}
                  />
                }
              </nav>
            </div>
            }

            { !this.state.allRegions ?
              <div></div>
              :
              <Map notifyImage={this.notifyImage} imageDidLoad={this.imageDidLoad} over={this.over} click={this.showRegionInfo} regions={this.state.allRegions} />
            }

            {
              <React.Fragment>
              { this.renderIntro() }
              { this.renderRegionInfo() }
              </React.Fragment>
            }

            { this.state.showingVerifyConfirmation &&
            <ConfirmDialog
              label="Thank you for verifying your email."
              showNoBtn={false}
              yesBtnLabel="Ok"
              onConfirm={() => this.setState({showingVerifyConfirmation:false})}
              onClose={() => this.setState({showingVerifyConfirmation:false})}
            />
            }

            { this.state.showingPasswordReset &&
            <PasswordReset
              userId={this.state.passwordResetUserId}
              onSuccess={this.didResetPassword}
            />
            }

            <img src={`${config().staticServer.path}/images/logo.png`} className={logoClass }/>

            { this.state.isShowingProgress &&
                <Progress done={() => this.setState({isShowingProgress:false})} total={this.state.totalImages} remaining={this.state.imagesRemaining} />
            }

      </SnackbarProvider>
    );
  }
























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































}


import React from 'react';
import autobind from 'auto-bind';
const {filterUnderscore} = require('../helpers/str.js');

export default class DataGrid extends React.Component
{
  constructor (props)
  {
    super(props);
    autobind(this);
    let columns = !props.data ? [] : Object.keys(props.data[0]);
    this.state = {
      columns,
      orderColumns: props.orderColumns ? props.orderColumns : columns,
      perPage: 9999,
      startIndex: 0,
    };
  }

  header ()
  {
    return (
      <React.Fragment>
        {this.state.orderColumns.map(this.headerCell)}
        {this.props.addColumns && this.props.addColumns.names.map(this.headerCell)}
      </React.Fragment>
    );
  }

  formatCellHeader (cell)
  {
    return (filterUnderscore(cell));
  }

  headerCell (cell)
  {
    return (
      <div className="text-center m-1 text-cyan-600 hover:bg-cyan-300 capitalize cursor-pointer underline">
        {this.formatCellHeader(cell)}
      </div>
    );
  }

  isOnPage (i)
  {
    return (i >= this.state.startIndex && i < (this.state.startIndex + this.state.perPage));
  }

  body ()
  {
    return (this.props.data.map((row, i) => !this.isOnPage(i) ? null : this.row(row)));
  }

  row (rowDataParam)
  {
    let rowData = JSON.parse(JSON.stringify(rowDataParam));
    let baseColumns = this.state.orderColumns.map((column, i) => this.bodyCell(i, column, rowData[column], rowData));
    if (this.props.addColumns)
    {
      for (let i=0; i<this.props.addColumns.names.length; i++)
      {
        let name = this.props.addColumns.names[i];
        let value = this.props.addColumns.values[i];
        rowData[name] = value(rowData);
      }
    }
    return (
      <React.Fragment>
        { baseColumns }
        { this.props.addColumns && this.props.addColumns.names.map((column, i) => this.bodyCell(i + this.state.orderColumns.length, column, rowData[column], rowData)) }
      </React.Fragment>
    );
  }

  bodyCell (num, name, cell, row)
  {
    let className = 'm-1 bg-white text-cyan-500 cursor-pointer text-center';
    if (!this.props.highlightColumns || this.props.highlightColumns[num])
    {
      className += ' hover:bg-yellow-100';
    }
    return (
      <div className={className}>
        { this.bodyCellValue(num, name, cell, row) }
      </div>
    );
  }

  bodyCellValue (num, name, cell, row)
  {
    let displayValue;
    if (cell === null)
    {
      displayValue = 'null';
    }
    else if (cell === true)
    {
      displayValue = 'true';
    }
    else if (cell === false)
    {
      displayValue = 'false';
    }
    else
    {
      displayValue = cell;
    }
    if (this.props.renderColumns)
    {
      displayValue = this.props.renderColumns(num, name, cell, displayValue, row);
    }
    return (displayValue);
  }

  footer ()
  {
    return (null);
  }

  render ()
  {
    return (
      <React.Fragment>
        <div className={`overflow-y-scroll grid grid-cols-${this.state.orderColumns.length + (!this.props.addColumns ? 0 : this.props.addColumns.names.length)}`}>
          { this.header() }
          { this.body() }
          { this.footer() }
        </div>
      </React.Fragment>
    );
  }
}


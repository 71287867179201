import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M100 56.29742033383993C66.08497723824007 68.76580677794878 25.44258978249938 59.38290338897468 0 33.4850784016197L0.9104704097112073 25.037936267072176C14.719271623671505 7.587253414263555 39.75720789074368 0 61.153262518968695 6.7273646939819125C82.59989883662212 13.40414769853426 98.68487607486043 34.39554881133091 100 56.29742033383993Z" id="Jamaica" className={this.props.getClass('Jamaica')} fill={`url(#regionImg${this.props.id('Jamaica')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}

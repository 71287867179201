import React from 'react';

export default class Svg extends React.Component
{
	render ()
	{
		return (
			<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
				{ this.props.defs }
						<path d="M41.77393649626124 68.82432266764741C17.74549466715704 83.65207796984252 16.341792325610356 85.60132401618291 5.5167340934160585 82.24224592374676C0.33100404560482805 80.6240039230112 0 76.50484246659359 1.4098320460952025 72.50827510114016C2.917739364962206 68.07036900821419 6.491357116586677 63.74279759715622 8.226063503738752 62.663969596665844C11.585141596174626 60.60438886845662 12.332965551060173 50.5455437047937 8.875812185852498 47.725879612603016C7.465980139757296 46.64705161211264 4.652445752114845 46.7512565894324 2.053451023660428 47.18033590780931C5.1918597523597505 37.556699767071265 7.576314821625571 28.349883535613657 6.491357116586677 23.268358465122464L8.875812185852498 22.728944464876715L14.931960279514591 22.07306607821473L12.332965551060173 9.194556822361132L23.15802378325334 13.963466960892774C28.56442319480169 10.610518573005137 41.3387274733361 9.850435209023116 46.7512565894324 12.657839892117329C52.163785705529264 15.256834620571464 60.27951452739918 8.55093784479619 69.04499203138452 7.251440480569263C76.1922275346329 6.056148093661529 90.68897879122235 0.21453965918859672 100 0C94.04192717911026 4.873115115851397 85.92619835723923 11.474806914306633 82.6774549466722 13.963466960892774C77.27105553512328 18.070369008213913 76.51097217114126 18.726247394875333 76.51097217114126 34.30182665195543C76.51097217114126 49.779330636263445 66.44599730293068 53.8923623881331 41.98847615544928 68.72011769032765C41.878141473581 68.72011769032765 41.878141473581 68.82432266764741 41.77393649626124 68.82432266764741Z" id="Syria" className={this.props.getClass('Syria')} fill={`url(#regionImg${this.props.id('Syria')})`} stroke="#fff" strokeWidth="{0.25}" />
			</svg>
		);
	}
}
